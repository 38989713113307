import axios from "axios";

import * as ACTION_TYPES from "./Types";
import { API_BASE_SUPER_ADMIN, API_BASE } from "../../config/AppConstant";

const validatedRespose = (res) => res?.data || { return_data: [] };

export const getDoctorsList = (hId) => (dispatch) => {
  let hospitalId = hId.replace("hos", "");
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/listDoctors`, { hospitalId })
    .then((res) => {
      dispatch({ type: ACTION_TYPES.LISTDOCTOR_SUCCESS, payload: res.data });
      return validatedRespose(res);
    })
    .catch((err) => {
      dispatch({ type: ACTION_TYPES.LISTDOCTOR_ERROR, payload: err.response });
      throw err;
    });
};

export const getDoctorsWithPagination = (obj) => (dispatch) => {
  obj.hospitalId = obj.hospitalId.replace("hos", "");
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/listDoctorsWithPagination`, obj)
    .then((res) => {
      dispatch({ type: ACTION_TYPES.LISTDOCTOR_WITH_PAGINATION_SUCCESS, payload: res.data });
      return validatedRespose(res);
    })
    .catch((err) => {
      dispatch({ type: ACTION_TYPES.LISTDOCTOR_WITH_PAGINATION_ERROR, payload: err.response });
      throw err;
    });
};

export const getDoctorsDetailsById = (data) => (dispatch) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/getDoctorDetails`, { ...data })
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.DOCTORS_DETAILS_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.DOCTORS_DETAILS_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

export const archivedoctor = (doctorId) => (dispatch) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/archiveDoctor`, { doctorId })
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.ARCHIVE_DOCTOR_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.ARCHIVE_DOCTOR_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

export const addNewDoctor = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/addNewDoctor`, data)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
};

export const editDoctor = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/updateDoctor`, data)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
};

export const getMedicineDetailsById = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/getMedicineDetails`, { ...data })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const getDressingmaterialDetailsById = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/getDressingMaterialsDetails`, { ...data })
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const getUnitPreferences = (userId) => (dispatch) => {
  return axios
    .get(`${API_BASE}api/getUnitPrefrences?user_id=${userId}`)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.UNIT_PREFERENCES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.UNIT_PREFERENCES_ERROR,
        payload: err.response,
      });
      throw err;
    });
}

export const UpdateUnitPreference = (data) => (dispatch) => {
  return axios
    .post(`${API_BASE}api/updateUnitPrefrences`, data)
    .then((res) => {
      return validatedRespose(res);
    })
    .catch((err) => {
      throw err;
    });
};

export const listAllUsesFor = () => {
  return axios
    .get(`${API_BASE_SUPER_ADMIN}api/listAllUsesFor`)
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const addNewMedicine = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/addNewMedicine`, data)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
};

export const editMedicine = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/editMedicine`, data)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
};

export const addNewDressingMaterial = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/addNewDressingMaterial`, data)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
};

export const editDressingMaterial = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/editDressingMaterial`, data)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
};

export const listAllMedicine = (id) => (dispatch) => {
  return axios
    .get(`${API_BASE_SUPER_ADMIN}api/listAllMedicine?hospital_id=${id}`)
    .then((res) => {
      dispatch({ type: ACTION_TYPES.MEDICINE_LIST_SUCCESS, payload: res.data });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.MEDICINE_LIST_ERROR,
        payload: err.response,
      });
      throw err;
    });
};
export const listAllTreatment = (id) => (dispatch) => {
  return axios
    .get(`${API_BASE_SUPER_ADMIN}api/listAllTreatments?hospital_id=${id}`)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.ALL_TREATMENT_LIST_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.ALL_TREATMENT_LIST_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

export const addNewTreatmentDoctor = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/addNewTreatment`, data)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
};

export const editTreatmentDoctor = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/editTreatment`, data)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
};

export const deleteTreatmentDoctor = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/archiveTreatment`, data)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
};

export const getTreatmentDetails = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/getTreatmentDetails`, data)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
};

export const listAllDressingMaterials = (id) => (dispatch) => {
  return axios
    .get(`${API_BASE_SUPER_ADMIN}api/listDressingMaterials?hospital_id=${id}`)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.DRESSING_MATERIAL_LIST_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.DRESSING_MATERIAL_LIST_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

export const archiveDressingMaterial = (obj) => (dispatch) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/archiveDressingMaterials`, obj)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.ARCHIVE_DRESSING_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.ARCHIVE_DRESSING_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

export const archiveMedicine = (medicineId) => (dispatch) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/archiveMedicine`, { medicineId })
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.ARCHIVE_MEDICINE_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.ARCHIVE_MEDICINE_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

export const addNewTandC = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/addTandC`, data)
    .then((res) => {
      validatedRespose(res);
      console.log(res);
    })
    .catch((err) => {
      throw err;
    });
};

export const editTandC = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/updateTandC`, data)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
};

export const getTandCDetails = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/getTAndC`, data)
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
};

export const addNewLanguage = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/addNewLanguage`, data)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
};

export const getLanguageListByAppType = (param) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/getLanguageListByType`, param)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
}

export const uploadNewLanguageJson = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/uploadLanguageJSON`, data)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
}

export const DownloadLanguageJson = (obj) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/downloadLanguageJSON`, obj)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
}

export const deleteAppLanguage = (id) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/removeLanguage`, id)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
}

export const getLanguageDetails = (language_id) => {
  return axios
    .get(`${API_BASE_SUPER_ADMIN}api/getLangaugeById?language_id=${language_id}`)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
}

export const updateLangaugeDetails = (data) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/editLanguage`, data)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
}

export const getSettingsData = (obj) => (dispatch) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/getHospitalSettings`, obj)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.SETTING_LIST_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.SETTING_LIST_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

export const updateSettingsData = (obj) => {
  return axios
    .post(`${API_BASE_SUPER_ADMIN}api/UpdateHospitalSetting`, obj)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}
export const getAllHospitalAdminList = (hId) => (dispatch) => {
  let hospitalId = hId.replace("hos", "");
  return axios
    .get(`${API_BASE}api/getHospitalAdminList?hos_id=${hospitalId}`)
    .then((res) => {
      dispatch({ type: ACTION_TYPES.HOSPITAL_ADMIN_LIST_SUCCESS, payload: res.data });
      return validatedRespose(res);
    })
    .catch((err) => {
      dispatch({ type: ACTION_TYPES.HOSPITAL_ADMIN_LIST_ERROR, payload: err.response });
      throw err;
    });
};

export const getHospitalDetails = (id) => {
  let hospitalId = id.replace("hos", "");
  return axios
    .get(`${API_BASE}api/getHospitalDetails?hos_id=${hospitalId}`)
    .then((res) => {
      return res.data.return_data;
    })
    .catch((err) => {
      throw err;
    });
}

export const addNewAdmin = (obj) => {
  obj.hos_id = obj.hos_id.replace("hos", "");
  return axios
    .post(`${API_BASE}api/addNewHosAdmin`, obj)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
}

export const getHospitalAdminByid = (id) => {
  return axios
    .get(`${API_BASE}api/getHospitalAdminById?admin_id=${id}`)
    .then((res) => {
      return res.data.return_data;
    })
    .catch((err) => {
      throw err;
    });
}

export const updateHosAdmin = (obj) => {
  return axios
    .post(`${API_BASE}api/updateHospitalAdmin`, obj)
    .then((res) => validatedRespose(res))
    .catch((err) => {
      throw err;
    });
}

export const changeAdminStatus = (aId) => {
  return axios
    .get(`${API_BASE}api/changeStatusHospitalAdmin?admin_id=${aId}`)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw err;
    });
}