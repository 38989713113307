import React, { useEffect, useState } from "react";
// import axios from "axios";

const HeaderTest = () => {
  const [token, setToken] = useState("");
  // const [err, setErr] = useState("");

  // const [beforeToken, setBeforeToken] = useState("");
  // const [beforeErr, setBeforeErr] = useState("");
  useEffect(() => {
    let req = new XMLHttpRequest();
    req.open("GET", `https://dev.shadesofthings.com/header-test`, false);
    req.send(null);
    let headers = req;
    console.log(headers);
    setToken(headers);
  });

  // useEffect(() => {
  //   axios
  //     // .get(`https://dev.shadesofthings.com/header-test`)
  //     .get(`http://localhost:3001/header-test`)
  //     .then((res) => {
  //       setToken(res);
  //     })
  //     .catch((err) => {
  //     ;
  // }, []);  setErr(err);
  //     })

  return (
    <div className="tab-box " style={{ padding: 0 }}>
      <h3 style={{ padding: 10 }}>After render : </h3>{" "}
      <div style={{ padding: 5 }}>Response: {JSON.stringify(token)}</div>
      <br />
      <div style={{ padding: 5 }}>Err: {""}
      {/* {JSON.stringify(err)} */}
      </div>
    </div>
  );
};

export default HeaderTest;
