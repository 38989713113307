export const RESET_WOUND_STATE = "RESET_WOUND_STATE";

//WOUNDS_LIST Actions
export const WOUNDS_LIST_SUCCESS = "WOUNDS_LIST_SUCCESS";
export const WOUNDS_LIST_ERROR = "WOUNDS_LIST_ERROR";

// CLOSE WOUND LIST
export const CLOSE_WOUNDS_LIST_SUCCESS = "CLOSE_WOUNDS_LIST_SUCCESS";
export const CLOSE_WOUNDS_LIST_ERROR = "CLOSE_WOUNDS_LIST_ERROR";

//WOUNDS_DETAIL Actions
export const WOUND_DETAILS_SUCCESS = "WOUND_DETAILS_SUCCESS";
export const OVERVIEW_WOUND_DETAILS_SUCCESS = "OVERVIEW_WOUND_DETAILS_SUCCESS";
export const ANALYSIS_WOUND_DETAILS_SUCCESS = "ANALYSIS_WOUND_DETAILS_SUCCESS";
export const WOUND_DETAILS_ERROR = "WOUND_DETAILS_ERROR";

//WOUNDS_DATES Actions
export const WOUND_DATES_SUCCESS = "WOUND_DATES_SUCCESS";
export const WOUND_DATES_ERROR = "WOUND_DATES_ERROR";


//WOUNDS_EXUDATE Actions
export const WOUND_EXUDATE_SUCCESS = "WOUND_EXUDATE_SUCCESS";
export const WOUND_EXUDATE_ERROR = "WOUND_EXUDATE_ERROR";

//WOUNDS_DETAIL_OVERVIEW Actions
export const WOUND_DETAILS_SUCCESS_OVERVIEW = "WOUND_DETAILS_SUCCESS_OVERVIEW";
export const WOUND_DETAILS_ERROR_OVERVIEW = "WOUND_DETAILS_ERROR_OVERVIEW";

//WOUNDS_HIGH_DETAIL Actions
export const HIGH_WOUND_DETAILS_SUCCESS = "HIGH_WOUND_DETAILS_SUCCESS";
export const HIGH_WOUND_DETAILS_ERROR = "HIGH_WOUND_DETAILS_ERROR";

//WOUNDS_DETAIL_BEFORE_CLEANING Actions
export const WOUNDS_BEFORE_CLEAN_SUCCESS = "WOUNDS_BEFORE_CLEAN_SUCCESS";
export const WOUNDS_BEFORE_CLEAN_ERROR = "WOUNDS_BEFORE_CLEAN_ERROR";

//WOUNDS_DETAIL_AFTER_CLEANING Actions
export const WOUNDS_AFTER_CLEAN_SUCCESS = "WOUNDS_AFTER_CLEAN_SUCCESS";
export const WOUNDS_AFTER_CLEAN_ERROR = "WOUNDS_AFTER_CLEAN_ERROR";

//WOUNDS_GET_TISSUE Actions
export const WOUNDS_GET_TISSUES_SUCCESS = "WOUNDS_GET_TISSUES_SUCCESS";
export const WOUNDS_GET_TISSUES_ERROR = "WOUNDS_GET_TISSUES_ERROR";

// PERI_WOUNDS_GET_TISSUE Actions
export const PERI_WOUNDS_GET_TISSUES_SUCCESS =
  "PERI_WOUNDS_GET_TISSUES_SUCCESS";
export const PERI_WOUNDS_GET_TISSUES_ERROR = "PERI_WOUNDS_GET_TISSUES_ERROR";

//WOUNDS_GET_TISSUE Actions
export const WOUNDS_GET_TISSUES_BEFORE_CLEANING_SUCCESS =
  "WOUNDS_GET_TISSUES_BEFORE_CLEANING_SUCCESS";
export const WOUNDS_GET_TISSUES_BEFORE_CLEANING_ERROR =
  "WOUNDS_GET_TISSUES_BEFORE_CLEANING_ERROR";

// PERI_WOUNDS_GET_TISSUE Actions
export const PERI_WOUNDS_GET_TISSUES_BEFORE_CLEANING_SUCCESS =
  "PERI_WOUNDS_GET_TISSUES_BEFORE_CLEANING_SUCCESS";
export const PERI_WOUNDS_GET_TISSUES_BEFORE_CLEANING_ERROR =
  "PERI_WOUNDS_GET_TISSUES_BEFORE_CLEANING_ERROR";

//WOUNDS_GET_TISSUE_DISTRIBUTION Actions
export const WOUNDS_GET_TISSUES_DISTRIBUTION_SUCCESS =
  "WOUNDS_GET_TISSUES_DISTRIBUTION_SUCCESS";
export const WOUNDS_GET_TISSUES_DISTRIBUTION_ERROR =
  "WOUNDS_GET_TISSUES_DISTRIBUTION_ERROR";
export const WOUNDS_GET_TISSUES_DISTRIBUTION_CLEAR =
  "WOUNDS_GET_TISSUES_DISTRIBUTION_CLEAR";

//PERI_WOUNDS_GET_TISSUE_DISTRIBUTION Actions
export const PERI_WOUNDS_GET_TISSUES_DISTRIBUTION_SUCCESS =
  "PERI_WOUNDS_GET_TISSUES_DISTRIBUTION_SUCCESS";
export const PERI_WOUNDS_GET_TISSUES_DISTRIBUTION_ERROR =
  "PERI_WOUNDS_GET_TISSUES_DISTRIBUTION_ERROR";

//GET PERIWOUND VALUES
export const GET_PERIWOUND_VALUES_SUCCESS = "GET_PERIWOUND_VALUES_SUCCESS";
export const GET_PERIWOUND_VALUES_ERROR = "GET_PERIWOUND_VALUES_ERROR";

//GET_TISSUE_DISTRIBUTION percentage
export const GET_TISSUE_DISTRIBUTION_SUCCESS = "GET_TISSUE_DISTRIBUTION_SUCCESS";
export const GET_TISSUE_DISTRIBUTION_ERROR = "GET_TISSUE_DISTRIBUTION_ERROR";

//GRAPH FOR TISSUE DISTRIBUTION
export const GET_TISSUE_DISTRIBUTION_GRAPH_SUCCESS = "GET_TISSUE_DISTRIBUTION_GRAPH_SUCCESS";
export const GET_TISSUE_DISTRIBUTION_GRAPH_ERROR = "GET_TISSUE_DISTRIBUTION_GRAPH_ERROR";

//GET WOUND SIZE AND DEPTH
// export const GET_WOUND_SIZE_DEPTH_SUCCESS = "GET_WOUND_SIZE_DEPTH_SUCCESS";
// export const GET_WOUND_SIZE_DEPTH_ERROR = "GET_WOUND_SIZE_DEPTH_ERROR";

//EXUDATE VALUES
export const GET_EXUDATE_SUCCESS = "GET_EXUDATE_SUCCESS";
export const GET_EXUDATE_ERROR = "GET_EXUDATE_ERROR";

//EDGE CHARACTERISTIC VALUES
export const GET_EDGE_CHAR_SUCCESS = "GET_EDGE_CHAR_SUCCESS";
export const GET_EDGE_CHAR_ERROR = "GET_EDGE_CHAR_ERROR";

//CULTURE VALUE
export const GET_CULTURE_SUCCESS = "GET_CULTURE_SUCCESS";
export const GET_CULTURE_ERROR = "GET_CULTURE_ERROR";

//EXPOSE
export const GET_EXPOSE_SUCCESS = "GET_EXPOSE_SUCCESS";
export const GET_EXPOSE_ERROR = "GET_EXPOSE_ERROR";

//MISCELLENEOUS
export const GET_MISCELLENEOUS_SUCCESS = "GET_MISCELLENEOUS_SUCCESS";
export const GET_MISCELLENEOUS_ERROR = "GET_MISCELLENEOUS_ERROR";

//GET_COMMENTS_SATISFACTION Actions
export const GET_COMMENTS_SATISFACTION_SUCCESS =
  "GET_COMMENTS_SATISFACTION_SUCCESS";
export const GET_COMMENTS_SATISFACTION_ERROR =
  "GET_COMMENTS_SATISFACTION_ERROR";

//GET_COMMENTS_SATISFACTION Actions
export const POST_COMMENTS_SATISFACTION_SUCCESS =
  "POST_COMMENTS_SATISFACTION_SUCCESS";
export const POST_COMMENTS_SATISFACTION_ERROR =
  "POST_COMMENTS_SATISFACTION_ERROR";

//WOUNDS_SIDE_DETAILS Actions
export const WOUND_SIDE_DETAILS_SUCCESS = "WOUND_SIDE_DETAILS_SUCCESS";
export const WOUND_SIDE_DETAILS_ERROR = "WOUND_SIDE_DETAILS_ERROR";

//DELETE WOUND
export const WOUND_DELETE_SUCCESS = "WOUND_DELETE_SUCCESS";
export const WOUND_DELETE_ERROR = "WOUND_DELETE_ERROR";

//close WOUND
export const WOUND_CLOSE_SUCCESS = "WOUND_CLOSE_SUCCESS";
export const WOUND_CLOSE_ERROR = "WOUND_CLOSE_ERROR";

//close wound details
export const WOUND_CLOSE_DET_SUCCESS = "WOUND_CLOSE_DET_SUCCESS";
export const WOUND_CLOSE_DET_ERROR = "WOUND_CLOSE_DET_ERROR";

//GET WOUND HOVER DETAILS
export const GET_WOUND_HOVER_DETAILS_SUCCESS =
  "GET_WOUND_HOVER_DETAILS_SUCCESS";
export const GET_WOUND_HOVER_DETAILS_ERROR = "GET_WOUND_HOVER_DETAILS_ERROR";

//GET ALGO DETAILS ON DATE
export const ALGO_DETAILS_ON_DATE_SUCCESS = "ALGO_DETAILS_ON_DATE_SUCCESS";
export const ALGO_DETAILS_ON_DATE_ERROR = "ALGO_DETAILS_ON_DATE_ERROR";

//ANALYSIS_WOUNDIMAGES
export const ANALYSIS_WOUNDIMAGES_SUCCESS = "ANALYSIS_WOUNDIMAGES_SUCCESS";
export const ANALYSIS_WOUNDIMAGES_ERROR = "ANALYSIS_WOUNDIMAGES_ERROR";

//ANALYSIS_HEALINGSCORE
export const ANALYSIS_HEALINGSCORE_SUCCESS = "ANALYSIS_HEALINGSCORE_SUCCESS";
export const ANALYSIS_HEALINGSCORE_ERROR = "ANALYSIS_HEALINGSCORE_ERROR";

//ANALYSIS_ODOURLEVEL
export const ANALYSIS_ODOURLEVEL_SUCCESS = "ANALYSIS_ODOURLEVEL_SUCCESS";
export const ANALYSIS_ODOURLEVEL_ERROR = "ANALYSIS_ODOURLEVEL_ERROR";

//ANALYSIS_PAINLEVEL
export const ANALYSIS_PAINLEVEL_SUCCESS = "ANALYSIS_PAINLEVEL_SUCCESS";
export const ANALYSIS_PAINLEVEL_ERROR = "ANALYSIS_PAINLEVEL_ERROR";

//ANALYSIS_WOUNDSIZE
export const ANALYSIS_WOUNDSIZE_SUCCESS = "ANALYSIS_WOUNDSIZE_SUCCESS";
export const ANALYSIS_WOUNDSIZE_ERROR = "ANALYSIS_WOUNDSIZE_ERROR";

//ANALYSIS_STAGE
export const ANALYSIS_STAGE_SUCCESS = "ANALYSIS_STAGE_SUCCESS";
export const ANALYSIS_STAGE_ERROR = "ANALYSIS_STAGE_ERROR";

//ANALYSIS_WOUNDDEPTH
export const ANALYSIS_WOUNDDEPTH_SUCCESS = "ANALYSIS_WOUNDDEPTH_SUCCESS";
export const ANALYSIS_WOUNDDEPTH_ERROR = "ANALYSIS_WOUNDDEPTH_ERROR";

//ANALYSIS_TEMPERATURE
export const ANALYSIS_TEMPERATURE_SUCCESS = "ANALYSIS_TEMPERATURE_SUCCESS";
export const ANALYSIS_TEMPERATURE_ERROR = "ANALYSIS_TEMPERATURE_ERROR";

export const SET_COMPREHENSIVE_VIEW_SELECTED_TAB =
  "SET_COMPREHENSIVE_VIEW_SELECTED_TAB";
export const RESET_COMPREHENSIVE_VIEW_SELECTED_TAB =
  "RESET_COMPREHENSIVE_VIEW_SELECTED_TAB";

//TREATMENT_SCHEDULE

export const GET_MEDICATION_LIST_SUCCESS = "GET_MEDICATION_LIST_SUCCESS";
export const GET_MEDICATION_LIST_ERROR = "GET_MEDICATION_LIST_ERROR";

export const GET_SCHEDULE_MEDICATION_SUCCESS =
  "GET_SCHEDULE_MEDICATION_SUCCESS";
export const GET_SCHEDULE_MEDICATION_ERROR = "GET_SCHEDULE_MEDICATION_ERROR";

export const CREATE_SCHEDULE_MEDICATION_SUCCESS =
  "CREATE_SCHEDULE_MEDICATION_SUCCESS";
export const CREATE_SCHEDULE_MEDICATION_ERROR =
  "CREATE_SCHEDULE_MEDICATION_ERROR";

export const UPDATE_SCHEDULE_MEDICATION_SUCCESS =
  "UPDATE_SCHEDULE_MEDICATION_SUCCESS";
export const UPDATE_SCHEDULE_MEDICATION_ERROR =
  "UPDATE_SCHEDULE_MEDICATION_ERROR";

export const DELETE_SCHEDULE_MEDICATION_SUCCESS =
  "DELETE_SCHEDULE_MEDICATION_SUCCESS";
export const DELETE_SCHEDULE_MEDICATION_ERROR =
  "DELETE_SCHEDULE_MEDICATION_ERROR";

export const ADD_SELECTED_MEDICATION_ID = "ADD_SELECTED_MEDICATION_ID";

export const ADD_PARTICULAR_MEDICATION_ID = "ADD_PARTICULAR_MEDICATION_ID";

export const REMOVE_PARTICULAR_MEDICATION_ID =
  "REMOVE_PARTICULAR_MEDICATION_ID";

export const GET_BANDAGE_LIST_SUCCESS = "GET_BANDAGE_LIST_SUCCESS";
export const GET_BANDAGE_LIST_ERROR = "GET_BANDAGE_LIST_ERROR";

export const CHECKBOX_SELECTED_BANDAGE_DETAILS =
  "CHECKBOX_SELECTED_BANDAGE_DETAILS";

export const CREATE_SCHEDULE_BANDAGE_SUCCESS =
  "CREATE_SCHEDULE_BANDAGE_SUCCESS";
export const CREATE_SCHEDULE_BANDAGE_ERROR = "CREATE_SCHEDULE_BANDAGE_ERROR";

export const GET_SCHEDULE_BANDAGE_SUCCESS = "GET_SCHEDULE_BANDAGE_SUCCESS";
export const GET_SCHEDULE_BANDAGE_ERROR = "GET_SCHEDULE_BANDAGE_ERROR";

export const EDIT_SELECTED_BANDAGE_DETAILS = "EDIT_SELECTED_BANDAGE_DETAILS";
export const EDIT_SELECTED_PARTICULAR_BANDAGE_DETAILS =
  "EDIT_SELECTED_PARTICULAR_BANDAGE_DETAILS";

export const UPDATE_SCHEDULE_BANDAGE_SUCCESS =
  "UPDATE_SCHEDULE_BANDAGE_SUCCESS";
export const UPDATE_SCHEDULE_BANDAGE_ERROR = "UPDATE_SCHEDULE_BANDAGE_ERROR";

export const DELETE_SCHEDULE_BANDAGE_SUCCESS =
  "DELETE_SCHEDULE_BANDAGE_SUCCESS";

export const DELETE_SCHEDULE_BANDAGE_ERROR = "DELETE_SCHEDULE_BANDAGE_ERROR";

export const GET_SCHEDULE_CALL = "GET_SCHEDULE_CALL";

export const GET_SCHEDULE_CALL_SUCCESS = "GET_SCHEDULE_CALL_SUCCESS";
export const GET_SCHEDULE_CALL_ERROR = "GET_SCHEDULE_CALL_ERROR";

export const JOIN_SCHEDULE_CALL_SUCCESS = "JOIN_SCHEDULE_CALL_SUCCESS";
export const JOIN_SCHEDULE_CALL_ERROR = "JOIN_SCHEDULE_CALL_ERROR";

export const DELETE_SCHEDULE_CALL_SUCCESS = "DELETE_SCHEDULE_CALL_SUCCESS";

export const SELECTED_SCHEDULE_CALL_DETAILS = "SELECTED_SCHEDULE_CALL_DETAILS";
export const REMOVE_SCHEDULE_CALL_DETAILS = "REMOVE_SCHEDULE_CALL_DETAILS";

export const SELECTED_ID_ON_REFRESH = "SELECTED_ID_ON_REFRESH";

export const GET_MEDICATION_GRAPH_DETAILS_SUCCESS =
  "GET_MEDICATION_GRAPH_DETAILS_SUCCESS";
export const GET_MEDICATION_GRAPH_DETAILS_ERROR =
  "GET_MEDICATION_GRAPH_DETAILS_ERROR";

export const GET_DRESSING_GRAPH_DETAILS_SUCCESS =
  "GET_DRESSING_GRAPH_DETAILS_SUCCESS";
export const GET_DRESSING_GRAPH_DETAILS_ERROR =
  "GET_DRESSING_GRAPH_DETAILS_ERROR";

export const GET_MEDICATION_GRAPH_DETAILS_OVERVIEW_SUCCESS =
  "GET_MEDICATION_GRAPH_DETAILS_OVERVIEW_SUCCESS";
export const GET_MEDICATION_GRAPH_DETAILS_OVERVIEW_ERROR =
  "GET_MEDICATION_GRAPH_DETAILS_OVERVIEW_ERROR";

export const GET_DRESSING_GRAPH_DETAILS_OVERVIEW_SUCCESS =
  "GET_DRESSING_GRAPH_DETAILS_OVERVIEW_SUCCESS";
export const GET_DRESSING_GRAPH_DETAILS_OVERVIEW_ERROR =
  "GET_DRESSING_GRAPH_DETAILS_OVERVIEW_ERROR";

export const GET_OVERALL_TREATMENT_FOLLOWED_SUCCESS =
  "GET_OVERALL_TREATMENT_FOLLOWED_SUCCESS";

export const GET_TREATMENT_PRESCRIBE_SUCCESS =
  "GET_TREATMENT_PRESCRIBE_SUCCESS";

export const ADD_SELECTED_TREATMENT_DATA_FOR_EDIT =
  "ADD_SELECTED_TREATMENT_DATA_FOR_EDIT";

//Clear the state
export const CLEAR_WOUND_STATE_OBJ_DATA = "CLEAR_WOUND_STATE_OBJ_DATA";
export const CLEAR_WOUND_STATE_ARRAY_DATA = "CLEAR_WOUND_STATE_ARRAY_DATA";

export const GET_SCHEDULED_TREATMENT_SUCCESS =
  "GET_SCHEDULED_TREATMENT_SUCCESS";
export const GET_SCHEDULED_TREATMENT_ERROR = "GET_SCHEDULED_TREATMENT_ERROR";

export const GET_PRINT_PRISCRIPTION_URL_SUCCESS =
  "GET_PRINT_PRISCRIPTION_URL_SUCCESS";
export const GET_PRINT_PRISCRIPTION_URL_ERROR =
  "GET_PRINT_PRISCRIPTION_URL_ERROR";

export const UPDATE_ALGO_WOUND_SUCCESS = "UPDATE_ALGO_WOUND_SUCCESS";
export const UPDATE_ALGO_WOUND_ERROR = "UPDATE_ALGO_WOUND_ERROR";

export const GET_VITAL_SCAN_RESULT_SUCC = "GET_VITAL_SCAN_RESULT_SUCC";
export const GET_VITAL_SCAN_RESULT_ERR = "GET_VITAL_SCAN_RESULT_ERR";

export const UPDATE_PERI_WOUND_SUCCESS = "UPDATE_PERI_WOUND_SUCCESS";
export const UPDATE_PERI_WOUND_ERROR = "UPDATE_PERI_WOUND_ERROR";

export const AVG_HEAL_LIST_SUCCESS = "AVG_HEAL_LIST_SUCCESS";
export const AVG_HEAL_LIST_ERROR = "AVG_HEAL_LIST_ERROR";

export const HIDE_IMAGE_FOR_PDF = "HIDE_IMAGE_FOR_PDF";