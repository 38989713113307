import React, { useEffect, useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { parse } from 'query-string';
import { API_BASE } from "../../../config/AppConstant";
import axios from 'axios';
import * as ACTION_TYPES from "../../../store/Wounds/Types";



import Icon360 from '../../../components/svg/Icon360'
import IconUpload from '../../../components/svg/IconUpload'
import { toAbsoluteUrl } from '../../../utils'
import ThreeDView from './ThreeDView'
// import TissueDistribution from './TissueDistribution'
// import { woundSideDetails } from '../../../store/Wounds/Actions';


const ThreedImage = () => {
    const dispatch = useDispatch();
    let location = useLocation();


    const validatedRespose = (res) => res?.data || { return_data: [] };


    const [loaderPercentage, setLoaderPercentage] = useState(0)
    const [isLoading3D, setIsLoading3D] = useState(toAbsoluteUrl("url(/images/3DImage.svg)"))

    const query = parse(location.search);
    console.log("🚀 ~ file: ThreedImage.js ~ line 32 ~ ThreedImage ~ query", query)

    useEffect(() => {
        // const header = query.authtoken

        // var req = new XMLHttpRequest();
        // req.open('GET', document.location, false);
        // req.send(null);
        // var headers = req.getAllResponseHeaders().toLowerCase();

        // query.uploadId && dispatch(woundSideDetails(query.uploadId));

       if (query?.uploadId && query?.authtoken) {
        axios
        .get(`${API_BASE}api/GetUploadDetails?upload_id=${query.uploadId}`, {
            headers: {
              'Authorization': query.authtoken
            }})
        .then((res) => {
            dispatch({
                type: ACTION_TYPES.WOUND_SIDE_DETAILS_SUCCESS,
                payload: validatedRespose(res),
            });
            return validatedRespose(res);
        })
        .catch((err) => {
            dispatch({
                type: ACTION_TYPES.WOUND_SIDE_DETAILS_ERROR,
                payload: err.response,
            });
            throw err;
        });
       }
        

    }, [query.uploadId,query.authtoken]);

    const woundSideDetail = useSelector(({ wounds: { woundSideDetails } }) => ({ woundSideDetails }))
    console.log("🚀 ~ file: ThreedImage.js ~ line 32 ~ ThreedImage ~ woundSideDetail", woundSideDetail)
    let image = woundSideDetail.woundSideDetails.Td_model

    // let image = "https://s3-ap-southeast-1.amazonaws.com/trails.wound.management/After_Cleaning/Algo/PLY/p2-1655881735020-doc1.ply"

    return (
        <div className="tab-box d-flex flex-wrap" style={{ padding: 0 }}>
            <div className="tab-left">
                {image ?
                    <figure className={`chart-map pt-0 ${isLoading3D ? 'border-remove' : ''}`} style={{ backgroundImage: isLoading3D, height: "calc(100vh - 320px)" }}>
                        <ThreeDView image_png={woundSideDetail?.woundSideDetails?.Td_model_image} isFullView={true} ply={image} toggleLoading={() => setIsLoading3D(false)} loadedPLY={(n) => setLoaderPercentage(n)} isLoading3D={isLoading3D} />
                        {isLoading3D && <div className="patients-chartimage no-image threeD d-flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 320px)", paddingBottom: 0 }}>
                            <div className="no-group text-center">
                                <Icon360 className="icon-360" />
                                <ProgressBar className="progress-single">
                                    <ProgressBar className="loading" now={loaderPercentage} />
                                </ProgressBar>
                                <h4 className="d-flex flex-column">
                                    <span>LOADING</span>
                                    <span>3D IMAGE</span>
                                </h4>
                            </div>
                        </div>}
                    </figure> :
                    <div className="patients-chartimage no-image d-flex flex-column justify-content-center align-items-center" style={{ height: "calc(100vh - 320px)", paddingBottom: 0 }}>
                        <div className="no-group text-center">
                            <IconUpload className="icon-upload" />
                            <h4>IMAGE UNDER PROCESSING</h4>
                        </div>
                    </div>
                }
                {/* <TissueDistribution showPercentage={false} isHidden={true} /> */}
            </div>
        </div>
    )
}

export default ThreedImage