export const RESET_DOCTOR_STATE = "RESET_DOCTOR_STATE";

//LISTUSER Actions
export const LISTDOCTOR_SUCCESS = "LISTDOCTOR_SUCCESS";
export const LISTDOCTOR_ERROR = "LISTDOCTOR_ERROR";

export const LISTDOCTOR_WITH_PAGINATION_SUCCESS = "LISTDOCTOR_WITH_PAGINATION_SUCCESS";
export const LISTDOCTOR_WITH_PAGINATION_ERROR = "LISTDOCTOR_WITH_PAGINATION_ERROR";

export const DOCTORS_DETAILS_SUCCESS = "DOCTORS_DETAILS_SUCCESS";
export const DOCTORS_DETAILS_ERROR = "DOCTORS_DETAILS_ERROR";

//UNIT_PREFERENCES
export const UNIT_PREFERENCES_SUCCESS = "UNIT_PREFERENCES_SUCCESS";
export const UNIT_PREFERENCES_ERROR = "UNIT_PREFERENCES_ERROR";
// export const UNIT_PREFERENCE_UPDATE_SUCCESS = "UNIT_PREFERENCE_UPDATE_SUCCESS";
// export const UNIT_PREFERENCE_UPDATE_ERROR = "UNIT_PREFERENCE_UPDATE_ERROR";

//ARCHIVE_USER Actions
export const ARCHIVE_DOCTOR_SUCCESS = "ARCHIVE_DOCTOR_SUCCESS";
export const ARCHIVE_DOCTOR_ERROR = "ARCHIVE_DOCTOR_ERROR";

//MEDICINE_LIST_SUCCESS Actions
export const MEDICINE_LIST_SUCCESS = "MEDICINE_LIST_SUCCESS";
export const MEDICINE_LIST_ERROR = "MEDICINE_LIST_ERROR";


//MEDICINE_LIST_SUCCESS Actions
export const DRESSING_MATERIAL_LIST_SUCCESS = "DRESSING_MATERIAL_LIST_SUCCESS";
export const DRESSING_MATERIAL_LIST_ERROR = "DRESSING_MATERIAL_LIST_ERROR";

//ARCHIVE_USER Actions
export const ARCHIVE_MEDICINE_SUCCESS = "ARCHIVE_MEDICINE_SUCCESS";
export const ARCHIVE_MEDICINE_ERROR = "ARCHIVE_MEDICINE_ERROR";

//ARCHIVE_USER Actions
export const ARCHIVE_DRESSING_SUCCESS = "ARCHIVE_DRESSING_SUCCESS";
export const ARCHIVE_DRESSING_ERROR = "ARCHIVE_DRESSING_ERROR";

//TREATMENT_LIST_SUCCESS Actions
export const ALL_TREATMENT_LIST_SUCCESS = "ALL_TREATMENT_LIST_SUCCESS";
export const ALL_TREATMENT_LIST_ERROR = "ALL_TREATMENT_LIST_ERROR";

//ADD_NEW_DOCTOR Actions
// export const ADD_NEW_DOCTOR_SUCCESS = "ADD_NEW_DOCTOR_SUCCESS";
// export const ADD_NEW_DOCTOR_ERROR = "ADD_NEW_DOCTOR_ERROR";

// //ADD_NEW_DOCTOR Actions
// export const EDIT_DOCTOR_SUCCESS = "EDIT_DOCTOR_SUCCESS";
// export const EDIT_DOCTOR_ERROR = "EDIT_DOCTOR_ERROR";

export const TC_SUCCESS = "TC_SUCCESS";
export const TC_ERROR = "TC_ERROR";

export const TC_ADD_SUCCESS = "TC_ADD_SUCCESS";
export const TC_ADD_ERROR = "TC_ADD_ERROR";

export const UPT_SUCCESS = "UPT_SUCCESS";
export const UPT_ERROR = "UPT_ERROR";

export const SETTING_LIST_SUCCESS = "SETTING_LIST_SUCCESS";
export const SETTING_LIST_ERROR = "SETTING_LIST_ERROR";

export const HOSPITAL_ADMIN_LIST_SUCCESS = "HOSPITAL_ADMIN_LIST_SUCCESS";
export const HOSPITAL_ADMIN_LIST_ERROR = "HOSPITAL_ADMIN_LIST_ERROR";