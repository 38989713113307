import dayjs from "dayjs";
import { convertToDDMMYYYY } from "../../utils";
import * as ACTION_TYPES from "./Types";

const unknownLabel = { label: "Unknown" };
const GetTissueData = (tissueData) => {
  let data = tissueData.return_data.length > 0 ? tissueData.return_data : [];
  if (data.length) {
    const otherIndex = data.findIndex((t) => t.wound_tissue === "Others");
    if (otherIndex >= 0) {
      data = [
        ...data.slice(0, otherIndex),
        ...data.slice(otherIndex + 1),
        data[otherIndex],
      ];
    }
  }
  let others = tissueData.others
    ? [...tissueData.others, unknownLabel]
    : [unknownLabel];
  return [data, others];
};

const utcDate = (date) => {
  let utcDate = new Date(date).getUTCDate()
  let utcMonth = new Date(date).getUTCMonth()
  let utcYear = new Date(date).getUTCFullYear()

  return new Date(`${utcMonth + 1}-${utcDate}-${utcYear}`)
}

const initialState = {
  message: "",
  error: null,
  woundsList: [],
  avgHealingList: [],
  closeWoundList: [],
  woundDetails: [],
  overviewWoundDetails: [],
  analisisWoundDetails: [],
  woundDates: [],
  woundClose: [],
  woundDetailsOverView: [],
  woundHighDetails: {},
  woundBeforeClean: {},
  woundAfterClean: {},
  TissueDistribution: [],
  TissueDistributionPercentage: [],
  TissueDistributionGraphVal: [],
  PeriWoundCharValues: [],
  TissueDistributionOthers: [],
  PeriTissueDistribution: [],
  // WoundSizeDepth: [],
  Culture: [],
  ExposedStructure: [],
  Miscelleneous: [],
  ExudateValues: [],
  EdgeCharacteristics: [],
  PeriTissueDistributionOthers: [],
  TissueDistributionBeforeCleaning: [],
  TissueDistributionBeforeCleaningOthers: [],
  PeriTissueDistributionBeforeCleaning: [],
  PeriTissueDistributionBeforeCleaningOthers: [],
  woundComments: [],
  woundDelete: [],
  closeWoundDetails: [],
  woundSideDetails: {},
  woundHoverDetails: {},
  algoDetailsOnDate: {},
  overallTreatmentFollowedValues: {},
  analysisWoundImages: [],
  analysisHealingScore: [],
  analysisOdourLevel: [],
  analysisPainLevel: [],
  analysisWoundSize: [],
  analysisStage: [],
  analysisWoundDepth: [],
  analysisTemperature: [],
  woundTissueDistributionBewteenDate: [],
  periWoundTissueDistributionBewteenDate: [],
  comprehensiveViewSelectedTab: 2,
  particularMedicationId: "",
  medicationListDetails: [],
  scheduleMedicationDetails: [],
  selectedMedicationDetails: {},

  bandageListDetials: [],
  selectedBandageDetails: [],
  dressingId: "",
  scheduleBandageDetails: [],

  scheduleCallDetails: [],
  selectedScheduleCallDetails: [],
  selectedIdAfterRefresh: "",
  room: {},

  scheduledTreatments: [],
  selectedTreatmentDetail: {},

  printPrescriptionUrl: { pdfUrl: "" },

  getMedicationGraphDetails: [],
  getDressingGraphDetails: [],

  getMedicationGraphDetailsOverview: {},
  getDressingGraphDetailsOverview: {},
  treatmentPrescribeData: [],
  selectedTreatment: {},
  selectVideoSchedule: {},
  algoWoundDetails: {},
  vitalDetails: {},
  algoExudateDetails: {},

  hideImagePdf: false
};

const WoundsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.GET_SCHEDULE_CALL:
      return {
        ...state,
        selectVideoSchedule: state.scheduleCallDetails.find(
          (schedule) => schedule.id === action.payload.id
        ),
      };

    case ACTION_TYPES.RESET_WOUND_STATE:
      return {
        ...initialState,
      };

    case ACTION_TYPES.WOUNDS_LIST_SUCCESS:
      return {
        ...state,
        woundsList: action.payload.return_data,
      };

    case ACTION_TYPES.WOUNDS_LIST_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.AVG_HEAL_LIST_SUCCESS:
      return {
        ...state,
        avgHealingList: action.payload.return_data,
      };

    case ACTION_TYPES.AVG_HEAL_LIST_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.CLOSE_WOUNDS_LIST_SUCCESS:
      return {
        ...state,
        closeWoundList: action.payload.return_data,
      };

    case ACTION_TYPES.CLOSE_WOUNDS_LIST_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.UPDATE_ALGO_WOUND_SUCCESS:
      return {
        ...state,
        algoWoundDetails: action.payload.data,
      };

    case ACTION_TYPES.WOUND_EXUDATE_SUCCESS:
      return {
        ...state,
        algoExudateDetails: action.payload.data,
      };

    case ACTION_TYPES.WOUND_EXUDATE_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };


    case ACTION_TYPES.UPDATE_ALGO_WOUND_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.GET_VITAL_SCAN_RESULT_SUCC:
      return {
        ...state,
        vitalDetails: action.payload.return_data,
      };

    case ACTION_TYPES.GET_VITAL_SCAN_RESULT_ERR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.WOUND_DETAILS_SUCCESS:
      return {
        ...state,
        woundDetails: action.payload.return_data.map((w) => ({
          ...w,
          uplaod_date: dayjs(utcDate(w.uplaod_date)).format("DD/MM/YYYY"),
        })),
      };
    case ACTION_TYPES.OVERVIEW_WOUND_DETAILS_SUCCESS:
      return {
        ...state,
        overviewWoundDetails: action?.payload?.return_data ? action.payload.return_data.map((w) => ({
          ...w,
          uplaod_date: dayjs(utcDate(w.uplaod_date)).format("DD/MM/YYYY"),
        })) : [],
      };
    case ACTION_TYPES.ANALYSIS_WOUND_DETAILS_SUCCESS:
      return {
        ...state,
        analisisWoundDetails: action?.payload?.return_data ? action.payload.return_data.map((w) => ({
          ...w,
          uplaod_date: dayjs(utcDate(w.uplaod_date)).format("DD/MM/YYYY"),
        })) : [],
      };
    // overviewWoundDetails
    case ACTION_TYPES.WOUND_DETAILS_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.WOUND_DATES_SUCCESS:
      let dates =
        Array.isArray(action.payload?.return_data) ?
          action.payload.return_data
            .filter((a) => a.uplaod_date)
            .map(({ uplaod_date }) => uplaod_date && new Date(uplaod_date))
            .sort((a, b) => a - b) : [];
      /** Comment Due to date format change from BackEnd */
      // dates = [...new Set(dates)].map((d) => {
      //   let day = +d.getDate();
      //   let month = +d.getMonth() + 1;
      //   let year = +d.getFullYear();
      //   if (day <= 9) day = `0${day}`;
      //   if (month <= 9) month = `0${month}`;
      //   return `${month}/${day}/${year}`;
      // });
      return {
        ...state,
        woundDates: dates,
      };

    case ACTION_TYPES.WOUND_DATES_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.WOUND_DETAILS_SUCCESS_OVERVIEW:
      return {
        ...state,
        woundDetailsOverView: action.payload.return_data,
      };

    case ACTION_TYPES.WOUND_DETAILS_ERROR_OVERVIEW:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.HIGH_WOUND_DETAILS_SUCCESS:
      return {
        ...state,
        woundHighDetails: action.payload.return_data ? action.payload.return_data[0] : {},
      };

    case ACTION_TYPES.HIGH_WOUND_DETAILS_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.WOUNDS_BEFORE_CLEAN_SUCCESS:
      return {
        ...state,
        woundBeforeClean:
          action.payload?.return_data?.length > 0
            ? action.payload.return_data[0]
            : {},
      };

    case ACTION_TYPES.WOUNDS_BEFORE_CLEAN_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.WOUNDS_AFTER_CLEAN_SUCCESS:
      return {
        ...state,
        woundAfterClean:
          action.payload?.return_data?.length > 0
            ? action.payload.return_data[0]
            : {},
      };
    case ACTION_TYPES.WOUNDS_AFTER_CLEAN_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };


    case ACTION_TYPES.WOUND_SIDE_DETAILS_SUCCESS:
      return {
        ...state,
        woundSideDetails:
          action.payload?.return_data?.length > 0
            ? action.payload.return_data[0]
            : {},
      };
    case ACTION_TYPES.WOUND_SIDE_DETAILS_ERROR:
      return {
        ...state,
        woundSideDetails: {},
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.GET_PERIWOUND_VALUES_SUCCESS:
      return {
        ...state,
        PeriWoundCharValues: action.payload?.return_data
          || []
      };
    case ACTION_TYPES.GET_PERIWOUND_VALUES_ERROR:
      return {
        ...state,
        PeriWoundCharValues: [],
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.GET_EXUDATE_SUCCESS:
      return {
        ...state,
        ExudateValues: action.payload?.return_data[0]
          || []
      };

    case ACTION_TYPES.GET_EXUDATE_ERROR:
      return {
        ...state,
        ExudateValues: [],
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.GET_EDGE_CHAR_SUCCESS:
      return {
        ...state,
        EdgeCharacteristics: action.payload.return_data.length > 0
          ? action.payload.return_data[0]
          : [],
      };
    case ACTION_TYPES.GET_EDGE_CHAR_ERROR:
      return {
        ...state,
        EdgeCharacteristics: [],
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.GET_CULTURE_SUCCESS:
      return {
        ...state,
        Culture: action.payload.return_data.length > 0
          ? action.payload.return_data[0]
          : [],
      };
    case ACTION_TYPES.GET_CULTURE_ERROR:
      return {
        ...state,
        Culture: [],
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.GET_EXPOSE_SUCCESS:
      return {
        ...state,
        ExposedStructure: action.payload.return_data.length > 0
          ? action.payload.return_data[0]
          : [],
      };
    case ACTION_TYPES.GET_EXPOSE_ERROR:
      return {
        ...state,
        ExposedStructure: [],
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.GET_MISCELLENEOUS_SUCCESS:
      return {
        ...state,
        Miscelleneous: action.payload.return_data.length > 0
          ? action.payload.return_data[0]
          : [],
      };
    case ACTION_TYPES.GET_MISCELLENEOUS_ERROR:
      return {
        ...state,
        Miscelleneous: [],
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.GET_TISSUE_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        TissueDistributionPercentage: action.payload?.return_data
          || []
      }

    case ACTION_TYPES.GET_TISSUE_DISTRIBUTION_ERROR:
      return {
        ...state,
        TissueDistributionPercentage: [],
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.GET_TISSUE_DISTRIBUTION_GRAPH_SUCCESS:
      return {
        ...state,
        TissueDistributionGraphVal: action.payload?.return_data
          || []
      }

    case ACTION_TYPES.GET_TISSUE_DISTRIBUTION_GRAPH_ERROR:
      return {
        ...state,
        TissueDistributionGraphVal: [],
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    // case ACTION_TYPES.GET_WOUND_SIZE_DEPTH_SUCCESS:
    //   return {
    //     ...state,
    //     WoundSizeDepth: action.payload?.return_data
    //       || []
    //   };

    // case ACTION_TYPES.GET_WOUND_SIZE_DEPTH_ERROR:
    //   return {
    //     ...state,
    //     WoundSizeDepth: [],
    //     message: action?.payload?.data?.message || "",
    //     error: action?.payload?.data || "",
    //   };


    case ACTION_TYPES.GET_VITAL_SCAN_RESULT_SUCC:
      return {
        ...state,
        woundSideDetails:
          action.payload.return_data.length > 0
            ? action.payload.return_data[0]
            : {},
      };

    case ACTION_TYPES.GET_VITAL_SCAN_RESULT_ERR:
      return {
        ...state,
        woundSideDetails: {},
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.ALGO_DETAILS_ON_DATE_SUCCESS:
      return {
        ...state,
        algoDetailsOnDate:
          action.payload.return_data.length > 0
            ? action.payload.return_data[0]
            : {},
      };

    case ACTION_TYPES.ALGO_DETAILS_ON_DATE_ERROR:
      return {
        ...state,
        algoDetailsOnDate: {},
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.WOUNDS_GET_TISSUES_SUCCESS:
      let [data, others] = GetTissueData(action.payload);

      return {
        ...state,
        TissueDistribution: data,
        TissueDistributionOthers: others,
      };

    case ACTION_TYPES.PERI_WOUNDS_GET_TISSUES_SUCCESS:
      let [data2, others2] = GetTissueData(action.payload);
      return {
        ...state,
        PeriTissueDistribution: data2,
        PeriTissueDistributionOthers: others2,
      };

    case ACTION_TYPES.WOUNDS_GET_TISSUES_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.PERI_WOUNDS_GET_TISSUES_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.WOUNDS_GET_TISSUES_BEFORE_CLEANING_SUCCESS:
      let [data1, others1] = GetTissueData(action.payload);

      return {
        ...state,
        TissueDistributionBeforeCleaning: data1,
        TissueDistributionBeforeCleaningOthers: others1,
      };

    case ACTION_TYPES.WOUNDS_GET_TISSUES_BEFORE_CLEANING_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.PERI_WOUNDS_GET_TISSUES_BEFORE_CLEANING_SUCCESS:
      let [data3, others3] = GetTissueData(action.payload);

      return {
        ...state,
        PeriTissueDistributionBeforeCleaning: data3,
        PeriTissueDistributionBeforeCleaningOthers: others3,
      };

    case ACTION_TYPES.PERI_WOUNDS_GET_TISSUES_BEFORE_CLEANING_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.GET_COMMENTS_SATISFACTION_SUCCESS:
      return {
        ...state,
        woundComments:
          action.payload.return_data.length > 0
            ? action.payload.return_data[0]
            : [],
      };

    case ACTION_TYPES.GET_COMMENTS_SATISFACTION_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.WOUND_DELETE_SUCCESS:
      return {
        ...state,
        woundDelete: action.payload.return_data,
      };

    case ACTION_TYPES.WOUND_DELETE_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.WOUND_CLOSE_SUCCESS:
      return {
        ...state,
        woundClose: action.payload.return_data,
      };

    case ACTION_TYPES.WOUND_CLOSE_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };


    case ACTION_TYPES.GET_WOUND_HOVER_DETAILS_SUCCESS:
      return {
        ...state,
        woundHoverDetails:
          action.payload.return_data.length > 0
            ? action.payload.return_data[0]
            : {},
      };

    case ACTION_TYPES.GET_WOUND_HOVER_DETAILS_ERROR:
      return {
        ...state,
        woundHoverDetails: {},
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.ANALYSIS_WOUNDIMAGES_SUCCESS:
      return {
        ...state,
        analysisWoundImages:
          action.payload?.return_data.length > 0
            ? action.payload.return_data.map((val) => {
              return {
                ...val,
                uplaod_date: convertToDDMMYYYY(val.uplaod_date),
              };
            })
            : [],
      };

    case ACTION_TYPES.ANALYSIS_WOUNDIMAGES_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.ANALYSIS_HEALINGSCORE_SUCCESS:
      return {
        ...state,
        analysisHealingScore:
          action.payload?.return_data.length > 0
            ? action.payload.return_data.map((val) => {
              return {
                ...val,
                uplaod_date: convertToDDMMYYYY(val.uplaod_date),
              };
            })
            : [],
      };

    case ACTION_TYPES.ANALYSIS_HEALINGSCORE_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };
    case ACTION_TYPES.ANALYSIS_ODOURLEVEL_SUCCESS:
      return {
        ...state,
        analysisOdourLevel:
          action.payload?.return_data.length > 0
            ? action.payload.return_data.map((val) => {
              return {
                ...val,
                uplaod_date: convertToDDMMYYYY(val.uplaod_date),
              };
            })
            : [],
      };

    case ACTION_TYPES.ANALYSIS_ODOURLEVEL_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.ANALYSIS_PAINLEVEL_SUCCESS:
      return {
        ...state,
        analysisPainLevel:
          action.payload?.return_data.length > 0
            ? action.payload.return_data.map((val) => {
              return {
                ...val,
                uplaod_date: convertToDDMMYYYY(val.uplaod_date),
              };
            })
            : [],
      };

    case ACTION_TYPES.ANALYSIS_PAINLEVEL_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.ANALYSIS_WOUNDSIZE_SUCCESS:
      return {
        ...state,
        analysisWoundSize:
          action.payload?.return_data.length > 0
            ? action.payload.return_data.map((val) => {
              return {
                ...val,
                uplaod_date: convertToDDMMYYYY(val.uplaod_date),
              };
            })
            : [],
      };

    case ACTION_TYPES.ANALYSIS_WOUNDSIZE_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.ANALYSIS_STAGE_SUCCESS:
      return {
        ...state,
        analysisStage:
          action.payload?.return_data.length > 0
            ? action.payload.return_data.map((val) => {
              return {
                ...val,
                uplaod_date: convertToDDMMYYYY(val.uplaod_date),
              };
            })
            : [],
      };

    case ACTION_TYPES.ANALYSIS_STAGE_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.ANALYSIS_WOUNDDEPTH_SUCCESS:
      return {
        ...state,
        analysisWoundDepth:
          action.payload?.return_data.length > 0
            ? action.payload.return_data.map((val) => {
              return {
                ...val,
                uplaod_date: convertToDDMMYYYY(val.uplaod_date),
              };
            })
            : [],
      };

    case ACTION_TYPES.ANALYSIS_WOUNDDEPTH_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.ANALYSIS_TEMPERATURE_SUCCESS:
      return {
        ...state,
        analysisTemperature: action.payload.return_data,
      };

    case ACTION_TYPES.ANALYSIS_TEMPERATURE_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.WOUNDS_GET_TISSUES_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        woundTissueDistributionBewteenDate: action.payload.return_data,
      };

    case ACTION_TYPES.WOUNDS_GET_TISSUES_DISTRIBUTION_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.WOUNDS_GET_TISSUES_DISTRIBUTION_CLEAR:
      return {
        ...state,
        woundTissueDistributionBewteenDate: [],
      };

    case ACTION_TYPES.PERI_WOUNDS_GET_TISSUES_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        periWoundTissueDistributionBewteenDate: action.payload.return_data,
      };

    case ACTION_TYPES.PERI_WOUNDS_GET_TISSUES_DISTRIBUTION_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.RESET_COMPREHENSIVE_VIEW_SELECTED_TAB:
      return {
        ...state,
        comprehensiveViewSelectedTab: initialState.comprehensiveViewSelectedTab,
      };

    case ACTION_TYPES.SET_COMPREHENSIVE_VIEW_SELECTED_TAB:
      return {
        ...state,
        comprehensiveViewSelectedTab: action.payload,
      };
    case ACTION_TYPES.GET_MEDICATION_LIST_SUCCESS:
      return {
        ...state,
        medicationListDetails: action.payload.return_data,
      };

    case ACTION_TYPES.GET_MEDICATION_LIST_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };
    case ACTION_TYPES.GET_SCHEDULE_MEDICATION_SUCCESS:
      return {
        ...state,
        scheduleMedicationDetails: action.payload.return_data,
      };

    case ACTION_TYPES.GET_SCHEDULE_MEDICATION_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.SELECTED_ID_ON_REFRESH:
      return {
        ...state,
        selectedIdAfterRefresh: action?.payload?.data?.id,
      };

    case ACTION_TYPES.ADD_SELECTED_MEDICATION_ID:
      return {
        ...state,
        selectedMedicationDetails: action.payload.data,
      };

    case ACTION_TYPES.ADD_PARTICULAR_MEDICATION_ID:
      return {
        ...state,
        selectedMedicationDetails: action.payload.data,
        particularMedicationId: action.payload.id,
      };

    case ACTION_TYPES.REMOVE_PARTICULAR_MEDICATION_ID:
      return {
        ...state,
        particularMedicationId: "",
        selectedBandageDetails: [],
        selectedMedicationDetails: [],
      };

    case ACTION_TYPES.DELETE_SCHEDULE_MEDICATION_SUCCESS:
      return {
        ...state,
        scheduleMedicationDetails: [],
      };

    case ACTION_TYPES.GET_BANDAGE_LIST_SUCCESS:
      return {
        ...state,
        bandageListDetials: action.payload.return_data,
      };

    case ACTION_TYPES.GET_BANDAGE_LIST_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.GET_SCHEDULE_BANDAGE_SUCCESS:
      return {
        ...state,
        scheduleBandageDetails: action.payload.return_data,
      };

    case ACTION_TYPES.GET_SCHEDULE_BANDAGE_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.CHECKBOX_SELECTED_BANDAGE_DETAILS:
      return {
        ...state,
        selectedBandageDetails: action.payload.data,
      };

    case ACTION_TYPES.EDIT_SELECTED_BANDAGE_DETAILS:
      return {
        ...state,
        selectedBandageDetails: action.payload.data,
        selectedMedicationDetails: action.payload.data,
        dressingId: action.payload.id,
      };

    case ACTION_TYPES.EDIT_SELECTED_PARTICULAR_BANDAGE_DETAILS:
      return {
        ...state,
        selectedBandageDetails: action.payload.data,
        selectedMedicationDetails: action.payload.data,
        particularMedicationId: action.payload.id,
      };

    case ACTION_TYPES.DELETE_SCHEDULE_BANDAGE_SUCCESS:
      return {
        ...state,
        scheduleBandageDetails: [],
      };

    case ACTION_TYPES.GET_SCHEDULE_CALL_SUCCESS:
      return {
        ...state,
        scheduleCallDetails: action.payload.return_data,
      };

    case ACTION_TYPES.GET_SCHEDULE_CALL_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.JOIN_SCHEDULE_CALL_SUCCESS:
      return {
        ...state,
        room: action.payload.room,
      };

    case ACTION_TYPES.JOIN_SCHEDULE_CALL_ERROR:
      return {
        ...state,
        message: action?.payload?.error || "",
        error: action?.payload?.error || "",
      };

    case ACTION_TYPES.SELECTED_SCHEDULE_CALL_DETAILS:
      return {
        ...state,
        selectedScheduleCallDetails: action.payload.data,
      };

    case ACTION_TYPES.REMOVE_SCHEDULE_CALL_DETAILS:
      return {
        ...state,
        selectedScheduleCallDetails: [],
      };

    case ACTION_TYPES.DELETE_SCHEDULE_CALL_SUCCESS:
      return {
        ...state,
        selectedScheduleCallDetails: [],
      };

    case ACTION_TYPES.GET_MEDICATION_GRAPH_DETAILS_SUCCESS:
      return {
        ...state,
        getMedicationGraphDetails: action.payload.return_data,
      };

    case ACTION_TYPES.GET_DRESSING_GRAPH_DETAILS_SUCCESS:
      return {
        ...state,
        getDressingGraphDetails: action.payload.return_data,
      };

    case ACTION_TYPES.GET_MEDICATION_GRAPH_DETAILS_OVERVIEW_SUCCESS:
      return {
        ...state,
        getMedicationGraphDetailsOverview: action.payload.return_data,
      };

    case ACTION_TYPES.GET_DRESSING_GRAPH_DETAILS_OVERVIEW_SUCCESS:
      return {
        ...state,
        getDressingGraphDetailsOverview: action.payload.return_data,
      };

    case ACTION_TYPES.GET_OVERALL_TREATMENT_FOLLOWED_SUCCESS:
      return {
        ...state,
        overallTreatmentFollowedValues: action.payload.return_data,
      };

    case ACTION_TYPES.GET_TREATMENT_PRESCRIBE_SUCCESS:
      return {
        ...state,
        treatmentPrescribeData:
          action.payload.return_data?.treatmentPrescribe || [],
      };

    case ACTION_TYPES.CLEAR_WOUND_STATE_OBJ_DATA:
      return {
        ...state,
        woundHighDetails: {},
        woundBeforeClean: {},
        woundAfterClean: {},
        woundHoverDetails: {},
        overallTreatmentFollowedValues: {},
      };

    case ACTION_TYPES.GET_SCHEDULED_TREATMENT_SUCCESS:
      return {
        ...state,
        scheduledTreatments: action.payload.return_data,
      };

    case ACTION_TYPES.GET_SCHEDULED_TREATMENT_ERROR:
      return {
        ...state,
        scheduledTreatments: [],
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };
    case ACTION_TYPES.GET_PRINT_PRISCRIPTION_URL_SUCCESS:
      return {
        ...state,
        printPrescriptionUrl: action.payload.return_data,
      };

    case ACTION_TYPES.GET_PRINT_PRISCRIPTION_URL_ERROR:
      return {
        ...state,
        printPrescriptionUrl: { pdfUrl: "" },
      };
    case ACTION_TYPES.ADD_SELECTED_TREATMENT_DATA_FOR_EDIT:
      return {
        ...state,
        selectedTreatmentDetail: action.payload,
      };

    case ACTION_TYPES.WOUND_CLOSE_DET_SUCCESS:
      return {
        ...state,
        closeWoundDetails: action,
      };

    case ACTION_TYPES.WOUND_CLOSE_DET_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };
    case ACTION_TYPES.HIDE_IMAGE_FOR_PDF:
      return {
        ...state,
        hideImagePdf: action.payload
      };
    case ACTION_TYPES.CLEAR_WOUND_STATE_ARRAY_DATA:
      return {
        ...state,
        woundsList: [],
        avgHealingList: [],
        closeWoundList: [],
        woundDetails: [],
        woundDates: [],
        woundClose: [],
        woundDetailsOverView: [],
        TissueDistribution: [],
        TissueDistributionOthers: [],
        PeriTissueDistribution: [],
        PeriTissueDistributionOthers: [],
        TissueDistributionBeforeCleaning: [],
        TissueDistributionBeforeCleaningOthers: [],
        PeriTissueDistributionBeforeCleaning: [],
        PeriTissueDistributionBeforeCleaningOthers: [],
        woundComments: [],
        woundDelete: [],
        woundSideDetails: [],
        woundHoverDetails: {},
        algoDetailsOnDate: {},
        analysisWoundImages: [],
        analysisHealingScore: [],
        analysisOdourLevel: [],
        analysisPainLevel: [],
        analysisWoundSize: [],
        analysisStage: [],
        analysisWoundDepth: [],
        analysisTemperature: [],
        woundTissueDistributionBewteenDate: [],
        periWoundTissueDistributionBewteenDate: [],
        closeWoundDetails: []
      };

    default:
      return state;
  }
};

export default WoundsReducer;
