// import { ContactSupportOutlined, PanoramaSharp } from "@material-ui/icons";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
// import { map } from "lodash";
// import { connectAdvanced } from "react-redux";
const moment = require("moment");

dayjs.extend(advancedFormat);

//To concate the path for the public folder
export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

export async function getVideoMetaData(videoFile) {

  const video = document.createElement("video");
  video.src = videoFile;

  console.log('videoFile', videoFile);

  // Wait for the video to load metadata
  video.addEventListener("loadedmetadata", () => {
    // Set the canvas dimensions to the video dimensions
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;

    // Draw the first frame of the video onto the canvas
    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

    // Convert the canvas to a data URL
    const thumbnailUrl = canvas.toDataURL();

    console.log('thumbnailUrl',thumbnailUrl);
  });
  // return thumbnailUrl
}


export const toHospitalLogoUrl = (pathname) =>
  `https://s3-ap-southeast-1.amazonaws.com/trails.wound.management/hospitalImages/${pathname}`;

export const title = [
  { value: "Dr.", label: "Dr." },
  { value: "Mr.", label: "Mr." },
  { value: "Mrs.", label: "Mrs." },
  { value: "Ms.", label: "Ms." },
];

export const closeWoundReasons = [
  { value: "Amputation", label: "Amputation" },
  { value: "Merged", label: "Merged" },
  { value: "Epithelialized", label: "Epithelialized" }
];

export const estimated = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
];

export const SPO2Arr = [
  { val: "0-69", label: "Cyanosis", colorClass: "label-cyanosis" },
  {
    val: "70-79",
    label: "Critical Blood Oxygen",
    colorClass: "label-good",
  },
  {
    val: "80-89",
    label: "Low Blood Oxygen",
    colorClass: "label-excellent",
  },
  {
    val: "90-100",
    label: "Normal Blood Oxygen",
    colorClass: "label-athlete",
  },
];

export const SPO2ArrMobile = [
  { val: "0-69", label: "Cyanosis", colorClass: "label-cyanosis" },
  {
    val: "70-79",
    label: "Critical",
    colorClass: "label-good",
  },
  {
    val: "80-89",
    label: "Low",
    colorClass: "label-excellent",
  },
  {
    val: "90-100",
    label: "Normal",
    colorClass: "label-athlete",
  },
];
/*
export const AgeWiseBPMMale = [
  {
    label: "0-4",
    subArr: [
      { bpm: "0-104", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "104-117", label: "Great", colorClass: "label-great" },
      { bpm: "117-300", label: "Poor", colorClass: "label-below-avg" },
    ],
  },
  {
    label: "5-12",
    subArr: [
      { bpm: "0-84", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "84-99", label: "Great", colorClass: "label-great" },
      { bpm: "99-300", label: "Poor", colorClass: "label-below-avg" },
    ],
  },
  {
    label: "13-17",
    subArr: [
      { bpm: "0-73", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "73-85", label: "Great", colorClass: "label-great" },
      { bpm: "85-300", label: "Poor", colorClass: "label-below-avg" },
    ],
  },
  {
    label: "18-25",
    subArr: [
      { bpm: "49-56", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "56-62", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "62-66", label: "Great", colorClass: "label-great" },
      { bpm: "66-70", label: "Good", colorClass: "label-good" },
      { bpm: "70-74", label: "Average", colorClass: "label-average" },
      { bpm: "74-82", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "82-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "26-35",
    subArr: [
      { bpm: "49-55", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "55-62", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "62-66", label: "Great", colorClass: "label-great" },
      { bpm: "66-71", label: "Good", colorClass: "label-good" },
      { bpm: "71-75", label: "Average", colorClass: "label-average" },
      { bpm: "75-82", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "82-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "36-45",
    subArr: [
      { bpm: "50-57", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "57-63", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "63-67", label: "Great", colorClass: "label-great" },
      { bpm: "67-71", label: "Good", colorClass: "label-good" },
      { bpm: "71-76", label: "Average", colorClass: "label-average" },
      { bpm: "76-83", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "83-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "46-55",
    subArr: [
      { bpm: "50-58", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "58-64", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "64-68", label: "Great", colorClass: "label-great" },
      { bpm: "68-72", label: "Good", colorClass: "label-good" },
      { bpm: "72-77", label: "Average", colorClass: "label-average" },
      { bpm: "77-84", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "84-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "56-65",
    subArr: [
      { bpm: "51-57", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "57-62", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "62-68", label: "Great", colorClass: "label-great" },
      { bpm: "68-72", label: "Good", colorClass: "label-good" },
      { bpm: "72-76", label: "Average", colorClass: "label-average" },
      { bpm: "76-82", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "82-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "66-100",
    subArr: [
      { bpm: "50-56", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "56-62", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "62-66", label: "Great", colorClass: "label-great" },
      { bpm: "66-70", label: "Good", colorClass: "label-good" },
      { bpm: "70-74", label: "Average", colorClass: "label-average" },
      { bpm: "74-80", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "80-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
];

export const AgeWiseBPMFemale = [
  {
    label: "0-4",
    subArr: [
      { bpm: "0-104", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "104-117", label: "Great", colorClass: "label-great" },
      { bpm: "117-300", label: "Poor", colorClass: "label-below-avg" },
    ],
  },
  {
    label: "5-12",
    subArr: [
      { bpm: "0-84", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "84-99", label: "Great", colorClass: "label-great" },
      { bpm: "99-300", label: "Poor", colorClass: "label-below-avg" },
    ],
  },
  {
    label: "13-17",
    subArr: [
      { bpm: "0-73", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "73-85", label: "Great", colorClass: "label-great" },
      { bpm: "85-300", label: "Poor", colorClass: "label-below-avg" },
    ],
  },
  {
    label: "18-25",
    subArr: [
      { bpm: "54-61", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "61-66", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "66-70", label: "Great", colorClass: "label-great" },
      { bpm: "70-74", label: "Good", colorClass: "label-good" },
      { bpm: "74-78", label: "Average", colorClass: "label-average" },
      { bpm: "79-85", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "85-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "26-35",
    subArr: [
      { bpm: "54-60", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "60-65", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "65-69", label: "Great", colorClass: "label-great" },
      { bpm: "69-73", label: "Good", colorClass: "label-good" },
      { bpm: "73-77", label: "Average", colorClass: "label-average" },
      { bpm: "77-83", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "83-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "36-45",
    subArr: [
      { bpm: "54-60", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "60-65", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "65-70", label: "Great", colorClass: "label-great" },
      { bpm: "70-73", label: "Good", colorClass: "label-good" },
      { bpm: "74-79", label: "Average", colorClass: "label-average" },
      { bpm: "79-85", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "85-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "46-55",
    subArr: [
      { bpm: "54-61", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "61-66", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "66-70", label: "Great", colorClass: "label-great" },
      { bpm: "70-74", label: "Good", colorClass: "label-good" },
      { bpm: "74-78", label: "Average", colorClass: "label-average" },
      { bpm: "78-84", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "84-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "56-65",
    subArr: [
      { bpm: "54-60", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "60-65", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "65-69", label: "Great", colorClass: "label-great" },
      { bpm: "69-74", label: "Good", colorClass: "label-good" },
      { bpm: "74-78", label: "Average", colorClass: "label-average" },
      { bpm: "78-84", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "84-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "66-100",
    subArr: [
      { bpm: "54-60", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "60-65", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "65-69", label: "Great", colorClass: "label-great" },
      { bpm: "69-73", label: "Good", colorClass: "label-good" },
      { bpm: "73-77", label: "Average", colorClass: "label-average" },
      { bpm: "77-85", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "85-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
];*/

export const AgeWiseBPMMale = [
  // {
  //   label: "0-4",
  //   subArr: [
  //     { bpm: "0-103", label: "Poor", colorClass: "label-below-avg" },
  //     { bpm: "104-116", label: "Great", colorClass: "label-great" },
  //     { bpm: "117-300", label: "Poor", colorClass: "label-below-avg" },
  //   ],
  // },
  // {
  //   label: "5-12",
  //   subArr: [
  //     { bpm: "0-83", label: "Poor", colorClass: "label-below-avg" },
  //     { bpm: "84-98", label: "Great", colorClass: "label-great" },
  //     { bpm: "99-300", label: "Poor", colorClass: "label-below-avg" },
  //   ],
  // },
  // {
  //   label: "13-17",
  //   subArr: [
  //     { bpm: "0-72", label: "Poor", colorClass: "label-below-avg" },
  //     { bpm: "73-84", label: "Great", colorClass: "label-great" },
  //     { bpm: "85-300", label: "Poor", colorClass: "label-below-avg" },
  //   ],
  // },
  {
    label: "0-25",
    subArr: [
      { bpm: "48-55", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "55-61", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "61-65", label: "Great", colorClass: "label-great" },
      { bpm: "65-69", label: "Good", colorClass: "label-good" },
      { bpm: "69-73", label: "Average", colorClass: "label-average" },
      { bpm: "73-81", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "81-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "26-35",
    subArr: [
      { bpm: "49-54", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "56-61", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "62-65", label: "Great", colorClass: "label-great" },
      { bpm: "66-70", label: "Good", colorClass: "label-good" },
      { bpm: "71-74", label: "Average", colorClass: "label-average" },
      { bpm: "75-81", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "82-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "36-45",
    subArr: [
      { bpm: "50-56", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "57-62", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "63-66", label: "Great", colorClass: "label-great" },
      { bpm: "67-70", label: "Good", colorClass: "label-good" },
      { bpm: "71-75", label: "Average", colorClass: "label-average" },
      { bpm: "76-82", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "83-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "46-55",
    subArr: [
      { bpm: "50-57", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "58-63", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "64-67", label: "Great", colorClass: "label-great" },
      { bpm: "68-71", label: "Good", colorClass: "label-good" },
      { bpm: "72-76", label: "Average", colorClass: "label-average" },
      { bpm: "77-83", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "84-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "56-65",
    subArr: [
      { bpm: "51-56", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "57-61", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "62-67", label: "Great", colorClass: "label-great" },
      { bpm: "68-71", label: "Good", colorClass: "label-good" },
      { bpm: "72-75", label: "Average", colorClass: "label-average" },
      { bpm: "76-81", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "82-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "66-100",
    subArr: [
      { bpm: "50-55", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "56-61", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "62-65", label: "Great", colorClass: "label-great" },
      { bpm: "66-69", label: "Good", colorClass: "label-good" },
      { bpm: "70-73", label: "Average", colorClass: "label-average" },
      { bpm: "74-79", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "80-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
];

export const AgeWiseBPMFemale = [
  // {
  //   label: "0-4",
  //   subArr: [
  //     { bpm: "0-103", label: "Poor", colorClass: "label-below-avg" },
  //     { bpm: "104-116", label: "Great", colorClass: "label-great" },
  //     { bpm: "117-300", label: "Poor", colorClass: "label-below-avg" },
  //   ],
  // },
  // {
  //   label: "5-12",
  //   subArr: [
  //     { bpm: "0-83", label: "Poor", colorClass: "label-below-avg" },
  //     { bpm: "84-98", label: "Great", colorClass: "label-great" },
  //     { bpm: "99-300", label: "Poor", colorClass: "label-below-avg" },
  //   ],
  // },
  // {
  //   label: "13-17",
  //   subArr: [
  //     { bpm: "0-72", label: "Poor", colorClass: "label-below-avg" },
  //     { bpm: "73-84", label: "Great", colorClass: "label-great" },
  //     { bpm: "85-300", label: "Poor", colorClass: "label-below-avg" },
  //   ],
  // },
  // {
  //   label: "18-25",
  //   subArr: [
  //     { bpm: "50-60", label: "Athlete", colorClass: "label-athlete" },
  //     { bpm: "61-65", label: "Excellent", colorClass: "label-excellent" },
  //     { bpm: "66-69", label: "Great", colorClass: "label-great" },
  //     { bpm: "70-73", label: "Good", colorClass: "label-good" },
  //     { bpm: "74-78", label: "Average", colorClass: "label-average" },
  //     { bpm: "79-84", label: "Poor", colorClass: "label-below-avg" },
  //     { bpm: "85-500", label: "Critical", colorClass: "label-cyanosis" },
  //   ],
  // },
  {
    label: "0-25",
    subArr: [
      { bpm: "49-60", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "60-65", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "65-69", label: "Great", colorClass: "label-great" },
      { bpm: "69-73", label: "Good", colorClass: "label-good" },
      { bpm: "73-78", label: "Average", colorClass: "label-average" },
      { bpm: "78-84", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "84-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "26-35",
    subArr: [
      { bpm: "49-59", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "60-64", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "65-68", label: "Great", colorClass: "label-great" },
      { bpm: "69-72", label: "Good", colorClass: "label-good" },
      { bpm: "73-76", label: "Average", colorClass: "label-average" },
      { bpm: "77-82", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "83-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "36-45",
    subArr: [
      { bpm: "49-59", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "60-64", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "65-69", label: "Great", colorClass: "label-great" },
      { bpm: "70-73", label: "Good", colorClass: "label-good" },
      { bpm: "74-78", label: "Average", colorClass: "label-average" },
      { bpm: "79-84", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "85-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "46-55",
    subArr: [
      { bpm: "49-60", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "61-65", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "66-69", label: "Great", colorClass: "label-great" },
      { bpm: "70-73", label: "Good", colorClass: "label-good" },
      { bpm: "74-77", label: "Average", colorClass: "label-average" },
      { bpm: "78-83", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "84-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "56-65",
    subArr: [
      { bpm: "49-59", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "60-64", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "65-68", label: "Great", colorClass: "label-great" },
      { bpm: "69-73", label: "Good", colorClass: "label-good" },
      { bpm: "74-77", label: "Average", colorClass: "label-average" },
      { bpm: "78-83", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "84-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
  {
    label: "66-100",
    subArr: [
      { bpm: "49-59", label: "Athlete", colorClass: "label-athlete" },
      { bpm: "60-64", label: "Excellent", colorClass: "label-excellent" },
      { bpm: "65-68", label: "Great", colorClass: "label-great" },
      { bpm: "69-72", label: "Good", colorClass: "label-good" },
      { bpm: "73-76", label: "Average", colorClass: "label-average" },
      { bpm: "77-84", label: "Poor", colorClass: "label-below-avg" },
      { bpm: "85-500", label: "Critical", colorClass: "label-cyanosis" },
    ],
  },
];

export const BodyTempArr = [
  {
    value: "0-35.7",
    label: "Low",
    colorClass: "label-low-tmp",
  },
  {
    value: "35.8-36.9",
    label: "Normal",
    colorClass: "label-athlete",
  },
  {
    value: "37.0-37.5",
    label: "Increased",
    colorClass: "label-excellent",
  },
  {
    value: "37.6-38.0",
    label: "Light",
    colorClass: "label-great",
  },
  {
    value: "38.1-38.5",
    label: "Moderate",
    colorClass: "label-good",
  },
  {
    value: "38.6-39.4",
    label: "High",
    colorClass: "label-average",
  },
  {
    value: "39.5-100.0",
    label: "Critical",
    colorClass: "label-cyanosis",
  },
];

export const BodyTempArrMobile = [
  {
    value: "0-36.6",
    label: "Subnormal",
    colorClass: "label-low-tmp",
  },
  {
    value: "36.7-37.1",
    label: "Normal",
    colorClass: "label-athlete",
  },
  {
    value: "37.2-37.8",
    label: "Mild",
    colorClass: "label-excellent",
  },
  {
    value: "37.9-39.4",
    label: "Moderate",
    colorClass: "label-great",
  },
  {
    value: "39.5-100.0",
    label: "High",
    colorClass: "label-cyanosis",
  },
];

export const purposeContact = [
  { value: "General Enquiry", label: "General Enquiry" },
  {
    value: "Collaboration / Partnership",
    label: "Collaboration / Partnership",
  },
  { value: "Technical Support", label: "Technical Support" },
  { value: "Research", label: "Research" },
  { value: "Join the Team", label: "Join the Team" },
  { value: "Other", label: "Other" },
];

export const woundStages = [
  "Hemostatis",
  "Proliferative",
  "Remodeling/Maturation",
  "Inflammatory",
];

export const TissueTypeOption = [
  {
    name: "Epithelial",
    labeltext: "Epithelial",
  },
  {
    name: "Healthy Granulation",
    labeltext: "Healthy Granulation",
  },
  {
    name: "Unhealthy Granulation ",
    labeltext: "Unhealthy Granulation ",
  },
  {
    name: "Necrotic / Eschar",
    labeltext: "Necrotic / Eschar",
  },
  {
    name: "Slough",
    labeltext: "Slough",
  },
  {
    name: "Dehydration",
    labeltext: "Dehydration",
  },
  {
    name: "Maceration",
    labeltext: "Maceration",
  },
  {
    name: "Fat Tissue",
    labeltext: "Fat Tissue",
  },
  {
    name: "Dead Fat",
    labeltext: "Dead Fat",
  },
  {
    name: "Others",
    labeltext: "Others",
  },
];

export const sugarLevelArr = [
  { value: "4.0", label: "Super Optimal", colorClass: "label-athlete" },
  { value: "4.1", label: "Super Optimal", colorClass: "label-athlete" },
  { value: "4.2", label: "Super Optimal", colorClass: "label-athlete" },
  { value: "4.3", label: "Super Optimal", colorClass: "label-athlete" },
  { value: "4.4", label: "Super Optimal", colorClass: "label-athlete" },

  { value: "4.5", label: "Super Optimal", colorClass: "label-athlete" },
  { value: "4.6", label: "Super Optimal", colorClass: "label-athlete" },
  { value: "4.7", label: "Super Optimal", colorClass: "label-athlete" },
  { value: "4.8", label: "Super Optimal", colorClass: "label-athlete" },
  { value: "4.9", label: "Super Optimal", colorClass: "label-athlete" },

  { value: "5.0", label: "Normal", colorClass: "label-excellent" },
  { value: "5.1", label: "Normal", colorClass: "label-excellent" },
  { value: "5.2", label: "Normal", colorClass: "label-excellent" },
  { value: "5.3", label: "Normal", colorClass: "label-excellent" },
  { value: "5.4", label: "Normal", colorClass: "label-excellent" },

  { value: "5.5", label: "Normal", colorClass: "label-excellent" },
  { value: "5.6", label: "Normal", colorClass: "label-excellent" },
  { value: "5.7", label: "Normal", colorClass: "label-excellent" },
  { value: "5.8", label: "Normal", colorClass: "label-excellent" },
  { value: "5.9", label: "Normal", colorClass: "label-excellent" },

  { value: "6.0", label: "Pre-Diabetic", colorClass: "label-great" },
  { value: "6.1", label: "Pre-Diabetic", colorClass: "label-great" },
  { value: "6.2", label: "Pre-Diabetic", colorClass: "label-great" },
  { value: "6.3", label: "Pre-Diabetic", colorClass: "label-great" },
  { value: "6.4", label: "Pre-Diabetic", colorClass: "label-great" },

  { value: "6.5", label: "Pre-Diabetic", colorClass: "label-great" },
  { value: "6.6", label: "Pre-Diabetic", colorClass: "label-great" },
  { value: "6.7", label: "Pre-Diabetic", colorClass: "label-great" },
  { value: "6.8", label: "Pre-Diabetic", colorClass: "label-great" },
  { value: "6.9", label: "Pre-Diabetic", colorClass: "label-great" },

  { value: "7.0", label: "Diabetic", colorClass: "label-average" },
  { value: "7.1", label: "Diabetic", colorClass: "label-average" },
  { value: "7.2", label: "Diabetic", colorClass: "label-average" },
  { value: "7.3", label: "Diabetic", colorClass: "label-average" },
  { value: "7.4", label: "Diabetic", colorClass: "label-average" },
  { value: "7.5", label: "Diabetic", colorClass: "label-average" },
  { value: "7.6", label: "Diabetic", colorClass: "label-average" },
  { value: "7.7", label: "Diabetic", colorClass: "label-average" },
  { value: "7.8", label: "Diabetic", colorClass: "label-average" },
  { value: "7.9", label: "Diabetic", colorClass: "label-average" },

  { value: "8.0", label: "Dangerous", colorClass: "label-below-avg" },
  { value: "8.1", label: "Dangerous", colorClass: "label-below-avg" },
  { value: "8.2", label: "Dangerous", colorClass: "label-below-avg" },
  { value: "8.3", label: "Dangerous", colorClass: "label-below-avg" },
  { value: "8.4", label: "Dangerous", colorClass: "label-below-avg" },
  { value: "8.5", label: "Dangerous", colorClass: "label-below-avg" },
  { value: "8.6", label: "Dangerous", colorClass: "label-below-avg" },
  { value: "8.7", label: "Dangerous", colorClass: "label-below-avg" },
  { value: "8.8", label: "Dangerous", colorClass: "label-below-avg" },
  { value: "8.9", label: "Dangerous", colorClass: "label-below-avg" },

  { value: "9.0", label: "Deadly", colorClass: "label-cyanosis" },
  { value: "9.1", label: "Deadly", colorClass: "label-cyanosis" },
  { value: "9.2", label: "Deadly", colorClass: "label-cyanosis" },
  { value: "9.3", label: "Deadly", colorClass: "label-cyanosis" },
  { value: "9.4", label: "Deadly", colorClass: "label-cyanosis" },
  { value: "9.5", label: "Deadly", colorClass: "label-cyanosis" },
  { value: "9.6", label: "Deadly", colorClass: "label-cyanosis" },
  { value: "9.7", label: "Deadly", colorClass: "label-cyanosis" },
  { value: "9.8", label: "Deadly", colorClass: "label-cyanosis" },
  { value: "9.9", label: "Deadly", colorClass: "label-cyanosis" },

  // { value: "9.5", label: "Suicidal", colorClass: "label-suicide" },
  // { value: "10.0", label: "Suicidal", colorClass: "label-suicide" },
  // { value: "10.5", label: "Suicidal", colorClass: "label-suicide" },
  // { value: "11.0", label: "Suicidal", colorClass: "label-suicide" },
  // { value: "11.5", label: "Suicidal", colorClass: "label-suicide" },
  // { value: "12.0", label: "Suicidal", colorClass: "label-suicide" },
  // { value: "12.5", label: "Suicidal", colorClass: "label-suicide" },
  // { value: "13.0", label: "Suicidal", colorClass: "label-suicide" },
  // { value: "13.5", label: "Suicidal", colorClass: "label-suicide" },
];

export const Duration = [
  {
    label: "End Date",
    value: "END_DATE",
  },
  {
    label: "Number of Days",
    value: "NO_OF_DAYS",
  },
];

export const Recurrence = [
  {
    label: "Everyday",
    value: "EVERYDAY",
  },
  {
    label: "Specific Days",
    value: "SPECIFIC_DAYS",
  },
  {
    label: "Day Interval",
    value: "DAY_INTERVAL",
  },
  {
    label: "Anytime",
    value: "anytime",
  },
];

export const RecurrenceWithoutAnytime = [
  {
    label: "Everyday",
    value: "EVERYDAY",
  },
  {
    label: "Specific Days",
    value: "SPECIFIC_DAYS",
  },
  {
    label: "Day Interval",
    value: "DAY_INTERVAL",
  },
];

export const Gender = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
  // {
  //     label: "Other",
  //     value: "Other",
  // },
];

export const SugarLevelInPer = [
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  // { value: "10", label: "10" },
  // { value: "11", label: "11" },
  // { value: "12", label: "12" },
  // { value: "13", label: "13" },
  // { value: "14", label: "14" },
  // { value: "15", label: "15" },
  // { value: "16", label: "16" },
  // { value: "17", label: "17" },
  // { value: "18", label: "18" },
  // { value: "19", label: "19" },
  // { value: "20", label: "20" },
];

export const HbAOneCInMMOL = [
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
  { value: "51", label: "51" },
  { value: "52", label: "52" },
  { value: "53", label: "53" },
  { value: "54", label: "54" },
  { value: "55", label: "55" },
  { value: "56", label: "56" },
  { value: "57", label: "57" },
  { value: "58", label: "58" },
  { value: "59", label: "59" },
  { value: "60", label: "60" },
  { value: "61", label: "61" },
  { value: "62", label: "62" },
  { value: "63", label: "63" },
  { value: "64", label: "64" },
  { value: "65", label: "65" },
  { value: "66", label: "66" },
  { value: "67", label: "67" },
  { value: "68", label: "68" },
  { value: "69", label: "69" },
  { value: "70", label: "70" },
  { value: "71", label: "71" },
  { value: "72", label: "72" },
  { value: "73", label: "73" },
  { value: "74", label: "74" },
  { value: "75", label: "75" },
  { value: "76", label: "76" },
  { value: "77", label: "77" },
  { value: "78", label: "78" },
  { value: "79", label: "79" },
  { value: "80", label: "80" },
  { value: "81", label: "81" },
  { value: "82", label: "82" },
  { value: "83", label: "83" },
  { value: "84", label: "84" },
  { value: "85", label: "85" },
  // { value: "86", label: "86" },
  // { value: "87", label: "87" },
  // { value: "88", label: "88" },
  // { value: "89", label: "89" },
  // { value: "90", label: "90" },
  // { value: "89", label: "89" },
  // { value: "90", label: "90" },
  // { value: "91", label: "91" },
  // { value: "92", label: "92" },
  // { value: "93", label: "93" },
  // { value: "94", label: "94" },
  // { value: "95", label: "95" },
  // { value: "96", label: "96" },
  // { value: "97", label: "97" },
  // { value: "98", label: "98" },
  // { value: "99", label: "99" },
  // { value: "100", label: "100" },
  // { value: "101", label: "101" },
  // { value: "102", label: "102" },
  // { value: "103", label: "103" },
  // { value: "104", label: "104" },
  // { label: "105", value: "105", },
  // { label: "106", value: "106", },
  // { label: "107", value: "107", },
  // { label: "108", value: "108", },
  // { label: "109", value: "109", },
  // { label: "110", value: "110", },
  // { label: "111", value: "111", },
  // { label: "112", value: "112", },
  // { label: "113", value: "113", },
  // { label: "114", value: "114", },
  // { label: "115", value: "115", },
  // { label: "116", value: "116", },
  // { label: "117", value: "117", },
  // { label: "118", value: "118", },
  // { label: "119", value: "119", },
  // { label: "120", value: "120", },
  // { label: "121", value: "121", },
  // { label: "122", value: "122", },
  // { label: "123", value: "123", },
  // { label: "124", value: "124", },
  // { label: "125", value: "125", },
  // { label: "126", value: "126", },
  // { label: "127", value: "127", },
  // { label: "128", value: "128", },
  // { label: "129", value: "129", },
  // { label: "130", value: "130", },
  // { label: "131", value: "131", },
  // { label: "132", value: "132", },
  // { label: "133", value: "133", },
  // { label: "134", value: "134", },
  // { label: "135", value: "135", },
  // { label: "136", value: "136", },
  // { label: "137", value: "137", },
  // { label: "138", value: "138", },
  // { label: "139", value: "139", },
  // { label: "140", value: "140", },
  // { label: "141", value: "141", },
  // { label: "142", value: "142", },
  // { label: "143", value: "143", },
  // { label: "144", value: "144", },
  // { label: "145", value: "145", },
  // { label: "146", value: "146", },
  // { label: "147", value: "147", },
  // { label: "148", value: "148", },
  // { label: "149", value: "149", },
  // { label: "150", value: "150", },
  // { label: "151", value: "151", },
  // { label: "152", value: "152", },
  // { label: "153", value: "153", },
  // { label: "154", value: "154", },
  // { label: "155", value: "155", },
  // { label: "156", value: "156", },
  // { label: "157", value: "157", },
  // { label: "158", value: "158", },
  // { label: "159", value: "159", },
  // { label: "160", value: "160", },
  // { label: "161", value: "161", },
  // { label: "162", value: "162", },
  // { label: "163", value: "163", },
  // { label: "164", value: "164", },
  // { label: "165", value: "165", },
  // { label: "166", value: "166", },
  // { label: "167", value: "167", },
  // { label: "168", value: "168", },
  // { label: "169", value: "169", },
  // { label: "170", value: "170", },
  // { label: "171", value: "171", },
  // { label: "172", value: "172", },
  // { label: "173", value: "173", },
  // { label: "174", value: "174", },
  // { label: "175", value: "175", },
  // { label: "176", value: "176", },
  // { label: "177", value: "177", },
  // { label: "178", value: "178", },
  // { label: "179", value: "179", },
  // { label: "180", value: "180", },
  // { label: "181", value: "181", },
  // { label: "182", value: "182", },
  // { label: "183", value: "183", },
  // { label: "184", value: "184", },
  // { label: "185", value: "185", },
  // { label: "186", value: "186", },
  // { label: "187", value: "187", },
  // { label: "188", value: "188", },
  // { label: "189", value: "189", },
  // { label: "190", value: "190", },
  // { label: "191", value: "191", },
  // { label: "192", value: "192", },
  // { label: "193", value: "193", },
  // { label: "194", value: "194", },
  // { label: "195", value: "195", },
  // { label: "196", value: "196", },
  // { label: "197", value: "197", },
  // { label: "198", value: "198", },
  // { label: "199", value: "199", },
  // { label: "200", value: "200", },
  // { value: "201", label: "201" },
  // { value: "202", label: "202" },
  // { value: "203", label: "203" },
  // { value: "204", label: "204" },
];

export const BodyTemp = [
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
];

export const BodyTempCelcius = [
  // { value: "33", label: "33" },
  // { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
];

export const bodyTempFahrenheit = [
  // { value: "89", label: "89" },
  // { value: "90", label: "90" },
  // { value: "91", label: "91" },
  // { value: "92", label: "92" },
  // { value: "93", label: "93" },
  // { value: "94", label: "94" },
  { value: "95", label: "<=95" },
  { value: "96", label: "96" },
  { value: "97", label: "97" },
  { value: "98", label: "98" },
  { value: "99", label: "99" },
  { value: "100", label: "100" },
  { value: "101", label: "101" },
  { value: "102", label: "102" },
  { value: "103", label: "103" },
  { value: "104", label: "104" },
  { value: "105", label: "105" },
  { value: "106", label: "106" },
  { value: "107", label: "107" },
];

export const ResRate = [
  // {
  //   label: "0",
  //   value: "0",
  //   label_name: "Severe",
  //   colorClass: "label-cyanosis",
  // },
  // {
  //   value: "1",
  //   label: "1",
  //   label_name: "Severe",
  //   colorClass: "label-cyanosis",
  // },
  // {
  //   value: "2",
  //   label: "2",
  //   label_name: "Severe",
  //   colorClass: "label-cyanosis",
  // },
  // {
  //   value: "3",
  //   label: "3",
  //   label_name: "Severe",
  //   colorClass: "label-cyanosis",
  // },
  // {
  //   value: "4",
  //   label: "4",
  //   label_name: "Severe",
  //   colorClass: "label-cyanosis",
  // },
  // {
  //   value: "5",
  //   label: "5",
  //   label_name: "Severe",
  //   colorClass: "label-cyanosis",
  // },
  // {
  //   value: "6",
  //   label: "6",
  //   label_name: "Severe",
  //   colorClass: "label-cyanosis",
  // },
  // {
  //   value: "7",
  //   label: "7",
  //   label_name: "Severe",
  //   colorClass: "label-cyanosis",
  // },
  {
    value: "8",
    label: "<=8",
    label_name: "Severe",
    colorClass: "label-cyanosis",
  },
  {
    value: "9",
    label: "9",
    label_name: "Moderate",
    colorClass: "label-good",
  },
  {
    value: "10",
    label: "10",
    label_name: "Moderate",
    colorClass: "label-good",
  },
  {
    value: "11",
    label: "11",
    label_name: "Moderate",
    colorClass: "label-good",
  },
  {
    value: "12",
    label: "12",
    label_name: "Normal",
    colorClass: "label-excellent",
  },
  {
    value: "13",
    label: "13",
    label_name: "Normal",
    colorClass: "label-excellent",
  },
  {
    value: "14",
    label: "14",
    label_name: "Normal",
    colorClass: "label-excellent",
  },
  {
    value: "15",
    label: "15",
    label_name: "Normal",
    colorClass: "label-excellent",
  },
  {
    value: "16",
    label: "16",
    label_name: "Normal",
    colorClass: "label-excellent",
  },
  {
    value: "17",
    label: "17",
    label_name: "Normal",
    colorClass: "label-excellent",
  },
  {
    value: "18",
    label: "18",
    label_name: "Normal",
    colorClass: "label-excellent",
  },
  {
    value: "19",
    label: "19",
    label_name: "Normal",
    colorClass: "label-excellent",
  },
  {
    value: "20",
    label: "20",
    label_name: "Normal",
    colorClass: "label-excellent",
  },
  {
    value: "21",
    label: "21",
    label_name: "Moderate",
    colorClass: "label-good",
  },
  {
    value: "22",
    label: "22",
    label_name: "Moderate",
    colorClass: "label-good",
  },
  {
    value: "23",
    label: "23",
    label_name: "Moderate",
    colorClass: "label-good",
  },
  {
    value: "24",
    label: "24",
    label_name: "Moderate",
    colorClass: "label-good",
  },
  {
    value: "25",
    label: ">=25",
    label_name: "Severe",
    colorClass: "label-cyanosis",
  },
  // {
  //   value: "26",
  //   label: "26",
  //   label_name: "Severe",
  //   colorClass: "label-cyanosis",
  // },
  // {
  //   value: "27",
  //   label: "27",
  //   label_name: "Severe",
  //   colorClass: "label-cyanosis",
  // },
  // {
  //   value: "28",
  //   label: "28",
  //   label_name: "Severe",
  //   colorClass: "label-cyanosis",
  // },
  // {
  //   value: "29",
  //   label: "29",
  //   label_name: "Severe",
  //   colorClass: "label-cyanosis",
  // },
  // {
  //   value: "30",
  //   label: "30",
  //   label_name: "Severe",
  //   colorClass: "label-cyanosis",
  // },
];

export const StressLevel = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
]

export const SPO2Level = [
  {
    label: "65",
    value: "65",
  },
  {
    label: "66",
    value: "66",
  },
  {
    label: "67",
    value: "67",
  },
  {
    label: "68",
    value: "68",
  },
  {
    label: "69",
    value: "69",
  },
  {
    label: "70",
    value: "70",
  },
  {
    label: "71",
    value: "71",
  },
  {
    label: "72",
    value: "72",
  },
  {
    label: "73",
    value: "73",
  },
  {
    label: "74",
    value: "74",
  },
  {
    label: "75",
    value: "75",
  },
  {
    label: "76",
    value: "76",
  },
  {
    label: "77",
    value: "77",
  },
  {
    label: "78",
    value: "78",
  },
  {
    label: "79",
    value: "79",
  },
  {
    label: "80",
    value: "80",
  },
  {
    label: "81",
    value: "81",
  },
  {
    label: "82",
    value: "82",
  },
  {
    label: "83",
    value: "83",
  },
  {
    label: "84",
    value: "84",
  },
  {
    label: "85",
    value: "85",
  },
  {
    label: "86",
    value: "86",
  },
  {
    label: "87",
    value: "87",
  },
  {
    label: "88",
    value: "88",
  },
  {
    label: "89",
    value: "89",
  },
  {
    label: "90",
    value: "90",
  },
  {
    label: "91",
    value: "91",
  },
  {
    label: "92",
    value: "92",
  },
  {
    label: "93",
    value: "93",
  },
  {
    label: "94",
    value: "94",
  },
  {
    label: "95",
    value: "95",
  },
  {
    label: "96",
    value: "96",
  },
  {
    label: "97",
    value: "97",
  },
  {
    label: "98",
    value: "98",
  },
  {
    label: "99",
    value: "99",
  },
  {
    label: "100",
    value: "100",
  },
];

export const HeartRate = [
  {
    label: "49",
    value: "49",
  },
  {
    label: "50",
    value: "50",
  },
  {
    label: "51",
    value: "51",
  },
  {
    label: "52",
    value: "52",
  },
  {
    label: "53",
    value: "53",
  },
  {
    label: "54",
    value: "54",
  },
  {
    label: "55",
    value: "55",
  },
  {
    label: "56",
    value: "56",
  },
  {
    label: "57",
    value: "57",
  },
  {
    label: "58",
    value: "58",
  },
  {
    label: "59",
    value: "59",
  },
  {
    label: "60",
    value: "60",
  },
  {
    label: "61",
    value: "61",
  },
  {
    label: "62",
    value: "62",
  },
  {
    label: "63",
    value: "63",
  },

  {
    label: "64",
    value: "64",
  },
  {
    label: "65",
    value: "65",
  },
  {
    label: "66",
    value: "66",
  },
  {
    label: "67",
    value: "67",
  },
  {
    label: "68",
    value: "68",
  },
  {
    label: "69",
    value: "69",
  },
  {
    label: "70",
    value: "70",
  },
  {
    label: "71",
    value: "71",
  },
  {
    label: "72",
    value: "72",
  },
  {
    label: "73",
    value: "73",
  },
  {
    label: "74",
    value: "74",
  },
  {
    label: "75",
    value: "75",
  },
  {
    label: "76",
    value: "76",
  },
  {
    label: "77",
    value: "77",
  },
  {
    label: "78",
    value: "78",
  },
  {
    label: "79",
    value: "79",
  },
  {
    label: "80",
    value: "80",
  },
  {
    label: "81",
    value: "81",
  },
  {
    label: "82",
    value: "82",
  },
  {
    label: ">=83",
    value: "83",
  },
  // {
  //   label: "84",
  //   value: "84",
  // },
  // {
  //   label: "85",
  //   value: "85",
  // },
  // {
  //   label: "86",
  //   value: "86",
  // },
  // {
  //   label: "87",
  //   value: "87",
  // },
  // {
  //   label: "88",
  //   value: "88",
  // },
  // {
  //   label: "89",
  //   value: "89",
  // },
  // {
  //   label: "90",
  //   value: "90",
  // },
  // {
  //   label: "91",
  //   value: "91",
  // },
  // {
  //   label: "92",
  //   value: "92",
  // },
  // {
  //   label: "93",
  //   value: "93",
  // },
  // {
  //   label: "94",
  //   value: "94",
  // },
  // {
  //   label: "95",
  //   value: "95",
  // },

  // {
  //   label: "96",
  //   value: "96",
  // },
  // {
  //   label: "97",
  //   value: "97",
  // },
  // {
  //   label: "98",
  //   value: "98",
  // },
  // {
  //   label: "99",
  //   value: "99",
  // },
  // {
  //   label: "100",
  //   value: "100",
  // },
  // {
  //   label: "101",
  //   value: "101",
  // },
  // {
  //   label: "102",
  //   value: "102",
  // },
  // {
  //   label: "103",
  //   value: "103",
  // },
  // {
  //   label: "104",
  //   value: "104",
  // },
  // {
  //   label: "105",
  //   value: "105",
  // },
  // {
  //   label: "106",
  //   value: "106",
  // },
  // {
  //   label: "107",
  //   value: "107",
  // },
  // {
  //   label: "108",
  //   value: "108",
  // },
  // {
  //   label: "109",
  //   value: "109",
  // },
  // {
  //   label: "110",
  //   value: "110",
  // },
  // {
  //   label: "111",
  //   value: "111",
  // },
  // {
  //   label: "112",
  //   value: "112",
  // },
  // {
  //   label: "113",
  //   value: "113",
  // },
  // {
  //   label: "114",
  //   value: "114",
  // },
  // {
  //   label: "115",
  //   value: "115",
  // },
  // {
  //   label: "116",
  //   value: "116",
  // },
  // {
  //   label: "117",
  //   value: "117",
  // },
  // {
  //   label: "118",
  //   value: "118",
  // },
  // {
  //   label: "119",
  //   value: "119",
  // },
  // {
  //   label: "120",
  //   value: "120",
  // },
  // {
  //   label: "121",
  //   value: "121",
  // },
  // {
  //   label: "122",
  //   value: "122",
  // },
  // {
  //   label: "123",
  //   value: "123",
  // },
  // {
  //   label: "124",
  //   value: "124",
  // },
  // {
  //   label: "125",
  //   value: "125",
  // },
  // {
  //   label: "126",
  //   value: "126",
  // },
  // {
  //   label: "127",
  //   value: "127",
  // },
  // {
  //   label: "128",
  //   value: "128",
  // },
  // {
  //   label: "129",
  //   value: "129",
  // },
  // {
  //   label: "130",
  //   value: "130",
  // },
  // {
  //   label: "131",
  //   value: "131",
  // },
  // {
  //   label: "132",
  //   value: "132",
  // },
  // {
  //   label: "133",
  //   value: "133",
  // },
  // {
  //   label: "134",
  //   value: "134",
  // },
  // {
  //   label: "135",
  //   value: "135",
  // },
  // {
  //   label: "136",
  //   value: "136",
  // },
  // {
  //   label: "137",
  //   value: "137",
  // },
  // {
  //   label: "138",
  //   value: "138",
  // },
  // {
  //   label: "139",
  //   value: "139",
  // },
  // {
  //   label: "140",
  //   value: "140",
  // },
  // {
  //   label: "141",
  //   value: "141",
  // },
  // {
  //   label: "142",
  //   value: "142",
  // },
  // {
  //   label: "143",
  //   value: "143",
  // },
  // {
  //   label: "144",
  //   value: "144",
  // },
  // {
  //   label: "145",
  //   value: "145",
  // },
  // {
  //   label: "146",
  //   value: "146",
  // },
  // {
  //   label: "147",
  //   value: "147",
  // },
  // {
  //   label: "148",
  //   value: "148",
  // },

  // {
  //   label: "149",
  //   value: "149",
  // },
  // {
  //   label: "150",
  //   value: "150",
  // },
  // {
  //   label: "151",
  //   value: "151",
  // },
  // {
  //   label: "152",
  //   value: "152",
  // },
  // {
  //   label: "153",
  //   value: "153",
  // },
  // {
  //   label: "154",
  //   value: "154",
  // },
  // {
  //   label: "155",
  //   value: "155",
  // },
  // {
  //   label: "156",
  //   value: "156",
  // },
  // {
  //   label: "157",
  //   value: "157",
  // },
  // {
  //   label: "158",
  //   value: "158",
  // },
  // {
  //   label: "159",
  //   value: "159",
  // },
  // {
  //   label: "160",
  //   value: "160",
  // },
  // {
  //   label: "161",
  //   value: "161",
  // },
  // {
  //   label: "162",
  //   value: "162",
  // },
  // {
  //   label: "163",
  //   value: "163",
  // },

  // {
  //   label: "164",
  //   value: "164",
  // },
  // {
  //   label: "165",
  //   value: "165",
  // },
  // {
  //   label: "166",
  //   value: "166",
  // },
  // {
  //   label: "167",
  //   value: "167",
  // },
  // {
  //   label: "168",
  //   value: "168",
  // },
  // {
  //   label: "169",
  //   value: "169",
  // },
  // {
  //   label: "170",
  //   value: "170",
  // },
  // {
  //   label: "171",
  //   value: "171",
  // },
  // {
  //   label: "172",
  //   value: "172",
  // },
  // {
  //   label: "173",
  //   value: "173",
  // },
  // {
  //   label: "174",
  //   value: "174",
  // },
  // {
  //   label: "175",
  //   value: "175",
  // },
  // {
  //   label: "176",
  //   value: "176",
  // },
  // {
  //   label: "177",
  //   value: "177",
  // },
  // {
  //   label: "178",
  //   value: "178",
  // },
  // {
  //   label: "179",
  //   value: "179",
  // },
  // {
  //   label: "180",
  //   value: "180",
  // },
  // {
  //   label: "181",
  //   value: "181",
  // },
  // {
  //   label: "182",
  //   value: "182",
  // },
  // {
  //   label: "183",
  //   value: "183",
  // },
  // {
  //   label: "184",
  //   value: "184",
  // },
  // {
  //   label: "185",
  //   value: "185",
  // },
  // {
  //   label: "186",
  //   value: "186",
  // },
  // {
  //   label: "187",
  //   value: "187",
  // },
  // {
  //   label: "188",
  //   value: "188",
  // },
  // {
  //   label: "189",
  //   value: "189",
  // },
  // {
  //   label: "190",
  //   value: "190",
  // },
  // {
  //   label: "191",
  //   value: "191",
  // },
  // {
  //   label: "192",
  //   value: "192",
  // },
  // {
  //   label: "193",
  //   value: "193",
  // },
  // {
  //   label: "194",
  //   value: "194",
  // },
  // {
  //   label: "195",
  //   value: "195",
  // },

  // {
  //   label: "196",
  //   value: "196",
  // },
  // {
  //   label: "197",
  //   value: "197",
  // },
  // {
  //   label: "198",
  //   value: "198",
  // },
  // {
  //   label: "199",
  //   value: "199",
  // },
  // {
  //   label: "200",
  //   value: "200",
  // },
];

export const HeightArrCM = [
  {
    label: "30 cm",
    value: "30",
  },
  {
    label: "31 cm",
    value: "31",
  },
  {
    label: "32 cm",
    value: "32",
  },
  {
    label: "33 cm",
    value: "33",
  },
  {
    label: "34 cm",
    value: "34",
  },
  {
    label: "35 cm",
    value: "35",
  },
  {
    label: "36 cm",
    value: "36",
  },
  {
    label: "37 cm",
    value: "37",
  },
  {
    label: "38 cm",
    value: "38",
  },
  {
    label: "39 cm",
    value: "39",
  },
  {
    label: "40 cm",
    value: "40",
  },
  {
    label: "41 cm",
    value: "41",
  },
  {
    label: "42 cm",
    value: "42",
  },
  {
    label: "43 cm",
    value: "43",
  },
  {
    label: "44 cm",
    value: "44",
  },
  {
    label: "45 cm",
    value: "45",
  },
  {
    label: "46 cm",
    value: "46",
  },
  {
    label: "47 cm",
    value: "47",
  },
  {
    label: "48 cm",
    value: "48",
  },
  {
    label: "49 cm",
    value: "49",
  },
  {
    label: "50 cm",
    value: "50",
  },
  {
    label: "51 cm",
    value: "51",
  },
  {
    label: "52 cm",
    value: "52",
  },
  {
    label: "53 cm",
    value: "53",
  },
  {
    label: "54 cm",
    value: "54",
  },
  {
    label: "55 cm",
    value: "55",
  },
  {
    label: "56 cm",
    value: "56",
  },
  {
    label: "57 cm",
    value: "57",
  },
  {
    label: "58 cm",
    value: "58",
  },
  {
    label: "59 cm",
    value: "59",
  },
  {
    label: "60 cm",
    value: "60",
  },
  {
    label: "61 cm",
    value: "61",
  },
  {
    label: "62 cm",
    value: "62",
  },
  {
    label: "63 cm",
    value: "63",
  },
  {
    label: "64 cm",
    value: "64",
  },
  {
    label: "65 cm",
    value: "65",
  },
  {
    label: "66 cm",
    value: "66",
  },
  {
    label: "67 cm",
    value: "67",
  },
  {
    label: "68 cm",
    value: "68",
  },
  {
    label: "69 cm",
    value: "69",
  },
  {
    label: "70 cm",
    value: "70",
  },
  {
    label: "71 cm",
    value: "71",
  },
  {
    label: "72 cm",
    value: "72",
  },
  {
    label: "73 cm",
    value: "73",
  },
  {
    label: "74 cm",
    value: "74",
  },
  {
    label: "75 cm",
    value: "75",
  },
  {
    label: "76 cm",
    value: "76",
  },
  {
    label: "77 cm",
    value: "77",
  },
  {
    label: "78 cm",
    value: "78",
  },
  {
    label: "79 cm",
    value: "79",
  },
  {
    label: "80 cm",
    value: "80",
  },
  {
    label: "81 cm",
    value: "81",
  },
  {
    label: "82 cm",
    value: "82",
  },
  {
    label: "83 cm",
    value: "83",
  },
  {
    label: "84 cm",
    value: "84",
  },
  {
    label: "85 cm",
    value: "85",
  },
  {
    label: "86 cm",
    value: "86",
  },
  {
    label: "87 cm",
    value: "87",
  },
  {
    label: "88 cm",
    value: "88",
  },
  {
    label: "89 cm",
    value: "89",
  },
  {
    label: "90 cm",
    value: "90",
  },
  {
    label: "91 cm",
    value: "91",
  },
  {
    label: "92 cm",
    value: "92",
  },
  {
    label: "93 cm",
    value: "93",
  },
  {
    label: "94 cm",
    value: "94",
  },
  {
    label: "95 cm",
    value: "95",
  },
  {
    label: "96 cm",
    value: "96",
  },
  {
    label: "97 cm",
    value: "97",
  },
  {
    label: "98 cm",
    value: "98",
  },
  {
    label: "99 cm",
    value: "99",
  },
  {
    label: "100 cm",
    value: "100",
  },
  {
    label: "101 cm",
    value: "101",
  },
  {
    label: "102 cm",
    value: "102",
  },
  {
    label: "103 cm",
    value: "103",
  },
  {
    label: "104 cm",
    value: "104",
  },
  {
    label: "105 cm",
    value: "105",
  },
  {
    label: "106 cm",
    value: "106",
  },
  {
    label: "107 cm",
    value: "107",
  },
  {
    label: "108 cm",
    value: "108",
  },
  {
    label: "109 cm",
    value: "109",
  },
  {
    label: "110 cm",
    value: "110",
  },
  {
    label: "111 cm",
    value: "111",
  },
  {
    label: "112 cm",
    value: "112",
  },
  {
    label: "113 cm",
    value: "113",
  },
  {
    label: "114 cm",
    value: "114",
  },
  {
    label: "115 cm",
    value: "115",
  },
  {
    label: "116 cm",
    value: "116",
  },
  {
    label: "117 cm",
    value: "117",
  },
  {
    label: "118 cm",
    value: "118",
  },
  {
    label: "119 cm",
    value: "119",
  },
  {
    label: "120 cm",
    value: "120",
  },
  {
    label: "121 cm",
    value: "121",
  },
  {
    label: "122 cm",
    value: "122",
  },
  {
    label: "123 cm",
    value: "123",
  },
  {
    label: "124 cm",
    value: "124",
  },
  {
    label: "125 cm",
    value: "125",
  },
  {
    label: "126 cm",
    value: "126",
  },
  {
    label: "127 cm",
    value: "127",
  },
  {
    label: "128 cm",
    value: "128",
  },
  {
    label: "129 cm",
    value: "129",
  },
  {
    label: "130 cm",
    value: "130",
  },
  {
    label: "131 cm",
    value: "131",
  },
  {
    label: "132 cm",
    value: "132",
  },
  {
    label: "133 cm",
    value: "133",
  },
  {
    label: "134 cm",
    value: "134",
  },
  {
    label: "135 cm",
    value: "135",
  },
  {
    label: "136 cm",
    value: "136",
  },
  {
    label: "137 cm",
    value: "137",
  },
  {
    label: "138 cm",
    value: "138",
  },
  {
    label: "139 cm",
    value: "139",
  },
  {
    label: "140 cm",
    value: "140",
  },
  {
    label: "141 cm",
    value: "141",
  },
  {
    label: "142 cm",
    value: "142",
  },
  {
    label: "143 cm",
    value: "143",
  },
  {
    label: "144 cm",
    value: "144",
  },
  {
    label: "145 cm",
    value: "145",
  },
  {
    label: "146 cm",
    value: "146",
  },
  {
    label: "147 cm",
    value: "147",
  },
  {
    label: "148 cm",
    value: "148",
  },
  {
    label: "149 cm",
    value: "149",
  },
  {
    label: "150 cm",
    value: "150",
  },
  {
    label: "151 cm",
    value: "151",
  },
  {
    label: "152 cm",
    value: "152",
  },
  {
    label: "153 cm",
    value: "153",
  },
  {
    label: "154 cm",
    value: "154",
  },
  {
    label: "155 cm",
    value: "155",
  },
  {
    label: "156 cm",
    value: "156",
  },
  {
    label: "157 cm",
    value: "157",
  },
  {
    label: "158 cm",
    value: "158",
  },
  {
    label: "159 cm",
    value: "159",
  },
  {
    label: "160 cm",
    value: "160",
  },
  {
    label: "161 cm",
    value: "161",
  },
  {
    label: "162 cm",
    value: "162",
  },
  {
    label: "163 cm",
    value: "163",
  },
  {
    label: "164 cm",
    value: "164",
  },
  {
    label: "165 cm",
    value: "165",
  },
  {
    label: "166 cm",
    value: "166",
  },
  {
    label: "167 cm",
    value: "167",
  },
  {
    label: "168 cm",
    value: "168",
  },
  {
    label: "169 cm",
    value: "169",
  },
  {
    label: "170 cm",
    value: "170",
  },
  {
    label: "171 cm",
    value: "171",
  },
  {
    label: "172 cm",
    value: "172",
  },
  {
    label: "173 cm",
    value: "173",
  },
  {
    label: "174 cm",
    value: "174",
  },
  {
    label: "175 cm",
    value: "175",
  },
  {
    label: "176 cm",
    value: "176",
  },
  {
    label: "177 cm",
    value: "177",
  },
  {
    label: "178 cm",
    value: "178",
  },
  {
    label: "179 cm",
    value: "179",
  },
  {
    label: "180 cm",
    value: "180",
  },
  {
    label: "181 cm",
    value: "181",
  },
  {
    label: "182 cm",
    value: "182",
  },
  {
    label: "183 cm",
    value: "183",
  },
  {
    label: "184 cm",
    value: "184",
  },
  {
    label: "185 cm",
    value: "185",
  },
  {
    label: "186 cm",
    value: "186",
  },
  {
    label: "187 cm",
    value: "187",
  },
  {
    label: "188 cm",
    value: "188",
  },
  {
    label: "189 cm",
    value: "189",
  },
  {
    label: "190 cm",
    value: "190",
  },
  {
    label: "191 cm",
    value: "191",
  },
  {
    label: "192 cm",
    value: "192",
  },
  {
    label: "193 cm",
    value: "193",
  },
  {
    label: "194 cm",
    value: "194",
  },
  {
    label: "195 cm",
    value: "195",
  },
  {
    label: "196 cm",
    value: "196",
  },
  {
    label: "197 cm",
    value: "197",
  },
  {
    label: "198 cm",
    value: "198",
  },
  {
    label: "199 cm",
    value: "199",
  },
  {
    label: "200 cm",
    value: "200",
  },
];

export const HeightArrFeet = [
  { label: "0 ft", value: "0" },
  { label: "1 ft", value: "1" },
  { label: "2 ft", value: "2" },
  { label: "3 ft", value: "3" },
  { label: "4 ft", value: "4" },
  { label: "5 ft", value: "5" },
  { label: "6 ft", value: "6" },
  { label: "7 ft", value: "7" },
]

export const HeightInchesForFeet = [
  { label: "0 inch", value: "0" },
  { label: "1 inch", value: "1" },
  { label: "2 inch", value: "2" },
  { label: "3 inch", value: "3" },
  { label: "4 inch", value: "4" },
  { label: "5 inch", value: "5" },
  { label: "6 inch", value: "6" },
  { label: "7 inch", value: "7" },
  { label: "8 inch", value: "8" },
  { label: "9 inch", value: "9" },
  { label: "10 inch", value: "10" },
  { label: "11 inch", value: "11" },
  { label: "12 inch", value: "12" },
];

export const HeightArrInches = [
  {
    label: "11 inch",
    value: "11",
  },
  {
    label: "12 inch",
    value: "12",
  },
  {
    label: "13 inch",
    value: "13",
  },
  {
    label: "14 inch",
    value: "14",
  },
  {
    label: "15 inch",
    value: "15",
  },
  {
    label: "16 inch",
    value: "16",
  },
  {
    label: "17 inch",
    value: "17",
  },
  {
    label: "18 inch",
    value: "18",
  },
  {
    label: "19 inch",
    value: "19",
  },
  {
    label: "20 inch",
    value: "20",
  },
  {
    label: "21 inch",
    value: "21",
  },
  {
    label: "22 inch",
    value: "22",
  },
  {
    label: "23 inch",
    value: "23",
  },
  {
    label: "24 inch",
    value: "24",
  },
  {
    label: "25 inch",
    value: "25",
  },
  {
    label: "26 inch",
    value: "26",
  },
  {
    label: "27 inch",
    value: "27",
  },
  {
    label: "28 inch",
    value: "28",
  },
  {
    label: "29 inch",
    value: "29",
  },
  {
    label: "30 inch",
    value: "30",
  },
  {
    label: "31 inch",
    value: "31",
  },
  {
    label: "32 inch",
    value: "32",
  },
  {
    label: "33 inch",
    value: "33",
  },
  {
    label: "34 inch",
    value: "34",
  },
  {
    label: "35 inch",
    value: "35",
  },
  {
    label: "36 inch",
    value: "36",
  },
  {
    label: "37 inch",
    value: "37",
  },
  {
    label: "38 inch",
    value: "38",
  },
  {
    label: "39 inch",
    value: "39",
  },
  {
    label: "40 inch",
    value: "40",
  },
  {
    label: "41 inch",
    value: "41",
  },
  {
    label: "42 inch",
    value: "42",
  },
  {
    label: "43 inch",
    value: "43",
  },
  {
    label: "44 inch",
    value: "44",
  },
  {
    label: "45 inch",
    value: "45",
  },
  {
    label: "46 inch",
    value: "46",
  },
  {
    label: "47 inch",
    value: "47",
  },
  {
    label: "48 inch",
    value: "48",
  },
  {
    label: "49 inch",
    value: "49",
  },
  {
    label: "50 inch",
    value: "50",
  },
  {
    label: "51 inch",
    value: "51",
  },
  {
    label: "52 inch",
    value: "52",
  },
  {
    label: "53 inch",
    value: "53",
  },
  {
    label: "54 inch",
    value: "54",
  },
  {
    label: "55 inch",
    value: "55",
  },
  {
    label: "56 inch",
    value: "56",
  },
  {
    label: "57 inch",
    value: "57",
  },
  {
    label: "58 inch",
    value: "58",
  },
  {
    label: "59 inch",
    value: "59",
  },
  {
    label: "60 inch",
    value: "60",
  },
  {
    label: "61 inch",
    value: "61",
  },
  {
    label: "62 inch",
    value: "62",
  },
  {
    label: "63 inch",
    value: "63",
  },
  {
    label: "64 inch",
    value: "64",
  },
  {
    label: "65 inch",
    value: "65",
  },
  {
    label: "66 inch",
    value: "66",
  },
  {
    label: "67 inch",
    value: "67",
  },
  {
    label: "68 inch",
    value: "68",
  },
  {
    label: "69 inch",
    value: "69",
  },
  {
    label: "70 inch",
    value: "70",
  },
  {
    label: "71 inch",
    value: "71",
  },
  {
    label: "72 inch",
    value: "72",
  },
  {
    label: "73 inch",
    value: "73",
  },
  {
    label: "74 inch",
    value: "74",
  },
  {
    label: "75 inch",
    value: "75",
  },
  {
    label: "76 inch",
    value: "76",
  },
  {
    label: "77 inch",
    value: "77",
  },
  {
    label: "78 inch",
    value: "78",
  },
  {
    label: "79 inch",
    value: "79",
  },
]

export const decimalValues = [
  {
    label: "0",
    value: "0",
  },
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "5",
    value: "5",
  },
  {
    label: "6",
    value: "6",
  },
  {
    label: "7",
    value: "7",
  },
  {
    label: "8",
    value: "8",
  },
  {
    label: "9",
    value: "9",
  },
];

export const longDecimalValues = [
  { value: "00", label: "00" },
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "05", label: "05" },
  { value: "06", label: "06" },
  { value: "07", label: "07" },
  { value: "08", label: "08" },
  { value: "09", label: "09" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
  { value: "51", label: "51" },
  { value: "52", label: "52" },
  { value: "53", label: "53" },
  { value: "54", label: "54" },
  { value: "55", label: "55" },
  { value: "56", label: "56" },
  { value: "57", label: "57" },
  { value: "58", label: "58" },
  { value: "59", label: "59" },
  { value: "60", label: "60" },
  { value: "61", label: "61" },
  { value: "62", label: "62" },
  { value: "63", label: "63" },
  { value: "64", label: "64" },
  { value: "65", label: "65" },
  { value: "66", label: "66" },
  { value: "67", label: "67" },
  { value: "68", label: "68" },
  { value: "69", label: "69" },
  { value: "70", label: "70" },
  { value: "71", label: "71" },
  { value: "72", label: "72" },
  { value: "73", label: "73" },
  { value: "74", label: "74" },
  { value: "75", label: "75" },
  { value: "76", label: "76" },
  { value: "77", label: "77" },
  { value: "78", label: "78" },
  { value: "79", label: "79" },
  { value: "80", label: "80" },
  { value: "81", label: "81" },
  { value: "82", label: "82" },
  { value: "83", label: "83" },
  { value: "84", label: "84" },
  { value: "85", label: "85" },
  { value: "86", label: "86" },
  { value: "87", label: "87" },
  { value: "88", label: "88" },
  { value: "89", label: "89" },
  { value: "90", label: "90" },
  { value: "91", label: "91" },
  { value: "92", label: "92" },
  { value: "93", label: "93" },
  { value: "94", label: "94" },
  { value: "95", label: "95" },
  { value: "96", label: "96" },
  { value: "97", label: "97" },
  { value: "98", label: "98" },
  { value: "99", label: "99" },
];

export const WeightArrKG = [
  {
    label: "30 kg",
    value: "30",
  },
  {
    label: "31 kg",
    value: "31",
  },
  {
    label: "32 kg",
    value: "32",
  },
  {
    label: "33 kg",
    value: "33",
  },
  {
    label: "34 kg",
    value: "34",
  },
  {
    label: "35 kg",
    value: "35",
  },
  {
    label: "36 kg",
    value: "36",
  },
  {
    label: "37 kg",
    value: "37",
  },
  {
    label: "38 kg",
    value: "38",
  },
  {
    label: "39 kg",
    value: "39",
  },
  {
    label: "40 kg",
    value: "40",
  },
  {
    label: "41 kg",
    value: "41",
  },
  {
    label: "42 kg",
    value: "42",
  },
  {
    label: "43 kg",
    value: "43",
  },
  {
    label: "44 kg",
    value: "44",
  },
  {
    label: "45 kg",
    value: "45",
  },
  {
    label: "46 kg",
    value: "46",
  },
  {
    label: "47 kg",
    value: "47",
  },
  {
    label: "48 kg",
    value: "48",
  },
  {
    label: "49 kg",
    value: "49",
  },
  {
    label: "50 kg",
    value: "50",
  },
  {
    label: "51 kg",
    value: "51",
  },
  {
    label: "52 kg",
    value: "52",
  },
  {
    label: "53 kg",
    value: "53",
  },
  {
    label: "54 kg",
    value: "54",
  },
  {
    label: "55 kg",
    value: "55",
  },
  {
    label: "56 kg",
    value: "56",
  },
  {
    label: "57 kg",
    value: "57",
  },
  {
    label: "58 kg",
    value: "58",
  },
  {
    label: "59 kg",
    value: "59",
  },
  {
    label: "60 kg",
    value: "60",
  },
  {
    label: "61 kg",
    value: "61",
  },
  {
    label: "62 kg",
    value: "62",
  },
  {
    label: "63 kg",
    value: "63",
  },
  {
    label: "64 kg",
    value: "64",
  },
  {
    label: "65 kg",
    value: "65",
  },
  {
    label: "66 kg",
    value: "66",
  },
  {
    label: "67 kg",
    value: "67",
  },
  {
    label: "68 kg",
    value: "68",
  },
  {
    label: "69 kg",
    value: "69",
  },
  {
    label: "70 kg",
    value: "70",
  },
  {
    label: "71 kg",
    value: "71",
  },
  {
    label: "72 kg",
    value: "72",
  },
  {
    label: "73 kg",
    value: "73",
  },
  {
    label: "74 kg",
    value: "74",
  },
  {
    label: "75 kg",
    value: "75",
  },
  {
    label: "76 kg",
    value: "76",
  },
  {
    label: "77 kg",
    value: "77",
  },
  {
    label: "78 kg",
    value: "78",
  },
  {
    label: "79 kg",
    value: "79",
  },
  {
    label: "80 kg",
    value: "80",
  },
  {
    label: "81 kg",
    value: "81",
  },
  {
    label: "82 kg",
    value: "82",
  },
  {
    label: "83 kg",
    value: "83",
  },
  {
    label: "84 kg",
    value: "84",
  },
  {
    label: "85 kg",
    value: "85",
  },
  {
    label: "86 kg",
    value: "86",
  },
  {
    label: "87 kg",
    value: "87",
  },
  {
    label: "88 kg",
    value: "88",
  },
  {
    label: "89 kg",
    value: "89",
  },
  {
    label: "90 kg",
    value: "90",
  },
  {
    label: "91 kg",
    value: "91",
  },
  {
    label: "92 kg",
    value: "92",
  },
  {
    label: "93 kg",
    value: "93",
  },
  {
    label: "94 kg",
    value: "94",
  },
  {
    label: "95 kg",
    value: "95",
  },
  {
    label: "96 kg",
    value: "96",
  },
  {
    label: "97 kg",
    value: "97",
  },
  {
    label: "98 kg",
    value: "98",
  },
  {
    label: "99 kg",
    value: "99",
  },
  {
    label: "100 kg",
    value: "100",
  },
  {
    label: "101 kg",
    value: "101",
  },
  {
    label: "102 kg",
    value: "102",
  },
  {
    label: "103 kg",
    value: "103",
  },
  {
    label: "104 kg",
    value: "104",
  },
  {
    label: "105 kg",
    value: "105",
  },
  {
    label: "106 kg",
    value: "106",
  },
  {
    label: "107 kg",
    value: "107",
  },
  {
    label: "108 kg",
    value: "108",
  },
  {
    label: "109 kg",
    value: "109",
  },
  {
    label: "110 kg",
    value: "110",
  },
  {
    label: "111 kg",
    value: "111",
  },
  {
    label: "112 kg",
    value: "112",
  },
  {
    label: "113 kg",
    value: "113",
  },
  {
    label: "114 kg",
    value: "114",
  },
  {
    label: "115 kg",
    value: "115",
  },
  {
    label: "116 kg",
    value: "116",
  },
  {
    label: "117 kg",
    value: "117",
  },
  {
    label: "118 kg",
    value: "118",
  },
  {
    label: "119 kg",
    value: "119",
  },
  {
    label: "120 kg",
    value: "120",
  },
  {
    label: "121 kg",
    value: "121",
  },
  {
    label: "122 kg",
    value: "122",
  },
  {
    label: "123 kg",
    value: "123",
  },
  {
    label: "124 kg",
    value: "124",
  },
  {
    label: "125 kg",
    value: "125",
  },
  {
    label: "126 kg",
    value: "126",
  },
  {
    label: "127 kg",
    value: "127",
  },
  {
    label: "128 kg",
    value: "128",
  },
  {
    label: "129 kg",
    value: "129",
  },
  {
    label: "130 kg",
    value: "130",
  },
  {
    label: "131 kg",
    value: "131",
  },
  {
    label: "132 kg",
    value: "132",
  },
  {
    label: "133 kg",
    value: "133",
  },
  {
    label: "134 kg",
    value: "134",
  },
  {
    label: "135 kg",
    value: "135",
  },
  {
    label: "136 kg",
    value: "136",
  },
  {
    label: "137 kg",
    value: "137",
  },
  {
    label: "138 kg",
    value: "138",
  },
  {
    label: "139 kg",
    value: "139",
  },
  {
    label: "140 kg",
    value: "140",
  },
  {
    label: "141 kg",
    value: "141",
  },
  {
    label: "142 kg",
    value: "142",
  },
  {
    label: "143 kg",
    value: "143",
  },
  {
    label: "144 kg",
    value: "144",
  },
  {
    label: "145 kg",
    value: "145",
  },
  {
    label: "146 kg",
    value: "146",
  },
  {
    label: "147 kg",
    value: "147",
  },
  {
    label: "148 kg",
    value: "148",
  },
  {
    label: "149 kg",
    value: "149",
  },
  {
    label: "150 kg",
    value: "150",
  },
  {
    label: "151 kg",
    value: "151",
  },
  {
    label: "152 kg",
    value: "152",
  },
  {
    label: "153 kg",
    value: "153",
  },
  {
    label: "154 kg",
    value: "154",
  },
  {
    label: "155 kg",
    value: "155",
  },
  {
    label: "156 kg",
    value: "156",
  },
  {
    label: "157 kg",
    value: "157",
  },
  {
    label: "158 kg",
    value: "158",
  },
  {
    label: "159 kg",
    value: "159",
  },
  {
    label: "160 kg",
    value: "160",
  },
  {
    label: "161 kg",
    value: "161",
  },
  {
    label: "162 kg",
    value: "162",
  },
  {
    label: "163 kg",
    value: "163",
  },
  {
    label: "164 kg",
    value: "164",
  },
  {
    label: "165 kg",
    value: "165",
  },
  {
    label: "166 kg",
    value: "166",
  },
  {
    label: "167 kg",
    value: "167",
  },
  {
    label: "168 kg",
    value: "168",
  },
  {
    label: "169 kg",
    value: "169",
  },
  {
    label: "170 kg",
    value: "170",
  },
  {
    label: "171 kg",
    value: "171",
  },
  {
    label: "172 kg",
    value: "172",
  },
  {
    label: "173 kg",
    value: "173",
  },
  {
    label: "174 kg",
    value: "174",
  },
  {
    label: "175 kg",
    value: "175",
  },
  {
    label: "176 kg",
    value: "176",
  },
  {
    label: "177 kg",
    value: "177",
  },
  {
    label: "178 kg",
    value: "178",
  },
  {
    label: "179 kg",
    value: "179",
  },
  {
    label: "180 kg",
    value: "180",
  },
  {
    label: "181 kg",
    value: "181",
  },
  {
    label: "182 kg",
    value: "182",
  },
  {
    label: "183 kg",
    value: "183",
  },
  {
    label: "184 kg",
    value: "184",
  },
  {
    label: "185 kg",
    value: "185",
  },
  {
    label: "186 kg",
    value: "186",
  },
  {
    label: "187 kg",
    value: "187",
  },
  {
    label: "188 kg",
    value: "188",
  },
  {
    label: "189 kg",
    value: "189",
  },
  {
    label: "190 kg",
    value: "190",
  },
  {
    label: "191 kg",
    value: "191",
  },
  {
    label: "192 kg",
    value: "192",
  },
  {
    label: "193 kg",
    value: "193",
  },
  {
    label: "194 kg",
    value: "194",
  },
  {
    label: "195 kg",
    value: "195",
  },
  {
    label: "196 kg",
    value: "196",
  },
  {
    label: "197 kg",
    value: "197",
  },
  {
    label: "198 kg",
    value: "198",
  },
  {
    label: "199 kg",
    value: "199",
  },
  {
    label: "200 kg",
    value: "200",
  },
];

export const WeightArrPound = [
  {
    label: "65 lb",
    value: "65",
  },
  {
    label: "66 lb",
    value: "66",
  },
  {
    label: "67 lb",
    value: "67",
  },
  {
    label: "68 lb",
    value: "68",
  },
  {
    label: "69 lb",
    value: "69",
  },
  {
    label: "70 lb",
    value: "70",
  },
  {
    label: "71 lb",
    value: "71",
  },
  {
    label: "72 lb",
    value: "72",
  },
  {
    label: "73 lb",
    value: "73",
  },
  {
    label: "74 lb",
    value: "74",
  },
  {
    label: "75 lb",
    value: "75",
  },
  {
    label: "76 lb",
    value: "76",
  },
  {
    label: "77 lb",
    value: "77",
  },
  {
    label: "78 lb",
    value: "78",
  },
  {
    label: "79 lb",
    value: "79",
  },
  {
    label: "80 lb",
    value: "80",
  },
  {
    label: "81 lb",
    value: "81",
  },
  {
    label: "82 lb",
    value: "82",
  },
  {
    label: "83 lb",
    value: "83",
  },
  {
    label: "84 lb",
    value: "84",
  },
  {
    label: "85 lb",
    value: "85",
  },
  {
    label: "86 lb",
    value: "86",
  },
  {
    label: "87 lb",
    value: "87",
  },
  {
    label: "88 lb",
    value: "88",
  },
  {
    label: "89 lb",
    value: "89",
  },
  {
    label: "90 lb",
    value: "90",
  },
  {
    label: "91 lb",
    value: "91",
  },
  {
    label: "92 lb",
    value: "92",
  },
  {
    label: "93 lb",
    value: "93",
  },
  {
    label: "94 lb",
    value: "94",
  },
  {
    label: "95 lb",
    value: "95",
  },
  {
    label: "96 lb",
    value: "96",
  },
  {
    label: "97 lb",
    value: "97",
  },
  {
    label: "98 lb",
    value: "98",
  },
  {
    label: "99 lb",
    value: "99",
  },
  {
    label: "100 lb",
    value: "100",
  },
  {
    label: "101 lb",
    value: "101",
  },
  {
    label: "102 lb",
    value: "102",
  },
  {
    label: "103 lb",
    value: "103",
  },
  {
    label: "104 lb",
    value: "104",
  },
  {
    label: "105 lb",
    value: "105",
  },
  {
    label: "106 lb",
    value: "106",
  },
  {
    label: "107 lb",
    value: "107",
  },
  {
    label: "108 lb",
    value: "108",
  },
  {
    label: "109 lb",
    value: "109",
  },
  {
    label: "110 lb",
    value: "110",
  },
  {
    label: "111 lb",
    value: "111",
  },
  {
    label: "112 lb",
    value: "112",
  },
  {
    label: "113 lb",
    value: "113",
  },
  {
    label: "114 lb",
    value: "114",
  },
  {
    label: "115 lb",
    value: "115",
  },
  {
    label: "116 lb",
    value: "116",
  },
  {
    label: "117 lb",
    value: "117",
  },
  {
    label: "118 lb",
    value: "118",
  },
  {
    label: "119 lb",
    value: "119",
  },
  {
    label: "120 lb",
    value: "120",
  },
  {
    label: "121 lb",
    value: "121",
  },
  {
    label: "122 lb",
    value: "122",
  },
  {
    label: "123 lb",
    value: "123",
  },
  {
    label: "124 lb",
    value: "124",
  },
  {
    label: "125 lb",
    value: "125",
  },
  {
    label: "126 lb",
    value: "126",
  },
  {
    label: "127 lb",
    value: "127",
  },
  {
    label: "128 lb",
    value: "128",
  },
  {
    label: "129 lb",
    value: "129",
  },
  {
    label: "130 lb",
    value: "130",
  },
  {
    label: "131 lb",
    value: "131",
  },
  {
    label: "132 lb",
    value: "132",
  },
  {
    label: "133 lb",
    value: "133",
  },
  {
    label: "134 lb",
    value: "134",
  },
  {
    label: "135 lb",
    value: "135",
  },
  {
    label: "136 lb",
    value: "136",
  },
  {
    label: "137 lb",
    value: "137",
  },
  {
    label: "138 lb",
    value: "138",
  },
  {
    label: "139 lb",
    value: "139",
  },
  {
    label: "140 lb",
    value: "140",
  },
  {
    label: "141 lb",
    value: "141",
  },
  {
    label: "142 lb",
    value: "142",
  },
  {
    label: "143 lb",
    value: "143",
  },
  {
    label: "144 lb",
    value: "144",
  },
  {
    label: "145 lb",
    value: "145",
  },
  {
    label: "146 lb",
    value: "146",
  },
  {
    label: "147 lb",
    value: "147",
  },
  {
    label: "148 lb",
    value: "148",
  },
  {
    label: "149 lb",
    value: "149",
  },
  {
    label: "150 lb",
    value: "150",
  },
  {
    label: "151 lb",
    value: "151",
  },
  {
    label: "152 lb",
    value: "152",
  },
  {
    label: "153 lb",
    value: "153",
  },
  {
    label: "154 lb",
    value: "154",
  },
  {
    label: "155 lb",
    value: "155",
  },
  {
    label: "156 lb",
    value: "156",
  },
  {
    label: "157 lb",
    value: "157",
  },
  {
    label: "158 lb",
    value: "158",
  },
  {
    label: "159 lb",
    value: "159",
  },
  {
    label: "160 lb",
    value: "160",
  },
  {
    label: "161 lb",
    value: "161",
  },
  {
    label: "162 lb",
    value: "162",
  },
  {
    label: "163 lb",
    value: "163",
  },
  {
    label: "164 lb",
    value: "164",
  },
  {
    label: "165 lb",
    value: "165",
  },
  {
    label: "166 lb",
    value: "166",
  },
  {
    label: "167 lb",
    value: "167",
  },
  {
    label: "168 lb",
    value: "168",
  },
  {
    label: "169 lb",
    value: "169",
  },
  {
    label: "170 lb",
    value: "170",
  },
  {
    label: "171 lb",
    value: "171",
  },
  {
    label: "172 lb",
    value: "172",
  },
  {
    label: "173 lb",
    value: "173",
  },
  {
    label: "174 lb",
    value: "174",
  },
  {
    label: "175 lb",
    value: "175",
  },
  {
    label: "176 lb",
    value: "176",
  },
  {
    label: "177 lb",
    value: "177",
  },
  {
    label: "178 lb",
    value: "178",
  },
  {
    label: "179 lb",
    value: "179",
  },
  {
    label: "180 lb",
    value: "180",
  },
  {
    label: "181 lb",
    value: "181",
  },
  {
    label: "182 lb",
    value: "182",
  },
  {
    label: "183 lb",
    value: "183",
  },
  {
    label: "184 lb",
    value: "184",
  },
  {
    label: "185 lb",
    value: "185",
  },
  {
    label: "186 lb",
    value: "186",
  },
  {
    label: "187 lb",
    value: "187",
  },
  {
    label: "188 lb",
    value: "188",
  },
  {
    label: "189 lb",
    value: "189",
  },
  {
    label: "190 lb",
    value: "190",
  },
  {
    label: "191 lb",
    value: "191",
  },
  {
    label: "192 lb",
    value: "192",
  },
  {
    label: "193 lb",
    value: "193",
  },
  {
    label: "194 lb",
    value: "194",
  },
  {
    label: "195 lb",
    value: "195",
  },
  {
    label: "196 lb",
    value: "196",
  },
  {
    label: "197 lb",
    value: "197",
  },
  {
    label: "198 lb",
    value: "198",
  },
  {
    label: "199 lb",
    value: "199",
  },
  {
    label: "200 lb",
    value: "200",
  },
  {
    label: "201 lb",
    value: "201",
  },
  {
    label: "202 lb",
    value: "202",
  },
  {
    label: "203 lb",
    value: "203",
  },
  {
    label: "204 lb",
    value: "204",
  },
  {
    label: "205 lb",
    value: "205",
  },
  {
    label: "206 lb",
    value: "206",
  },
  {
    label: "207 lb",
    value: "207",
  },
  {
    label: "208 lb",
    value: "208",
  },
  {
    label: "209 lb",
    value: "209",
  },
  {
    label: "210 lb",
    value: "210",
  },
  {
    label: "211 lb",
    value: "211",
  },
  {
    label: "212 lb",
    value: "212",
  },
  {
    label: "213 lb",
    value: "213",
  },
  {
    label: "214 lb",
    value: "214",
  },
  {
    label: "215 lb",
    value: "215",
  },
  {
    label: "216 lb",
    value: "216",
  },
  {
    label: "217 lb",
    value: "217",
  },
  {
    label: "218 lb",
    value: "218",
  },
  {
    label: "219 lb",
    value: "219",
  },
  {
    label: "220 lb",
    value: "220",
  },
  {
    label: "221 lb",
    value: "221",
  },
  {
    label: "222 lb",
    value: "222",
  },
  {
    label: "223 lb",
    value: "223",
  },
  {
    label: "224 lb",
    value: "224",
  },
  {
    label: "225 lb",
    value: "225",
  },
  {
    label: "226 lb",
    value: "226",
  },
  {
    label: "227 lb",
    value: "227",
  },
  {
    label: "228 lb",
    value: "228",
  },
  {
    label: "229 lb",
    value: "229",
  },
  {
    label: "230 lb",
    value: "230",
  },
  {
    label: "231 lb",
    value: "231",
  },
  {
    label: "232 lb",
    value: "232",
  },
  {
    label: "233 lb",
    value: "233",
  },
  {
    label: "234 lb",
    value: "234",
  },
  {
    label: "235 lb",
    value: "235",
  },
  {
    label: "236 lb",
    value: "236",
  },
  {
    label: "237 lb",
    value: "237",
  },
  {
    label: "238 lb",
    value: "238",
  },
  {
    label: "239 lb",
    value: "239",
  },
  {
    label: "240 lb",
    value: "240",
  },
  {
    label: "241 lb",
    value: "241",
  },
  {
    label: "242 lb",
    value: "242",
  },
  {
    label: "243 lb",
    value: "243",
  },
  {
    label: "244 lb",
    value: "244",
  },
  {
    label: "245 lb",
    value: "245",
  },
  {
    label: "246 lb",
    value: "246",
  },
  {
    label: "247 lb",
    value: "247",
  },
  {
    label: "248 lb",
    value: "248",
  },
  {
    label: "249 lb",
    value: "249",
  },
  {
    label: "250 lb",
    value: "250",
  },
  {
    label: "251 lb",
    value: "251",
  },
  {
    label: "252 lb",
    value: "252",
  },
  {
    label: "253 lb",
    value: "253",
  },
  {
    label: "254 lb",
    value: "254",
  },
  {
    label: "255 lb",
    value: "255",
  },
  {
    label: "256 lb",
    value: "256",
  },
  {
    label: "257 lb",
    value: "257",
  },
  {
    label: "258 lb",
    value: "258",
  },
  {
    label: "259 lb",
    value: "259",
  },
  {
    label: "260 lb",
    value: "260",
  },
  {
    label: "261 lb",
    value: "261",
  },
  {
    label: "262 lb",
    value: "262",
  },
  {
    label: "263 lb",
    value: "263",
  },
  {
    label: "264 lb",
    value: "264",
  },
  {
    label: "265 lb",
    value: "265",
  },
  {
    label: "266 lb",
    value: "266",
  },
  {
    label: "267 lb",
    value: "267",
  },
  {
    label: "268 lb",
    value: "268",
  },
  {
    label: "269 lb",
    value: "269",
  },
  {
    label: "270 lb",
    value: "270",
  },
  {
    label: "271 lb",
    value: "271",
  },
  {
    label: "272 lb",
    value: "272",
  },
  {
    label: "273 lb",
    value: "273",
  },
  {
    label: "274 lb",
    value: "274",
  },
  {
    label: "275 lb",
    value: "275",
  },
  {
    label: "276 lb",
    value: "276",
  },
  {
    label: "277 lb",
    value: "277",
  },
  {
    label: "278 lb",
    value: "278",
  },
  {
    label: "279 lb",
    value: "279",
  },
  {
    label: "280 lb",
    value: "280",
  },
  {
    label: "281 lb",
    value: "281",
  },
  {
    label: "282 lb",
    value: "282",
  },
  {
    label: "283 lb",
    value: "283",
  },
  {
    label: "284 lb",
    value: "284",
  },
  {
    label: "285 lb",
    value: "285",
  },
  {
    label: "286 lb",
    value: "286",
  },
  {
    label: "287 lb",
    value: "287",
  },
  {
    label: "288 lb",
    value: "288",
  },
  {
    label: "289 lb",
    value: "289",
  },
  {
    label: "290 lb",
    value: "290",
  },
  {
    label: "291 lb",
    value: "291",
  },
  {
    label: "292 lb",
    value: "292",
  },
  {
    label: "293 lb",
    value: "293",
  },
  {
    label: "294 lb",
    value: "294",
  },
  {
    label: "295 lb",
    value: "295",
  },
  {
    label: "296 lb",
    value: "296",
  },
  {
    label: "297 lb",
    value: "297",
  },
  {
    label: "298 lb",
    value: "298",
  },
  {
    label: "299 lb",
    value: "299",
  },
  {
    label: "300 lb",
    value: "300",
  },
  {
    label: "301 lb",
    value: "301",
  },
  {
    label: "302 lb",
    value: "302",
  },
  {
    label: "303 lb",
    value: "303",
  },
  {
    label: "304 lb",
    value: "304",
  },
  {
    label: "305 lb",
    value: "305",
  },
  {
    label: "306 lb",
    value: "306",
  },
  {
    label: "307 lb",
    value: "307",
  },
  {
    label: "308 lb",
    value: "308",
  },
  {
    label: "309 lb",
    value: "309",
  },
  {
    label: "310 lb",
    value: "310",
  },
  {
    label: "311 lb",
    value: "311",
  },
  {
    label: "312 lb",
    value: "312",
  },
  {
    label: "313 lb",
    value: "313",
  },
  {
    label: "314 lb",
    value: "314",
  },
  {
    label: "315 lb",
    value: "315",
  },
  {
    label: "316 lb",
    value: "316",
  },
  {
    label: "317 lb",
    value: "317",
  },
  {
    label: "318 lb",
    value: "318",
  },
  {
    label: "319 lb",
    value: "319",
  },
  {
    label: "320 lb",
    value: "320",
  },
  {
    label: "321 lb",
    value: "321",
  },
  {
    label: "322 lb",
    value: "322",
  },
  {
    label: "323 lb",
    value: "323",
  },
  {
    label: "324 lb",
    value: "324",
  },
  {
    label: "325 lb",
    value: "325",
  },
  {
    label: "326 lb",
    value: "326",
  },
  {
    label: "327 lb",
    value: "327",
  },
  {
    label: "328 lb",
    value: "328",
  },
  {
    label: "329 lb",
    value: "329",
  },
  {
    label: "330 lb",
    value: "330",
  },
  {
    label: "331 lb",
    value: "331",
  },
  {
    label: "332 lb",
    value: "332",
  },
  {
    label: "333 lb",
    value: "333",
  },
  {
    label: "334 lb",
    value: "334",
  },
  {
    label: "335 lb",
    value: "335",
  },
  {
    label: "336 lb",
    value: "336",
  },
  {
    label: "337 lb",
    value: "337",
  },
  {
    label: "338 lb",
    value: "338",
  },
  {
    label: "339 lb",
    value: "339",
  },
  {
    label: "340 lb",
    value: "340",
  },
  {
    label: "341 lb",
    value: "341",
  },
  {
    label: "342 lb",
    value: "342",
  },
  {
    label: "343 lb",
    value: "343",
  },
  {
    label: "344 lb",
    value: "344",
  },
  {
    label: "345 lb",
    value: "345",
  },
  {
    label: "346 lb",
    value: "346",
  },
  {
    label: "347 lb",
    value: "347",
  },
  {
    label: "348 lb",
    value: "348",
  },
  {
    label: "349 lb",
    value: "349",
  },
  {
    label: "350 lb",
    value: "350",
  },
  {
    label: "351 lb",
    value: "351",
  },
  {
    label: "352 lb",
    value: "352",
  },
  {
    label: "353 lb",
    value: "353",
  },
  {
    label: "354 lb",
    value: "354",
  },
  {
    label: "355 lb",
    value: "355",
  },
  {
    label: "356 lb",
    value: "356",
  },
  {
    label: "357 lb",
    value: "357",
  },
  {
    label: "358 lb",
    value: "358",
  },
  {
    label: "359 lb",
    value: "359",
  },
  {
    label: "360 lb",
    value: "360",
  },
  {
    label: "361 lb",
    value: "361",
  },
  {
    label: "362 lb",
    value: "362",
  },
  {
    label: "363 lb",
    value: "363",
  },
  {
    label: "364 lb",
    value: "364",
  },
  {
    label: "365 lb",
    value: "365",
  },
  {
    label: "366 lb",
    value: "366",
  },
  {
    label: "367 lb",
    value: "367",
  },
  {
    label: "368 lb",
    value: "368",
  },
  {
    label: "369 lb",
    value: "369",
  },
  {
    label: "370 lb",
    value: "370",
  },
  {
    label: "371 lb",
    value: "371",
  },
  {
    label: "372 lb",
    value: "372",
  },
  {
    label: "373 lb",
    value: "373",
  },
  {
    label: "374 lb",
    value: "374",
  },
  {
    label: "375 lb",
    value: "375",
  },
  {
    label: "376 lb",
    value: "376",
  },
  {
    label: "377 lb",
    value: "377",
  },
  {
    label: "378 lb",
    value: "378",
  },
  {
    label: "379 lb",
    value: "379",
  },
  {
    label: "380 lb",
    value: "380",
  },
  {
    label: "381 lb",
    value: "381",
  },
  {
    label: "382 lb",
    value: "382",
  },
  {
    label: "383 lb",
    value: "383",
  },
  {
    label: "384 lb",
    value: "384",
  },
  {
    label: "385 lb",
    value: "385",
  },
  {
    label: "386 lb",
    value: "386",
  },
  {
    label: "387 lb",
    value: "387",
  },
  {
    label: "388 lb",
    value: "388",
  },
  {
    label: "389 lb",
    value: "389",
  },
  {
    label: "390 lb",
    value: "390",
  },
  {
    label: "391 lb",
    value: "391",
  },
  {
    label: "392 lb",
    value: "392",
  },
  {
    label: "393 lb",
    value: "393",
  },
  {
    label: "394 lb",
    value: "394",
  },
  {
    label: "395 lb",
    value: "395",
  },
  {
    label: "396 lb",
    value: "396",
  },
  {
    label: "397 lb",
    value: "397",
  },
  {
    label: "398 lb",
    value: "398",
  },
  {
    label: "399 lb",
    value: "399",
  },
  {
    label: "400 lb",
    value: "400",
  },
  {
    label: "401 lb",
    value: "401",
  },
  {
    label: "402 lb",
    value: "402",
  },
  {
    label: "403 lb",
    value: "403",
  },
  {
    label: "404 lb",
    value: "404",
  },
  {
    label: "405 lb",
    value: "405",
  },
  {
    label: "406 lb",
    value: "406",
  },
  {
    label: "407 lb",
    value: "407",
  },
  {
    label: "408 lb",
    value: "408",
  },
  {
    label: "409 lb",
    value: "409",
  },
  {
    label: "410 lb",
    value: "410",
  },
  {
    label: "411 lb",
    value: "411",
  },
  {
    label: "412 lb",
    value: "412",
  },
  {
    label: "413 lb",
    value: "413",
  },
  {
    label: "414 lb",
    value: "414",
  },
  {
    label: "415 lb",
    value: "415",
  },
  {
    label: "416 lb",
    value: "416",
  },
  {
    label: "417 lb",
    value: "417",
  },
  {
    label: "418 lb",
    value: "418",
  },
  {
    label: "419 lb",
    value: "419",
  },
  {
    label: "420 lb",
    value: "420",
  },
  {
    label: "421 lb",
    value: "421",
  },
  {
    label: "422 lb",
    value: "422",
  },
  {
    label: "423 lb",
    value: "423",
  },
  {
    label: "424 lb",
    value: "424",
  },
  {
    label: "425 lb",
    value: "425",
  },
  {
    label: "426 lb",
    value: "426",
  },
  {
    label: "427 lb",
    value: "427",
  },
  {
    label: "428 lb",
    value: "428",
  },
  {
    label: "429 lb",
    value: "429",
  },
  {
    label: "430 lb",
    value: "430",
  },
  {
    label: "431 lb",
    value: "431",
  },
  {
    label: "432 lb",
    value: "432",
  },
  {
    label: "433 lb",
    value: "433",
  },
  {
    label: "434 lb",
    value: "434",
  },
  {
    label: "435 lb",
    value: "435",
  },
  {
    label: "436 lb",
    value: "436",
  },
  {
    label: "437 lb",
    value: "437",
  },
  {
    label: "438 lb",
    value: "438",
  },
  {
    label: "439 lb",
    value: "439",
  },
  {
    label: "440 lb",
    value: "440",
  },
];

export const BloodGrouping = [
  {
    label: "A+",
    value: "aplus",
    image: "aplus.png",
  },
  {
    label: "A-",
    value: "aminus",
    image: "aminus.png",
  },
  {
    label: "B+",
    value: "bplus",
    image: "bplus.png",
  },
  {
    label: "B-",
    value: "bminus",
    image: "bminus.png",
  },
  {
    label: "O+",
    value: "oplus",
    image: "oplus.png",
  },
  {
    label: "O-",
    value: "ominus",
    image: "ominus.png",
  },
  {
    label: "AB+",
    value: "abplus",
    image: "abplus.png",
  },
  {
    label: "AB-",
    value: "abminus",
    image: "abminus.png",
  },
];

export const getBloodGroup = (blood_group) =>
  BloodGrouping.filter((b) => b.label == blood_group);

export const AppointmentDuration = [
  { label: "15 Min", value: 15 },
  { label: "30 Min", value: 30 },
  { label: "45 Min", value: 45 },
  { label: "60 Min", value: 60 },
];

export const TimingWithoutAmPm = [
  { label: "12:00", value: "12:00", disabled: false },
  { label: "12:15", value: "12:15", disabled: false },
  { label: "12:30", value: "12:30", disabled: false },
  { label: "12:45", value: "12:45", disabled: false },
  { label: "1:00", value: "1:00", disabled: false },
  { label: "1:15", value: "1:15", disabled: false },
  { label: "1:30", value: "1:30", disabled: false },
  { label: "1:45", value: "1:45", disabled: false },
  { label: "2:00", value: "2:00", disabled: false },
  { label: "2:15", value: "2:15", disabled: false },
  { label: "2:30", value: "2:30", disabled: false },
  { label: "2:45", value: "2:45", disabled: false },
  { label: "3:00", value: "3:00", disabled: false },
  { label: "3:15", value: "3:15", disabled: false },
  { label: "3:30", value: "3:30", disabled: false },
  { label: "3:45", value: "3:45", disabled: false },
  { label: "4:00", value: "4:00", disabled: false },
  { label: "4:15", value: "4:15", disabled: false },
  { label: "4:30", value: "4:30", disabled: false },
  { label: "4:45", value: "4:45", disabled: false },
  { label: "5:00", value: "5:00", disabled: false },
  { label: "5:15", value: "5:15", disabled: false },
  { label: "5:30", value: "5:30", disabled: false },
  { label: "5:45", value: "5:45", disabled: false },
  { label: "6:00", value: "6:00", disabled: false },
  { label: "6:15", value: "6:15", disabled: false },
  { label: "6:30", value: "6:30", disabled: false },
  { label: "6:45", value: "6:45", disabled: false },
  { label: "7:00", value: "7:00", disabled: false },
  { label: "7:15", value: "7:15", disabled: false },
  { label: "7:30", value: "7:30", disabled: false },
  { label: "7:45", value: "7:45", disabled: false },
  { label: "8:00", value: "8:00", disabled: false },
  { label: "8:15", value: "8:15", disabled: false },
  { label: "8:30", value: "8:30", disabled: false },
  { label: "8:45", value: "8:45", disabled: false },
  { label: "9:00", value: "9:00", disabled: false },
  { label: "9:15", value: "9:15", disabled: false },
  { label: "9:30", value: "9:30", disabled: false },
  { label: "9:45", value: "9:45", disabled: false },
  { label: "10:00", value: "10:00", disabled: false },
  { label: "10:15", value: "10:15", disabled: false },
  { label: "10:30", value: "10:30", disabled: false },
  { label: "10:45", value: "10:45", disabled: false },
  { label: "11:00", value: "11:00", disabled: false },
  { label: "11:15", value: "11:15", disabled: false },
  { label: "11:30", value: "11:30", disabled: false },
  { label: "11:45", value: "11:45", disabled: false },
];

export const Timing = [
  { label: "12:00 AM", value: "00:00" },
  { label: "12:15 AM", value: "00:15" },
  { label: "12:30 AM", value: "00:30" },
  { label: "12:45 AM", value: "00:45" },
  { label: "1:00 AM", value: "1:00" },
  { label: "1:15 AM", value: "1:15" },
  { label: "1:30 AM", value: "1:30" },
  { label: "1:45 AM", value: "1:45" },
  { label: "2:00 AM", value: "2:00" },
  { label: "2:15 AM", value: "2:15" },
  { label: "2:30 AM", value: "2:30" },
  { label: "2:45 AM", value: "2:45" },
  { label: "3:00 AM", value: "3:00" },
  { label: "3:15 AM", value: "3:15" },
  { label: "3:30 AM", value: "3:30" },
  { label: "3:45 AM", value: "3:45" },
  { label: "4:00 AM", value: "4:00" },
  { label: "4:15 AM", value: "4:15" },
  { label: "4:30 AM", value: "4:30" },
  { label: "4:45 AM", value: "4:45" },
  { label: "5:00 AM", value: "5:00" },
  { label: "5:15 AM", value: "5:15" },
  { label: "5:30 AM", value: "5:30" },
  { label: "5:45 AM", value: "5:45" },
  { label: "6:00 AM", value: "6:00" },
  { label: "6:15 AM", value: "6:15" },
  { label: "6:30 AM", value: "6:30" },
  { label: "6:45 AM", value: "6:45" },
  { label: "7:00 AM", value: "7:00" },
  { label: "7:15 AM", value: "7:15" },
  { label: "7:30 AM", value: "7:30" },
  { label: "7:45 AM", value: "7:45" },
  { label: "8:00 AM", value: "8:00" },
  { label: "8:15 AM", value: "8:15" },
  { label: "8:30 AM", value: "8:30" },
  { label: "8:45 AM", value: "8:45" },
  { label: "9:00 AM", value: "9:00" },
  { label: "9:15 AM", value: "9:15" },
  { label: "9:30 AM", value: "9:30" },
  { label: "9:45 AM", value: "9:45" },
  { label: "10:00 AM", value: "10:00" },
  { label: "10:15 AM", value: "10:15" },
  { label: "10:30 AM", value: "10:30" },
  { label: "10:45 AM", value: "10:45" },
  { label: "11:00 AM", value: "11:00" },
  { label: "11:15 AM", value: "11:15" },
  { label: "11:30 AM", value: "11:30" },
  { label: "11:45 AM", value: "11:45" },
  { label: "12:00 PM", value: "12:00" },
  { label: "12:15 PM", value: "12:15" },
  { label: "12:30 PM", value: "12:30" },
  { label: "12:45 PM", value: "12:45" },
  { label: "1:00 PM", value: "13:00" },
  { label: "1:15 PM", value: "13:15" },
  { label: "1:30 PM", value: "13:30" },
  { label: "1:45 PM", value: "13:45" },
  { label: "2:00 PM", value: "14:00" },
  { label: "2:15 PM", value: "14:15" },
  { label: "2:30 PM", value: "14:30" },
  { label: "2:45 PM", value: "14:45" },
  { label: "3:00 PM", value: "15:00" },
  { label: "3:15 PM", value: "15:15" },
  { label: "3:30 PM", value: "15:30" },
  { label: "3:45 PM", value: "15:45" },
  { label: "4:00 PM", value: "16:00" },
  { label: "4:15 PM", value: "16:15" },
  { label: "4:30 PM", value: "16:30" },
  { label: "4:45 PM", value: "16:45" },
  { label: "5:00 PM", value: "17:00" },
  { label: "5:15 PM", value: "17:15" },
  { label: "5:30 PM", value: "17:30" },
  { label: "5:45 PM", value: "17:45" },
  { label: "6:00 PM", value: "18:00" },
  { label: "6:15 PM", value: "18:15" },
  { label: "6:30 PM", value: "18:30" },
  { label: "6:45 PM", value: "18:45" },
  { label: "7:00 PM", value: "19:00" },
  { label: "7:15 PM", value: "19:15" },
  { label: "7:30 PM", value: "19:30" },
  { label: "7:45 PM", value: "19:45" },
  { label: "8:00 PM", value: "20:00" },
  { label: "8:15 PM", value: "20:15" },
  { label: "8:30 PM", value: "20:30" },
  { label: "8:45 PM", value: "20:45" },
  { label: "9:00 PM", value: "21:00" },
  { label: "9:15 PM", value: "21:15" },
  { label: "9:30 PM", value: "21:30" },
  { label: "9:45 PM", value: "21:45" },
  { label: "10:00 PM", value: "22:00" },
  { label: "10:15 PM", value: "22:15" },
  { label: "10:30 PM", value: "22:30" },
  { label: "10:45 PM", value: "22:45" },
  { label: "11:00 PM", value: "23:00" },
  { label: "11:15 PM", value: "23:15" },
  { label: "11:30 PM", value: "23:30" },
  { label: "11:45 PM", value: "23:45" },
];

export const Doses = [
  {
    label: "0.5",
    value: 0.5,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "1.5",
    value: 1.5,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "2.5",
    value: 2.5,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "3.5",
    value: 3.5,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "4.5",
    value: 4.5,
  },
  {
    label: "5",
    value: 5,
  },
];

export const specificDays = [
  {
    text: "Mon",
    value: 0,
    checked: false,
  },
  {
    text: "Tue",
    value: 0,
    checked: false,
  },
  {
    text: "Wed",
    value: 0,
    checked: false,
  },
  {
    text: "Thu",
    value: 0,
    checked: false,
  },
  {
    text: "Fri",
    value: 0,
    checked: false,
  },
  {
    text: "Sat",
    value: 0,
    checked: false,
  },
  {
    text: "Sun",
    value: 0,
    checked: false,
  },
];

export const countryCodes = [
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    name: "Afghanistan",
    dial_code: "+93",
    code: "AF",
  },
  {
    name: "Albania",
    dial_code: "+355",
    code: "AL",
  },
  {
    name: "Algeria",
    dial_code: "+213",
    code: "DZ",
  },
  {
    name: "AmericanSamoa",
    dial_code: "+1 684",
    code: "AS",
  },
  {
    name: "Andorra",
    dial_code: "+376",
    code: "AD",
  },
  {
    name: "Angola",
    dial_code: "+244",
    code: "AO",
  },
  {
    name: "Anguilla",
    dial_code: "+1 264",
    code: "AI",
  },
  {
    name: "Antigua and Barbuda",
    dial_code: "+1268",
    code: "AG",
  },
  {
    name: "Argentina",
    dial_code: "+54",
    code: "AR",
  },
  {
    name: "Armenia",
    dial_code: "+374",
    code: "AM",
  },
  {
    name: "Aruba",
    dial_code: "+297",
    code: "AW",
  },
  {
    name: "Australia",
    dial_code: "+61",
    code: "AU",
  },
  {
    name: "Austria",
    dial_code: "+43",
    code: "AT",
  },
  {
    name: "Azerbaijan",
    dial_code: "+994",
    code: "AZ",
  },
  {
    name: "Bahamas",
    dial_code: "+1 242",
    code: "BS",
  },
  {
    name: "Bahrain",
    dial_code: "+973",
    code: "BH",
  },
  {
    name: "Bangladesh",
    dial_code: "+880",
    code: "BD",
  },
  {
    name: "Barbados",
    dial_code: "+1 246",
    code: "BB",
  },
  {
    name: "Belarus",
    dial_code: "+375",
    code: "BY",
  },
  {
    name: "Belgium",
    dial_code: "+32",
    code: "BE",
  },
  {
    name: "Belize",
    dial_code: "+501",
    code: "BZ",
  },
  {
    name: "Benin",
    dial_code: "+229",
    code: "BJ",
  },
  {
    name: "Bermuda",
    dial_code: "+1 441",
    code: "BM",
  },
  {
    name: "Bhutan",
    dial_code: "+975",
    code: "BT",
  },
  {
    name: "Bosnia and Herzegovina",
    dial_code: "+387",
    code: "BA",
  },
  {
    name: "Botswana",
    dial_code: "+267",
    code: "BW",
  },
  {
    name: "Brazil",
    dial_code: "+55",
    code: "BR",
  },
  {
    name: "British Indian Ocean Territory",
    dial_code: "+246",
    code: "IO",
  },
  {
    name: "Bulgaria",
    dial_code: "+359",
    code: "BG",
  },
  {
    name: "Burkina Faso",
    dial_code: "+226",
    code: "BF",
  },
  {
    name: "Burundi",
    dial_code: "+257",
    code: "BI",
  },
  {
    name: "Cambodia",
    dial_code: "+855",
    code: "KH",
  },
  {
    name: "Cameroon",
    dial_code: "+237",
    code: "CM",
  },
  {
    name: "Canada",
    dial_code: "+1",
    code: "CA",
  },
  {
    name: "Cape Verde",
    dial_code: "+238",
    code: "CV",
  },
  {
    name: "Cayman Islands",
    dial_code: "+ 345",
    code: "KY",
  },
  {
    name: "Central African Republic",
    dial_code: "+236",
    code: "CF",
  },
  {
    name: "Chad",
    dial_code: "+235",
    code: "TD",
  },
  {
    name: "Chile",
    dial_code: "+56",
    code: "CL",
  },
  {
    name: "China",
    dial_code: "+86",
    code: "CN",
  },
  {
    name: "Christmas Island",
    dial_code: "+61",
    code: "CX",
  },
  {
    name: "Colombia",
    dial_code: "+57",
    code: "CO",
  },
  {
    name: "Comoros",
    dial_code: "+269",
    code: "KM",
  },
  {
    name: "Congo",
    dial_code: "+242",
    code: "CG",
  },
  {
    name: "Cook Islands",
    dial_code: "+682",
    code: "CK",
  },
  {
    name: "Costa Rica",
    dial_code: "+506",
    code: "CR",
  },
  {
    name: "Croatia",
    dial_code: "+385",
    code: "HR",
  },
  {
    name: "Cuba",
    dial_code: "+53",
    code: "CU",
  },
  {
    name: "Cyprus",
    dial_code: "+537",
    code: "CY",
  },
  {
    name: "Czech Republic",
    dial_code: "+420",
    code: "CZ",
  },
  {
    name: "Denmark",
    dial_code: "+45",
    code: "DK",
  },
  {
    name: "Djibouti",
    dial_code: "+253",
    code: "DJ",
  },
  {
    name: "Dominica",
    dial_code: "+1 767",
    code: "DM",
  },
  {
    name: "Dominican Republic",
    dial_code: "+1 849",
    code: "DO",
  },
  {
    name: "Ecuador",
    dial_code: "+593",
    code: "EC",
  },
  {
    name: "Egypt",
    dial_code: "+20",
    code: "EG",
  },
  {
    name: "El Salvador",
    dial_code: "+503",
    code: "SV",
  },
  {
    name: "Equatorial Guinea",
    dial_code: "+240",
    code: "GQ",
  },
  {
    name: "Eritrea",
    dial_code: "+291",
    code: "ER",
  },
  {
    name: "Estonia",
    dial_code: "+372",
    code: "EE",
  },
  {
    name: "Ethiopia",
    dial_code: "+251",
    code: "ET",
  },
  {
    name: "Faroe Islands",
    dial_code: "+298",
    code: "FO",
  },
  {
    name: "Fiji",
    dial_code: "+679",
    code: "FJ",
  },
  {
    name: "Finland",
    dial_code: "+358",
    code: "FI",
  },
  {
    name: "France",
    dial_code: "+33",
    code: "FR",
  },
  {
    name: "French Guiana",
    dial_code: "+594",
    code: "GF",
  },
  {
    name: "French Polynesia",
    dial_code: "+689",
    code: "PF",
  },
  {
    name: "Gabon",
    dial_code: "+241",
    code: "GA",
  },
  {
    name: "Gambia",
    dial_code: "+220",
    code: "GM",
  },
  {
    name: "Georgia",
    dial_code: "+995",
    code: "GE",
  },
  {
    name: "Germany",
    dial_code: "+49",
    code: "DE",
  },
  {
    name: "Ghana",
    dial_code: "+233",
    code: "GH",
  },
  {
    name: "Gibraltar",
    dial_code: "+350",
    code: "GI",
  },
  {
    name: "Greece",
    dial_code: "+30",
    code: "GR",
  },
  {
    name: "Greenland",
    dial_code: "+299",
    code: "GL",
  },
  {
    name: "Grenada",
    dial_code: "+1 473",
    code: "GD",
  },
  {
    name: "Guadeloupe",
    dial_code: "+590",
    code: "GP",
  },
  {
    name: "Guam",
    dial_code: "+1 671",
    code: "GU",
  },
  {
    name: "Guatemala",
    dial_code: "+502",
    code: "GT",
  },
  {
    name: "Guinea",
    dial_code: "+224",
    code: "GN",
  },
  {
    name: "Guinea-Bissau",
    dial_code: "+245",
    code: "GW",
  },
  {
    name: "Guyana",
    dial_code: "+595",
    code: "GY",
  },
  {
    name: "Haiti",
    dial_code: "+509",
    code: "HT",
  },
  {
    name: "Honduras",
    dial_code: "+504",
    code: "HN",
  },
  {
    name: "Hungary",
    dial_code: "+36",
    code: "HU",
  },
  {
    name: "Iceland",
    dial_code: "+354",
    code: "IS",
  },
  {
    name: "India",
    dial_code: "+91",
    code: "IN",
  },
  {
    name: "Indonesia",
    dial_code: "+62",
    code: "ID",
  },
  {
    name: "Iraq",
    dial_code: "+964",
    code: "IQ",
  },
  {
    name: "Ireland",
    dial_code: "+353",
    code: "IE",
  },
  {
    name: "Israel",
    dial_code: "+972",
    code: "IL",
  },
  {
    name: "Italy",
    dial_code: "+39",
    code: "IT",
  },
  {
    name: "Jamaica",
    dial_code: "+1 876",
    code: "JM",
  },
  {
    name: "Japan",
    dial_code: "+81",
    code: "JP",
  },
  {
    name: "Jordan",
    dial_code: "+962",
    code: "JO",
  },
  {
    name: "Kazakhstan",
    dial_code: "+7 7",
    code: "KZ",
  },
  {
    name: "Kenya",
    dial_code: "+254",
    code: "KE",
  },
  {
    name: "Kiribati",
    dial_code: "+686",
    code: "KI",
  },
  {
    name: "Kuwait",
    dial_code: "+965",
    code: "KW",
  },
  {
    name: "Kyrgyzstan",
    dial_code: "+996",
    code: "KG",
  },
  {
    name: "Latvia",
    dial_code: "+371",
    code: "LV",
  },
  {
    name: "Lebanon",
    dial_code: "+961",
    code: "LB",
  },
  {
    name: "Lesotho",
    dial_code: "+266",
    code: "LS",
  },
  {
    name: "Liberia",
    dial_code: "+231",
    code: "LR",
  },
  {
    name: "Liechtenstein",
    dial_code: "+423",
    code: "LI",
  },
  {
    name: "Lithuania",
    dial_code: "+370",
    code: "LT",
  },
  {
    name: "Luxembourg",
    dial_code: "+352",
    code: "LU",
  },
  {
    name: "Madagascar",
    dial_code: "+261",
    code: "MG",
  },
  {
    name: "Malawi",
    dial_code: "+265",
    code: "MW",
  },
  {
    name: "Malaysia",
    dial_code: "+60",
    code: "MY",
  },
  {
    name: "Maldives",
    dial_code: "+960",
    code: "MV",
  },
  {
    name: "Mali",
    dial_code: "+223",
    code: "ML",
  },
  {
    name: "Malta",
    dial_code: "+356",
    code: "MT",
  },
  {
    name: "Marshall Islands",
    dial_code: "+692",
    code: "MH",
  },
  {
    name: "Martinique",
    dial_code: "+596",
    code: "MQ",
  },
  {
    name: "Mauritania",
    dial_code: "+222",
    code: "MR",
  },
  {
    name: "Mauritius",
    dial_code: "+230",
    code: "MU",
  },
  {
    name: "Mayotte",
    dial_code: "+262",
    code: "YT",
  },
  {
    name: "Mexico",
    dial_code: "+52",
    code: "MX",
  },
  {
    name: "Monaco",
    dial_code: "+377",
    code: "MC",
  },
  {
    name: "Mongolia",
    dial_code: "+976",
    code: "MN",
  },
  {
    name: "Montenegro",
    dial_code: "+382",
    code: "ME",
  },
  {
    name: "Montserrat",
    dial_code: "+1664",
    code: "MS",
  },
  {
    name: "Morocco",
    dial_code: "+212",
    code: "MA",
  },
  {
    name: "Myanmar",
    dial_code: "+95",
    code: "MM",
  },
  {
    name: "Namibia",
    dial_code: "+264",
    code: "NA",
  },
  {
    name: "Nauru",
    dial_code: "+674",
    code: "NR",
  },
  {
    name: "Nepal",
    dial_code: "+977",
    code: "NP",
  },
  {
    name: "Netherlands",
    dial_code: "+31",
    code: "NL",
  },
  {
    name: "Netherlands Antilles",
    dial_code: "+599",
    code: "AN",
  },
  {
    name: "New Caledonia",
    dial_code: "+687",
    code: "NC",
  },
  {
    name: "New Zealand",
    dial_code: "+64",
    code: "NZ",
  },
  {
    name: "Nicaragua",
    dial_code: "+505",
    code: "NI",
  },
  {
    name: "Niger",
    dial_code: "+227",
    code: "NE",
  },
  {
    name: "Nigeria",
    dial_code: "+234",
    code: "NG",
  },
  {
    name: "Niue",
    dial_code: "+683",
    code: "NU",
  },
  {
    name: "Norfolk Island",
    dial_code: "+672",
    code: "NF",
  },
  {
    name: "Northern Mariana Islands",
    dial_code: "+1 670",
    code: "MP",
  },
  {
    name: "Norway",
    dial_code: "+47",
    code: "NO",
  },
  {
    name: "Oman",
    dial_code: "+968",
    code: "OM",
  },
  {
    name: "Pakistan",
    dial_code: "+92",
    code: "PK",
  },
  {
    name: "Palau",
    dial_code: "+680",
    code: "PW",
  },
  {
    name: "Panama",
    dial_code: "+507",
    code: "PA",
  },
  {
    name: "Papua New Guinea",
    dial_code: "+675",
    code: "PG",
  },
  {
    name: "Paraguay",
    dial_code: "+595",
    code: "PY",
  },
  {
    name: "Peru",
    dial_code: "+51",
    code: "PE",
  },
  {
    name: "Philippines",
    dial_code: "+63",
    code: "PH",
  },
  {
    name: "Poland",
    dial_code: "+48",
    code: "PL",
  },
  {
    name: "Portugal",
    dial_code: "+351",
    code: "PT",
  },
  {
    name: "Puerto Rico",
    dial_code: "+1 939",
    code: "PR",
  },
  {
    name: "Qatar",
    dial_code: "+974",
    code: "QA",
  },
  {
    name: "Romania",
    dial_code: "+40",
    code: "RO",
  },
  {
    name: "Rwanda",
    dial_code: "+250",
    code: "RW",
  },
  {
    name: "Samoa",
    dial_code: "+685",
    code: "WS",
  },
  {
    name: "San Marino",
    dial_code: "+378",
    code: "SM",
  },
  {
    name: "Saudi Arabia",
    dial_code: "+966",
    code: "SA",
  },
  {
    name: "Senegal",
    dial_code: "+221",
    code: "SN",
  },
  {
    name: "Serbia",
    dial_code: "+381",
    code: "RS",
  },
  {
    name: "Seychelles",
    dial_code: "+248",
    code: "SC",
  },
  {
    name: "Sierra Leone",
    dial_code: "+232",
    code: "SL",
  },
  {
    name: "Singapore",
    dial_code: "+65",
    code: "SG",
  },
  {
    name: "Slovakia",
    dial_code: "+421",
    code: "SK",
  },
  {
    name: "Slovenia",
    dial_code: "+386",
    code: "SI",
  },
  {
    name: "Solomon Islands",
    dial_code: "+677",
    code: "SB",
  },
  {
    name: "South Africa",
    dial_code: "+27",
    code: "ZA",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dial_code: "+500",
    code: "GS",
  },
  {
    name: "Spain",
    dial_code: "+34",
    code: "ES",
  },
  {
    name: "Sri Lanka",
    dial_code: "+94",
    code: "LK",
  },
  {
    name: "Sudan",
    dial_code: "+249",
    code: "SD",
  },
  {
    name: "Suriname",
    dial_code: "+597",
    code: "SR",
  },
  {
    name: "Swaziland",
    dial_code: "+268",
    code: "SZ",
  },
  {
    name: "Sweden",
    dial_code: "+46",
    code: "SE",
  },
  {
    name: "Switzerland",
    dial_code: "+41",
    code: "CH",
  },
  {
    name: "Tajikistan",
    dial_code: "+992",
    code: "TJ",
  },
  {
    name: "Thailand",
    dial_code: "+66",
    code: "TH",
  },
  {
    name: "Togo",
    dial_code: "+228",
    code: "TG",
  },
  {
    name: "Tokelau",
    dial_code: "+690",
    code: "TK",
  },
  {
    name: "Tonga",
    dial_code: "+676",
    code: "TO",
  },
  {
    name: "Trinidad and Tobago",
    dial_code: "+1 868",
    code: "TT",
  },
  {
    name: "Tunisia",
    dial_code: "+216",
    code: "TN",
  },
  {
    name: "Turkey",
    dial_code: "+90",
    code: "TR",
  },
  {
    name: "Turkmenistan",
    dial_code: "+993",
    code: "TM",
  },
  {
    name: "Turks and Caicos Islands",
    dial_code: "+1 649",
    code: "TC",
  },
  {
    name: "Tuvalu",
    dial_code: "+688",
    code: "TV",
  },
  {
    name: "Uganda",
    dial_code: "+256",
    code: "UG",
  },
  {
    name: "Ukraine",
    dial_code: "+380",
    code: "UA",
  },
  {
    name: "United Arab Emirates",
    dial_code: "+971",
    code: "AE",
  },
  {
    name: "United Kingdom",
    dial_code: "+44",
    code: "GB",
  },
  {
    name: "United States",
    dial_code: "+1",
    code: "US",
  },
  {
    name: "Uruguay",
    dial_code: "+598",
    code: "UY",
  },
  {
    name: "Uzbekistan",
    dial_code: "+998",
    code: "UZ",
  },
  {
    name: "Vanuatu",
    dial_code: "+678",
    code: "VU",
  },
  {
    name: "Wallis and Futuna",
    dial_code: "+681",
    code: "WF",
  },
  {
    name: "Yemen",
    dial_code: "+967",
    code: "YE",
  },
  {
    name: "Zambia",
    dial_code: "+260",
    code: "ZM",
  },
  {
    name: "Zimbabwe",
    dial_code: "+263",
    code: "ZW",
  },
  {
    name: "land Islands",
    dial_code: "",
    code: "AX",
  },
  {
    name: "Antarctica",
    dial_code: null,
    code: "AQ",
  },
  {
    name: "Bolivia, Plurinational State of",
    dial_code: "+591",
    code: "BO",
  },
  {
    name: "Brunei Darussalam",
    dial_code: "+673",
    code: "BN",
  },
  {
    name: "Cocos (Keeling) Islands",
    dial_code: "+61",
    code: "CC",
  },
  {
    name: "Congo, The Democratic Republic of the",
    dial_code: "+243",
    code: "CD",
  },
  {
    name: "Cote d'Ivoire",
    dial_code: "+225",
    code: "CI",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dial_code: "+500",
    code: "FK",
  },
  {
    name: "Guernsey",
    dial_code: "+44",
    code: "GG",
  },
  {
    name: "Holy See (Vatican City State)",
    dial_code: "+379",
    code: "VA",
  },
  {
    name: "Hong Kong",
    dial_code: "+852",
    code: "HK",
  },
  {
    name: "Iran, Islamic Republic of",
    dial_code: "+98",
    code: "IR",
  },
  {
    name: "Isle of Man",
    dial_code: "+44",
    code: "IM",
  },
  {
    name: "Jersey",
    dial_code: "+44",
    code: "JE",
  },
  {
    name: "Korea, Democratic People's Republic of",
    dial_code: "+850",
    code: "KP",
  },
  {
    name: "Korea, Republic of",
    dial_code: "+82",
    code: "KR",
  },
  {
    name: "Lao People's Democratic Republic",
    dial_code: "+856",
    code: "LA",
  },
  {
    name: "Libyan Arab Jamahiriya",
    dial_code: "+218",
    code: "LY",
  },
  {
    name: "Macao",
    dial_code: "+853",
    code: "MO",
  },
  {
    name: "Macedonia, The Former Yugoslav Republic of",
    dial_code: "+389",
    code: "MK",
  },
  {
    name: "Micronesia, Federated States of",
    dial_code: "+691",
    code: "FM",
  },
  {
    name: "Moldova, Republic of",
    dial_code: "+373",
    code: "MD",
  },
  {
    name: "Mozambique",
    dial_code: "+258",
    code: "MZ",
  },
  {
    name: "Palestinian Territory, Occupied",
    dial_code: "+970",
    code: "PS",
  },
  {
    name: "Pitcairn",
    dial_code: "+872",
    code: "PN",
  },
  {
    name: "Réunion",
    dial_code: "+262",
    code: "RE",
  },
  {
    name: "Russia",
    dial_code: "+7",
    code: "RU",
  },
  {
    name: "Saint Barthélemy",
    dial_code: "+590",
    code: "BL",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dial_code: "+290",
    code: "SH",
  },
  {
    name: "Saint Kitts and Nevis",
    dial_code: "+1 869",
    code: "KN",
  },
  {
    name: "Saint Lucia",
    dial_code: "+1 758",
    code: "LC",
  },
  {
    name: "Saint Martin",
    dial_code: "+590",
    code: "MF",
  },
  {
    name: "Saint Pierre and Miquelon",
    dial_code: "+508",
    code: "PM",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dial_code: "+1 784",
    code: "VC",
  },
  {
    name: "Sao Tome and Principe",
    dial_code: "+239",
    code: "ST",
  },
  {
    name: "Somalia",
    dial_code: "+252",
    code: "SO",
  },
  {
    name: "Svalbard and Jan Mayen",
    dial_code: "+47",
    code: "SJ",
  },
  {
    name: "Syrian Arab Republic",
    dial_code: "+963",
    code: "SY",
  },
  {
    name: "Taiwan, Province of China",
    dial_code: "+886",
    code: "TW",
  },
  {
    name: "Tanzania, United Republic of",
    dial_code: "+255",
    code: "TZ",
  },
  {
    name: "Timor-Leste",
    dial_code: "+670",
    code: "TL",
  },
  {
    name: "Venezuela, Bolivarian Republic of",
    dial_code: "+58",
    code: "VE",
  },
  {
    name: "Viet Nam",
    dial_code: "+84",
    code: "VN",
  },
  {
    name: "Virgin Islands, British",
    dial_code: "+1 284",
    code: "VG",
  },
  {
    name: "Virgin Islands, U.S.",
    dial_code: "+1 340",
    code: "VI",
  },
];

export const IsNumber = (number) =>
  typeof number === "number" &&
  !Number.isNaN(number) &&
  Number.isFinite(number) &&
  number !== Infinity &&
  number !== -Infinity;
export const IsDate = (date) => date instanceof Date && !isNaN(date);
export const GetNumber = (value) => {
  try {
    let r = +value;
    if (isNaN(r) || !isFinite(r) || r === Infinity || r === -Infinity) r = '';
    return r;
  } catch (error) {
    console.error(error);
    return 0;
  }
};

export const getAverage = (arr) => {
  if (!Array.isArray(arr) || arr.length === 0) return 0;
  arr = arr.filter(IsNumber);
  if (!arr.length) return 0;

  let average = arr.reduce((a, b) => a + b) / arr.length;
  average = Math.round((average + Number.EPSILON) * 100) / 100;
  return average;
};

export const isMonthType = (type) => type === "Months";

export const formatDate = (date) => {
  if (IsDate(date)) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    if (day < 10) day = `0${day}`;
    if (month < 10) month = `0${month}`;
    return `${day}/${month}/${year}`;
  }
  return "01/01/1970";
};

export const getDateFromStringDDMMYY = (date) => {
  const defaultDate = new Date(1970, 1, 1);

  if (typeof date !== "string") return defaultDate;

  let [day, month, year] = date.split("/");
  day = +day;
  month = +month;
  year = +year;

  if (!IsNumber(day) || !IsNumber(month) || !IsNumber(year)) return defaultDate;

  const returnDate = new Date(year, month - 1, day);
  if (!IsDate(returnDate)) return defaultDate;

  return returnDate;
};

export const getDateFromStringMMDDYY = (date) => {
  const defaultDate = new Date(1970, 1, 1);

  if (typeof date !== "string") return defaultDate;

  let [month, day, year] = date.split("/");
  day = +day;
  month = +month;
  year = +year;

  if (!IsNumber(day) || !IsNumber(month) || !IsNumber(year)) return defaultDate;

  const returnDate = new Date(year, month - 1, day);
  if (!IsDate(returnDate)) return defaultDate;

  return returnDate;
};

export const getDDMMYYfromDate = (date) => {
  if (!IsDate(date)) return "01/01/1970";

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  if (day <= 9) day = `0${day}`;
  if (month <= 9) month = `0${month}`;
  return `${day}/${month}/${year}`;
};

export const getMMDDYYfromDate = (date) => {
  if (!IsDate(date)) return "01/01/1970";

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  if (day <= 9) day = `0${day}`;
  if (month <= 9) month = `0${month}`;
  return `${month}/${day}/${year}`;
};

export const formatDateTooltip = (dateString, addDayName) => {
  let monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const date = getDateFromStringDDMMYY(dateString);

  //   console.log( {dateString, newDate: date});
  const dayName = date.toString().split(" ")[0];
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const monthName = monthNames[monthIndex];

  const year = date.getFullYear();

  let value = `${day} ${monthName} ${year}`;
  if (addDayName) value = `${dayName}, ${value}`;
  return value;
};

export const datasetKeyProvider = () => {
  return btoa(Math.random()).substring(0, 12);
};

export const getUniqueDates = (data, type) => {
  // console.log('get date and type',data,type)
  if (!Array.isArray(data) || data.length === 0) return [];

  type = type || "Days";
  const isMonth = isMonthType(type);

  const dates = data
    //   console.log({uniqueDates});
    .map((o) => {
      const date = getDateFromStringDDMMYY(o.uplaod_date);
      const formattedDate = formatDate(date);
      let [day, month, year] = formattedDate.split("/");
      if (isMonth) day = 1;
      return new Date(`${month}/${day}/${year}`);
    })
    .sort((a, b) => a - b);

  const uniqueDates = [...new Set(dates.map(formatDate))];
  if (isMonth) {
    // console.log({dates});
    // console.log({uniqueDates});
  }
  return uniqueDates;
};

export const getChartLabels = (dates, type) => {
  const isMonth = isMonthType(type);
  const labels = Array.from({ length: dates.length }, (_, i) => {
    let label = "";
    if (isMonth) {
      const [, month] = dates[i].split("/");
      label = month;
    } else {
      label = dates[i].split("/")[0];
    }
    return label;
  });
  return labels;
};

export const getDateWiseReduced = (data, column, type) => {
  const isMonth = isMonthType(type);
  const dateWiseReduced = data.reduce((acc, curr) => {
    const uploadDate = curr.uplaod_date;
    let date = getDateFromStringDDMMYY(uploadDate);
    date = formatDate(date);
    // console.log({type});
    if (isMonth) {
      const keyParts = date.split("/");
      date = +`${keyParts[1]}${keyParts[2]}`;
    }
    if (!acc[date]) acc[date] = [];

    const value = curr[column] || 0;
    acc[date].push(value);

    return acc;
  }, {});
  return dateWiseReduced;
};

export const AnalysisChartType = {
  AverageHealingScore: "AverageHealingScore",
  OdourLevel: "OdourLevel",
  PainLevel: "PainLevel",
};

export const getDateRange = (dateArray, type, fromDate, toDate) => {
  const isMonth = isMonthType(type);
  if (!Array.isArray(dateArray) || !dateArray.length) {
    return [];
  }

  let startDate = getDateFromStringDDMMYY(dateArray[0]);
  let endDate = getDateFromStringDDMMYY(dateArray[dateArray.length - 1]);

  if (fromDate !== null && toDate !== null) {
    startDate = fromDate;
    endDate = toDate;
  }

  let dates = [];
  if (isMonth) {
    let diff = (endDate.getTime() - startDate.getTime()) / 1000;
    diff /= 60 * 60 * 24 * 7 * 4;
    const months = Math.abs(Math.round(diff));
    const formattedDate = formatDate(startDate);
    let [day, month, year] = formattedDate.split("/");
    day = 1;
    const tempDate = new Date(`${month}/${day}/${year}`);
    Array.from({ length: months }, (_, index) => {
      let date = new Date(tempDate);
      date.setMonth(tempDate.getMonth() + index);
      date = formatDate(date);
      dates.push(date);
      return true;
    });
  } else {
    let diff = endDate.getTime() - startDate.getTime();
    let days = Math.ceil(diff / (1000 * 3600 * 24)) + 1;
    dates = [];
    Array.from({ length: days }, (_, index) => {
      let date = new Date(startDate);
      date.setDate(startDate.getDate() + index);
      date = formatDate(date);
      dates.push(date);
      return true;
    });
  }
  // console.log({dates});
  return dates;
};

export const getMonthRange = (dateRange) =>
  dateRange.map((d) => {
    let [, month, year] = d.split("/");
    return `${+month}${+year}`;
  });

export const GetDaysData = (
  data,
  column,
  noOfRows,
  type,
  chartType,
  startDate,
  endDate
) => {
  type = type || "Days";
  const isMonth = isMonthType(type);
  // if (isMonth) console.log({'👉': data, type});
  // data = data.filter(o => o[column]);
  let uniqueDates = getUniqueDates(data, type);
  let dateRange = getDateRange(uniqueDates, type, startDate, endDate);

  const dateWiseReduced = getDateWiseReduced(data, column, type);

  // show line in chart if any date has more than one entries
  const showLines = Object.values(dateWiseReduced).some((a) => a.length > 1);

  let average = Array(dateRange.length).fill(null);
  let minMaxData = Array(dateRange.length).fill(null);
  if (isMonth) {
    const monthRange = getMonthRange(dateRange);
    Object.entries(dateWiseReduced).map(([date, valueArray]) => {
      const dateIndex = monthRange.indexOf(date);
      if (dateIndex >= 0 && Array.isArray(valueArray)) {
        valueArray = valueArray.filter((v) => v);
        if (valueArray.length) {
          const avg = valueArray.reduce((a, b) => a + b) / valueArray.length;
          average[dateIndex] = avg;
          minMaxData[dateIndex] = [
            Math.min(...valueArray),
            Math.max(...valueArray),
          ];
        }
      }
      return true;
    });
  } else {
    // console.log({dateWiseReduced});
    Object.entries(dateWiseReduced).map(([date, valueArray]) => {
      const dateIndex = dateRange.indexOf(date);
      if (dateIndex >= 0 && Array.isArray(valueArray) && valueArray.length) {
        average[dateIndex] = getAverage(valueArray);
        minMaxData[dateIndex] = [
          Math.min(...valueArray),
          Math.max(...valueArray),
        ];
      }
      return true;
    });
  }

  const getResultArray = () => {
    const length = dateRange.length;
    const rows = Array.from({ length: noOfRows }, () => []);
    // if (isMonth) console.log({'🎇': rows});
    if (!length) return rows;
    return rows.map(() => Array.from({ length }, (_, index) => [0, 0]));
  };
  //   console.log({resultArray: getResultArray()});

  let formattedData = getResultArray();
  if (isMonth) {
    // console.log({'✨': formattedData});
  }

  let toolTipData = {};
  Object.entries(dateWiseReduced).map(([date, arr]) => {
    // if (isMonth) console.log({date, arr});
    let newUniqueDates = [...dateRange];
    if (isMonth) {
      // console.log({formattedData});
      newUniqueDates = newUniqueDates.map((d) => {
        const [, month, year] = d.split("/");
        return String(+`${month}${year}`);
      });
      // console.log({noOfRows, date, arr, uniqueDates, dateRange, newUniqueDates, formattedData});
    }
    const dateIndex = newUniqueDates.indexOf(date);
    if (isMonth) {
      // console.log({noOfRows, date, dateIndex});
    }
    // const length = arr.length;
    let min = Math.min(...arr);
    let max = Math.max(...arr);
    if (isMonth) {
      let avg = getAverage(arr);
      avg = Math.round((avg + Number.EPSILON) * 100) / 100;
      toolTipData[date] = { min, max, avg };
    }
    // console.log({min, max});
    if (IsNumber(max) && max > 0) {
      // if (length <= 2 &&
      //     (length === 1 || min === max) &&
      //     min > 0 &&
      //     chartType === AnalysisChartType.AverageHealingScore) {
      //   min -= 1;
      // }
      if (dateIndex >= 0) {
        if (isMonth) {
          // console.log({noOfRows, min, max, formattedData});
          // let i = min === 0 ? 0 : min - 1;
          let i = min;
          for (; i < max; i++) {
            formattedData[i][dateIndex] = [i, i + 1];
          }
        } else {
          formattedData[max - 1][dateIndex] = [min, max];
        }
      }
    }
    return null;
  });

  if (isMonth) {
    // console.log('👇');
    // formattedData.map(d => console.log(d));
  }

  return {
    formattedData,
    dates: dateRange,
    average,
    toolTipData,
    showLines,
    minMaxData,
  };
};

export const GetWoundDepthUnit = (value) => "cm";
// value && value < 100 ? "mm" : "cm";

export const startDateConversion = (new_start_date) => {
  let d = new Date(new_start_date * 1000);
  let new_date =
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2);
  return new Date(`${new_date}T00:00:00.000+08:00`) / 1000;
};

export const endDateConversion = (new_end_date) => {
  let d = new Date(new_end_date * 1000);
  let new_date =
    d.getFullYear() +
    "-" +
    ("0" + (d.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + d.getDate()).slice(-2);
  return new Date(`${new_date}T23:59:59.000+08:00`) / 1000;
};

export const convertServerTimeStamp = (timeStamp) => {
  let serverTimeZone = "Asia/Singapore"; // TODO: Get from env file
  return (
    new Date(
      new Date(timeStamp * 1000).toLocaleString("en-US", {
        timeZone: serverTimeZone,
      })
    ) / 1000
  );
};

export const convertServerTimeStamp2 = (timeStamp) => {
  // let serverTimeZone = "Asia/Singapore"; // TODO: Get from env file
  // let tmp = new Date(
  //   new Date(timeStamp * 1000).toLocaleString("en-US", {
  //     timeZone: serverTimeZone,
  //   })
  // ).setHours(0, 0, 0, 0) / 1000
  // console.log('tmp: ', tmp);

  // return (
  //   new Date(
  //     new Date(timeStamp * 1000).toLocaleString("en-US", {
  //       timeZone: serverTimeZone,
  //     })
  //   ).setHours(0, 0, 0, 0) / 1000
  // );
  return timeStamp;
};

// export const showBmiUnits = (bmi) => {
//     if (Number(bmi) < 18.5) {
//         return "Underweight"
//     }
//     else if (Number(bmi) >= 18.5 && Number(bmi) <= 25.0) {
//         return "Normal"
//     }
//     else if (Number(bmi) >= 25.0 && Number(bmi) <= 30.0) {
//         return "Overweight"
//     }
//     else {
//         return "Obese"
//     }
// }

export const showBmiUnits = (bmi) => {
  if (Number(bmi) >= 5 && Number(bmi) <= 18.4) {
    return "Low";
  } else if (Number(bmi) >= 18.5 && Number(bmi) <= 24.9) {
    return "Normal";
  } else if (Number(bmi) >= 25.0 && Number(bmi) <= 29.9) {
    return "Overweight";
  } else if (Number(bmi) >= 30.0 && Number(bmi) <= 34.9) {
    return "Obesity Class I";
  } else if (Number(bmi) >= 35.0 && Number(bmi) <= 39.9) {
    return "Obesity Class II";
  } else if (Number(bmi) >= 40.0 && Number(bmi) <= 250) {
    return "Obesity Class III";
  } else {
    return "Obese";
  }
};

export const calcSugarLevel = (level) => {
  return sugarLevelArr.filter((val) => Number(val.value) == Number(level));
};

export const calculate_age = (dob1) => {
  var today = new Date();
  var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
  var age_now = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age_now--;
  }
  return age_now;
};

export const calcPulseRate = (level) => {
  level.age = calculate_age(moment(level.age).toDate());
  console.log(level);
  let heartRate = parseFloat(level.heart_rate)?.toFixed(2);
  const AthleteCondition =
    (level.age <= 25 && heartRate >= 49 && heartRate <= 55) ||
    (level.age >= 26 &&
      level.age <= 35 &&
      heartRate >= 49 &&
      heartRate <= 54) ||
    (level.age >= 36 &&
      level.age <= 45 &&
      heartRate >= 50 &&
      heartRate <= 56) ||
    (level.age >= 46 &&
      level.age <= 55 &&
      heartRate >= 50 &&
      heartRate <= 57) ||
    (level.age >= 56 &&
      level.age <= 65 &&
      heartRate >= 51 &&
      heartRate <= 56) ||
    (level.age >= 65 && heartRate >= 50 && heartRate <= 55);

  const ExcellentCondition =
    (level.age <= 25 && heartRate >= 56 && heartRate <= 61) ||
    (level.age >= 26 &&
      level.age <= 35 &&
      heartRate >= 55 &&
      heartRate <= 61) ||
    (level.age >= 36 &&
      level.age <= 45 &&
      heartRate >= 57 &&
      heartRate <= 62) ||
    (level.age >= 46 &&
      level.age <= 55 &&
      heartRate >= 58 &&
      heartRate <= 63) ||
    (level.age >= 56 &&
      level.age <= 65 &&
      heartRate >= 57 &&
      heartRate <= 61) ||
    (level.age >= 65 && heartRate >= 56 && heartRate <= 61);

  const GreatCondition =
    (level.age <= 25 && heartRate >= 62 && heartRate <= 65) ||
    (level.age >= 26 &&
      level.age <= 35 &&
      heartRate >= 62 &&
      heartRate <= 65) ||
    (level.age >= 36 &&
      level.age <= 45 &&
      heartRate >= 63 &&
      heartRate <= 66) ||
    (level.age >= 46 &&
      level.age <= 55 &&
      heartRate >= 64 &&
      heartRate <= 67) ||
    (level.age >= 56 &&
      level.age <= 65 &&
      heartRate >= 62 &&
      heartRate <= 67) ||
    (level.age >= 65 && heartRate >= 62 && heartRate <= 65);

  const GoodCondition =
    (level.age <= 25 && heartRate >= 66 && heartRate <= 69) ||
    (level.age >= 26 &&
      level.age <= 35 &&
      heartRate >= 66 &&
      heartRate <= 70) ||
    (level.age >= 36 &&
      level.age <= 45 &&
      heartRate >= 67 &&
      heartRate <= 70) ||
    (level.age >= 46 &&
      level.age <= 55 &&
      heartRate >= 68 &&
      heartRate <= 71) ||
    (level.age >= 56 &&
      level.age <= 65 &&
      heartRate >= 68 &&
      heartRate <= 71) ||
    (level.age >= 65 && heartRate >= 66 && heartRate <= 69);

  const AverageCondition =
    (level.age <= 25 && heartRate >= 70 && heartRate <= 73) ||
    (level.age >= 26 &&
      level.age <= 35 &&
      heartRate >= 71 &&
      heartRate <= 74) ||
    (level.age >= 36 &&
      level.age <= 45 &&
      heartRate >= 71 &&
      heartRate <= 75) ||
    (level.age >= 46 &&
      level.age <= 55 &&
      heartRate >= 72 &&
      heartRate <= 76) ||
    (level.age >= 56 &&
      level.age <= 65 &&
      heartRate >= 72 &&
      heartRate <= 75) ||
    (level.age >= 65 && heartRate >= 70 && heartRate <= 73);

  const BelowAverageCondition =
    (level.age <= 25 && heartRate >= 74 && heartRate <= 81) ||
    (level.age >= 26 &&
      level.age <= 35 &&
      heartRate >= 75 &&
      heartRate <= 81) ||
    (level.age >= 36 &&
      level.age <= 45 &&
      heartRate >= 76 &&
      heartRate <= 82) ||
    (level.age >= 46 &&
      level.age <= 55 &&
      heartRate >= 77 &&
      heartRate <= 83) ||
    (level.age >= 56 &&
      level.age <= 65 &&
      heartRate >= 76 &&
      heartRate <= 81) ||
    (level.age >= 65 && heartRate >= 74 && heartRate <= 79);

  const PoorCondition =
    (level.age <= 25 && heartRate >= 82) ||
    (level.age >= 26 && level.age <= 35 && heartRate >= 82) ||
    (level.age >= 36 && level.age <= 45 && heartRate >= 83) ||
    (level.age >= 46 && level.age <= 55 && heartRate >= 84) ||
    (level.age >= 56 && level.age <= 65 && heartRate >= 82) ||
    (level.age >= 65 && heartRate >= 80);

  if (AthleteCondition) {
    return { label: "Athlete", colorClass: "label-athlete" };
  } else if (ExcellentCondition) {
    return { label: "Excellent", colorClass: "label-excellent" };
  } else if (GreatCondition) {
    return { label: "Great", colorClass: "label-great" };
  } else if (GoodCondition) {
    return { label: "Good", colorClass: "label-good" };
  } else if (AverageCondition) {
    return { label: "Average", colorClass: "label-average" };
  } else if (BelowAverageCondition) {
    return { label: "Poor", colorClass: "label-below-avg" };
  } else if (PoorCondition) {
    return { label: "Critical", colorClass: "label-cyanosis" };
  } else {
    return { label: "Athlete", colorClass: "label-athlete" };
  }
};

export const calcBloodPressue = (level) => {
  if (level.systolic == "Less than 120" || level.diastolic == "Less than 80") {
    return [{ lebel: "Low Blood Pressure", colorClass: "label-athlete" }];
  } else if (level.systolic == "120 - 129" && level.diastolic == "80 - 89") {
    return [{ lebel: "Normal", colorClass: "label-excellent" }];
  } else if (level.systolic == "130 - 139" || level.diastolic == "90 - 100") {
    return [{ lebel: "Prehypertension", colorClass: "label-great" }];
  } else if (level.systolic == "140 - 180" || level.diastolic == "100 - 120") {
    return [
      {
        lebel: "Hypertension Stage 1",
        colorClass: "label-good",
      },
    ];
  } else if (
    level.systolic == "Higher than 180" ||
    level.diastolic == "Higher than 120"
  ) {
    return [
      {
        // lebel: "High Blood Pressure Crisis",
        lebel: "Critical Blood Pressure",
        colorClass: "label-average",
      },
    ];
  }
};

export const getHrvLevelText = hrv => {
  if (hrv <= 50) {
    return {label: "Unhealthy", colorClass:"unhealthy", color: '#EB6857'};
  } else if (hrv > 50 && hrv <= 100) {
    return {label: "Compromised Health", colorClass:"compromised-health", color: '#E6D158'};
  } else if (hrv > 100) {
    return {label: "Healthy", colorClass:"healthy", color: '#6CB26A'};
  }
};

export const calcBloodPressueMobile = (level) => {
  let sys = level.systolic,
    dia = level.diastolic;

  if (dia && dia.startsWith("<") || dia.startsWith("Less than")) {
    dia = "79";
  }
  if (dia && dia.startsWith(">") || dia.startsWith("Greater than")) {
    dia = "121";
  }

  if (sys && sys.startsWith("<") || sys.startsWith("Less than")) {
    sys = "119";
  }
  if (sys && sys.startsWith(">") || sys.startsWith("Greater than")) {
    sys = "181";
  }
  if (dia == "" && sys == "") {
    return "N/A";
  }

  if (sys < 80 || dia < 60) {
    return [{ lebel: "Hypotension", colorClass: "label-athlete" }];
  } else if (sys >= 80 && sys <= 120 && dia >= 60 && dia <= 80) {
    return [{ lebel: "Normal", colorClass: "label-excellent" }];
  } else if ((sys >= 121 && sys <= 139) || (dia >= 90 && dia <= 99)) {
    return [{ lebel: "Prehypertension", colorClass: "label-great" }];
  } else if ((sys >= 140 && sys <= 159) || (dia >= 81 && dia <= 89)) {
    return [
      {
        lebel: "Hypertension - 1",
        colorClass: "label-good",
      },
    ];
  } else if ((sys >= 160 && sys <= 180) || (dia >= 100 && dia <= 110)) {
    return [
      {
        lebel: "Hypertension - 2",
        colorClass: "label-average",
      },
    ];
  } else if (sys > 180 || dia > 110) {
    return [
      {
        lebel: "Critical",
        colorClass: "label-critical",
      },
    ];
  } else {
    return "N/A";
  }
};

/**heart rate calc start */
export const calcHeartRate = (params) => {
  params.age = calculate_age(moment(params.age).toDate());
  if (params.gender.toLowerCase() == "male") {
    return calcMaleBPMMale(params);
  } else {
    return calcMaleBPMFeMale(params);
  }
};

export const calcMaleBPMMale = (params) => {
  let filterAgeData = AgeWiseBPMMale.filter(
    (val) =>
      // Number(val.label.split("-")[0]) <= params.age &&
      // Number(val.label.split("-")[1]) >= params.age
      params.age > parseInt(val.label.split("-")[0]) &&
      params.age <= parseInt(val.label.split("-")[1])
  );

  if (filterAgeData[0]) {
    var final = filterAgeData[0].subArr.filter(
      (sub) =>
        // Number(sub.bpm.split("-")[0]) <= Math.round(params.heart_rate) &&
        // Number(sub.bpm.split("-")[1]) >= Math.round(params.heart_rate)
        Math.round(params.heart_rate) > parseInt(sub.bpm.split("-")[0]) &&
        Math.round(params.heart_rate) <= parseInt(sub.bpm.split("-")[1])
    );
    if (final.length === 0) {
      final.push(filterAgeData[0].subArr[0])
    }
    return final;
  } else {
    return [];
  }
};

export const calcResRate = (params) => {
  let filterData = ResRate.filter(
    (val) => Number(val.label) <= params && Number(val.label) >= params
  );

  return filterData;
};

export const woundTemp = [
  { value: 0, label: "Low", class: "wtext-low", colorCode: "#52b362" },
  //{ value: "0", label: "Low", class: "wtext-low", colorCode: "#52b362" },
  { value: 1, label: "Medium", class: "wtext-medium", colorCode: "#89be52" },
  { value: 2, label: "Normal", class: "wtext-normal", colorCode: "#d3c740" },
  { value: 3, label: "Hot", class: "wtext-hot", colorCode: "#e78722" },
  { value: 4, label: "Very Hot", class: "wtext-vhot", colorCode: "#e2120c" },
];

export const exudateType = [
  {
    value: "Serous (clear)",
    label: "Serous (clear)",
    class: "wtext-low",
  },
  {
    value: "Serosanguinous (pale red, pink)",
    label: "Serosanguinous (pale red, pink)",
    class: "wtext-normal",
  },
  {
    value: "Sanguineous (blood)",
    label: "Sanguineous (blood)",
    class: "wtext-medium",
  },
  {
    value: "Purulent (yellow, green, tan)",
    label: "Purulent (yellow, green, tan)",
    class: "wtext-vhot",
  },
];

export const exudateAmount = [
  {
    value: "None",
    label: "None",
    class: "wtext-low", colorCode: "#52b362"
  },
  {
    value: "Scant",
    label: "Scant",
    class: "wtext-medium", colorCode: "#89be52"
  },
  {
    value: "Moderate",
    label: "Moderate",
    class: "wtext-normal", colorCode: "#d3c740"
  },
  {
    value: "Heavy",
    label: "Heavy",
    class: "wtext-hot", colorCode: "#e78722"
  },
  {
    value: "Copious",
    label: "Copious",
    class: "wtext-vhot", colorCode: "#e2120c"
  },
];

export const cultureRadioOptions = [
  {
    label: 'Swab',
    value: 'Swab',
  },
  {
    label: 'Biopsy',
    value: 'Biopsy',
  },
];

export const locationHourOptions = [
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
]

export const exudateConsistancy = [
  {
    label: 'Thin',
    value: 'Thin',
  },
  {
    label: 'Thick',
    value: 'Thick',
  },
];

export const calcExudate = (params) => {
  return exudateAmount.filter((val) => val.value == params);

};

export const dressingCategories = [
  {
    label: 'Alginates',
    value: 'Alginates',
  },
  {
    label: 'Collagens',
    value: 'Collagens',
  },
  {
    label: 'Composites',
    value: 'Composites',
  },
  {
    label: 'Foams',
    value: 'Foams',
  },
  {
    label: 'Gauzes',
    value: 'Gauzes',
  },
  {
    label: 'Medical Grade Honey',
    value: 'Medical Grade Honey',
  },
  {
    label: 'Hydrocolloids',
    value: 'Hydrocolloids',
  },
  {
    label: 'Hydrofibers',
    value: 'Hydrofibers',
  },
  {
    label: 'Hydrogels',
    value: 'Hydrogels',
  },
  {
    label: 'Impregnated Dressings',
    value: 'Impregnated Dressings',
  },
  {
    label: 'Super Absorbent Dressings',
    value: 'Super Absorbent Dressings',
  },
  {
    label: 'Transparent Dressings',
    value: 'Transparent Dressings',
  },
  {
    label: 'Wound Fillers',
    value: 'Wound Fillers',
  },
  {
    label: 'Antimicrobials',
    value: 'Antimicrobials',
  },
  {
    label: 'Bandages/Compression',
    value: 'Bandages/Compression'
  },
  {
    label: 'Tapes',
    value: 'Tapes'
  },
  {
    label: 'First Aid',
    value: 'First Aid'
  }
];

export const calcWoundTemp = (params) => {
  //console.log("params", params);

  if (params === 0 || params === "0") {
    return [
      {
        value: 0,
        label: "Low",
        class: "wtext-low",
        colorCode: "#52b362",
      },
    ];
  } else {
    return woundTemp.filter((val) => val.value == Number(params));
  }
};

export const GENERAL_WELLBEING = [
  {
    key: 'high',
    Color: '#88BE51',
    title: "4 High 0-52",
    className: "well-high"
  },
  {
    key: 'medium-high',
    Color: '#B8CE46',
    title: "3 Medium-High 53-65",
    className: "well-medium-high"
  },
  {
    key: 'medium',
    Color: '#D3C740',
    title: "2 Medium 66-74",
    className: "well-medium"
  },
  {
    key: 'medium-low',
    Color: '#EBD039',
    title: "1 Medium-Low 75-88",
    className: "well-medium-low"
  },
  {
    key: 'low',
    Color: '#FF5D4F',
    title: "0 Low 89-100",
    className: "well-low"
  },
]

export const NUTRITION_DATA = [
  {
    key: 'poor',
    Color: '#FF5D4F',
    title: '0 Poor <25%',
    className: "nut-poor"
  },
  {
    key: 'fair',
    Color: '#EBD039',
    title: '1 Fair 26-50%',
    className: "nut-fair"

  },
  {
    key: 'good',
    Color: '#D3C740',
    title: '2 Good 50-75%',
    className: "nut-good"
  },
  {
    key: 'exellent',
    Color: '#B8CE46',
    title: '3 Excellent 76-100%',
    className: "nut-exellent"
  }
]

export const UnitPreferencesDefault = [
  // {
  //     key: 'geolocation_radius',
  //     label: 'Geolocation Radius',
  //     option_1: 'meters (m)',
  //     option_2: 'miles (mi)',
  //     o1Selected: true,
  //     o2Selected: false,

  // },
  {
    key: 'wound_size',
    label: 'Wound Size',
    option_1: 'Centimeters (cm)',
    option_2: 'Inches (in)',
    o1Selected: true,
    o2Selected: false,
  },
  {
    key: 'temperature',
    label: 'Temperature',
    option_1: 'Celsius (°C)',
    option_2: 'Fahrenheit (°F)',
    o1Selected: true,
    o2Selected: false,

  },
  // {
  //     key: 'volume',
  //     label: 'Volume',
  //     option_1: 'Milliliters (mL)',
  //     option_2: 'Fluid Ounces (Oz)',
  //     o1Selected: true,
  //     o2Selected: false,

  // },
  {
    key: 'weight',
    label: 'Weight',
    option_1: 'Kilograms (kg)',
    option_2: 'Pounds (lb)',
    o1Selected: true,
    o2Selected: false,

  },
  {
    key: 'height',
    label: 'Height',
    option_1: 'Centimeters (cm)',
    option_2: 'Feet & Inches (ft & in)',
    o1Selected: true,
    o2Selected: false,

  },
  {
    key: 'depth',
    label: 'Depth',
    option_1: 'Centimeters (cm)',
    option_2: 'Inches (in)',
    o1Selected: true,
    o2Selected: false,

  },
  // {
  //     key: 'waist_circumference',
  //     label: 'Waist circumference (cm)',
  //     option_1: 'Centimeters (cm)',
  //     option_2: 'Inches (in)',
  //     o1Selected: true,
  //     o2Selected: false,

  // },
  // {
  //     key: 'cholesterol',
  //     label: 'Cholesterol',
  //     option_1: 'Millimoles per liter (mmol/L)',
  //     option_2: 'Milligrams per deciliter (mg/dL)',
  //     o1Selected: true,
  //     o2Selected: false,

  // },
  {
    key: 'blood_glucose',
    label: 'Blood Glucose',
    option_1: 'Millimoles per liter (mmol/L)',
    option_2: 'Milligrams per deciliter (mg/dL)',
    o1Selected: true,
    o2Selected: false,

  },
  {
    key: 'hba1c',
    label: 'HbA1C',
    option_1: 'Percentage (%)',
    option_2: 'Millimoles per mole (mmol/mol)',
    o1Selected: true,
    o2Selected: false,
  },
]

export const calcResRateInt = (params) => {
  params = parseFloat(params);
  // console.log(params);
  if (params <= 8) {
    return {
      label: params,
      value: params,
      label_name: "Severe",
      colorClass: "label-cyanosis",
    };
  } else if (params > 8 && params <= 11) {
    return {
      label: params,
      value: params,
      label_name: "Moderate",
      colorClass: "label-good",
    };
  } else if (params > 11 && params <= 20) {
    return {
      label: params,
      value: params,
      label_name: "Normal",
      colorClass: "label-excellent",
    };
  } else if (params > 20 && params <= 24) {
    return {
      label: params,
      value: params,
      label_name: "Moderate",
      colorClass: "label-good",
    };
  } else if (params > 24) {
    return {
      label: params,
      value: params,
      label_name: "Severe",
      colorClass: "label-cyanosis",
    };
  }
};

export const calcStressLevel = (params) => {
  params = parseFloat(params);
  // console.log('...params',params);
  if (params >= 1 && params <= 3) {
    return {
      label: "Low",
      value: params,
      label_name: "Low",
      colorClass: "low-stress",
    };
  } else if (params >= 4 && params <= 7) {
    return {
      label: "Moderate",
      value: params,
      label_name: "Moderate",
      colorClass: "moderate-stress",
    };
  } else if (params >= 8 && params <= 10) {
    return {
      label: "High",
      value: params,
      label_name: "High",
      colorClass: "high-stress",
    };
  } else {
    return {
      label: "Low",
      value: params,
      label_name: "Low",
      colorClass: "low-stress",
    };
  }
}

export const calcOxygenLevel = (params) => {
  return SPO2Arr.filter(
    (val) =>
      Number(val.val.split("-")[0]) <= params &&
      Number(val.val.split("-")[1]) >= params
  );
};

export const calcOxygenLevelMobile = (params) => {
  return SPO2ArrMobile.filter(
    (val) =>
      Number(val.val.split("-")[0]) <= params &&
      Number(val.val.split("-")[1]) >= params
  );
};

export const Cigarettes = [
  {
    value: "0",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>0</span>
        <img
          src={toAbsoluteUrl("/images/smoke/0.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "1",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>1</span>
        <img
          src={toAbsoluteUrl("/images/smoke/1.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "2",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>2</span>
        <img
          src={toAbsoluteUrl("/images/smoke/2.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "3",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>3</span>
        <img
          src={toAbsoluteUrl("/images/smoke/3.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "4",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>4</span>
        <img
          src={toAbsoluteUrl("/images/smoke/4.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "5",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>5</span>
        <img
          src={toAbsoluteUrl("/images/smoke/5.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "6",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>6</span>
        <img
          src={toAbsoluteUrl("/images/smoke/6.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "7",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>7</span>
        <img
          src={toAbsoluteUrl("/images/smoke/7.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "8",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>8</span>
        <img
          src={toAbsoluteUrl("/images/smoke/8.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "9",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>9</span>
        <img
          src={toAbsoluteUrl("/images/smoke/9.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "10",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>10</span>
        <img
          src={toAbsoluteUrl("/images/smoke/10.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "11",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>11</span>
        <img
          src={toAbsoluteUrl("/images/smoke/11.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "12",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>12</span>
        <img
          src={toAbsoluteUrl("/images/smoke/12.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "13",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>13</span>
        <img
          src={toAbsoluteUrl("/images/smoke/13.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "14",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>14</span>
        <img
          src={toAbsoluteUrl("/images/smoke/14.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "15",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>15</span>
        <img
          src={toAbsoluteUrl("/images/smoke/15.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "16",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>16</span>
        <img
          src={toAbsoluteUrl("/images/smoke/16.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "17",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>17</span>
        <img
          src={toAbsoluteUrl("/images/smoke/17.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "18",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>18</span>
        <img
          src={toAbsoluteUrl("/images/smoke/18.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "19",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>19</span>
        <img
          src={toAbsoluteUrl("/images/smoke/19.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "20",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>20</span>
        <img
          src={toAbsoluteUrl("/images/smoke/20.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
  {
    value: "20+",
    label: (
      <div className="d-flex align-items-center justify-content-between smoke-right">
        <span style={{ paddingTop: 5, display: "inline-block" }}>20+</span>
        <img
          src={toAbsoluteUrl("/images/smoke/20+.png")}
          height="30px"
          width="30px"
        />
      </div>
    ),
  },
];

export const calcMaleBPMFeMale = (params) => {
  let filterAgeData = AgeWiseBPMFemale.filter(
    (val) =>
      // Number(val.label.split("-")[0]) <= params.age &&
      // Number(val.label.split("-")[1]) >= params.age
      params.age > Number(val.label.split("-")[0]) &&
      params.age <= Number(val.label.split("-")[1])
  );

  if (filterAgeData[0]) {
    var final = filterAgeData[0].subArr.filter(
      (sub) =>
        // Number(sub.bpm.split("-")[0]) <= params.heart_rate &&
        // Number(sub.bpm.split("-")[1]) >= params.heart_rate
        Math.round(params.heart_rate) > Number(sub.bpm.split("-")[0]) &&
        Math.round(params.heart_rate) <= Number(sub.bpm.split("-")[1])
    );
    if (final.length === 0) {
      final.push(filterAgeData[0].subArr[0])
    }
    return final;
  } else {
    return [];
  }
};

/**heart rate calc end */

/** body temp calc start */
export const calcBodyTemp = (temp) => {
  var bodytemp = [];
  if (temp) {
    bodytemp = BodyTempArr.filter(
      (val) =>
        Number(val.value.split("-")[0]) <= temp &&
        Number(val.value.split("-")[1]) >= temp
    );
  }

  return bodytemp;
};
/** body temp calc end */

export const calcBodyTempMobile = (temp) => {
  var bodytemp = [];
  if (temp) {
    bodytemp = BodyTempArrMobile.filter(
      (val) =>
        Number(val.value.split("-")[0]) <= temp &&
        Number(val.value.split("-")[1]) >= temp
    );
  }

  return bodytemp;
};

export const CaptitalizeName = (name1, name2) => {
  return `${name1} ${name2}`
    .split(" ")
    .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
    .join(" ");
};

export const getDatesBetweenDates = (startDate, endDate) => {
  let dates = [];
  //to avoid modifying the original date
  const theDate = new Date(startDate);
  while (theDate < endDate) {
    dates = [...dates, new Date(theDate).getDate()];
    theDate.setDate(theDate.getDate() + 1);
  }
  return [...dates, endDate.getDate()];
};

export const getTomorrowDate = () => {
  var nextDay = new Date();
  nextDay.setDate(nextDay.getDate() + 1);
  return nextDay;
};

export const dataURItoBlob = (dataURI) => {
  if (dataURI) {
    // convert base64 to raw binary data held in a string
    var byteString = atob(dataURI?.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI?.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(arrayBuffer);
    for (var i = 0; i < byteString.length; i++) {
      _ia[i] = byteString.charCodeAt(i);
    }

    var dataView = new DataView(arrayBuffer);
    let blob = new Blob([dataView], { type: mimeString });
    return blob;
  } else {
    let blob = new Blob([], { type: "" });
    return blob;
  }
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const shortMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const ordinal = (number) => {
  const english_ordinal_rules = new Intl.PluralRules("en-US", {
    type: "ordinal",
  });
  const suffixes = {
    one: "st",
    two: "nd",
    few: "rd",
    other: "th",
  };
  const suffix = suffixes[english_ordinal_rules.select(number)];
  return number + suffix;
};
export const convertTimeStampToDateWithOrdinalMedication = (value) => {
  // value = dayjs(value);
  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var date = new Date(value * 1000);
  // console.log('date: ', date);
  let newDate =
    ordinal(date.getDate()) +
    " " +
    month[date.getMonth()] +
    " " +
    date.getFullYear();
  // console.log('newDate: ', newDate);
  return newDate;
};
export const convertTimeStampToDateWithOrdinal = (value) => {
  value = convertServerTimeStamp(value);
  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var date = new Date(value * 1000);
  let newDate =
    ordinal(date.getDate()) +
    " " +
    month[date.getMonth()] +
    " " +
    date.getFullYear();
  return newDate;
};

//convert UTC time to local

export const convertTimeStampToDateWithOrdinal3 = (value) => {
  // console.log("run4")
  value =
    moment
      .utc(value * 1000)
      .local()
      .toDate()
      .getTime() / 1000;
  //  console.log(moment.utc(value * 1000).local().toDate(),"value value")
  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var date = new Date(value * 1000);
  let newDate =
    ordinal(date.getDate()) +
    " " +
    month[date.getMonth()] +
    " " +
    date.getFullYear();
  return newDate;
};

export const convertTimeStampToDateWithOrdinal4 = (value) => {
  // console.log("run4")
  value = moment.utc(value).local().toDate().getTime() / 1000;
  //  console.log(moment.utc(value * 1000).local().toDate(),"value value")
  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var date = new Date(value * 1000);
  let newDate =
    ordinal(date.getDate()) +
    " " +
    month[date.getMonth()] +
    " " +
    date.getFullYear();
  return newDate;
};

export const convertTimeStampToDateWithOrdinal2 = (value) => {
  value = convertServerTimeStamp(value);
  var month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  var date = new Date(value * 1000);
  let newDate =
    date.getDate() + " " + month[date.getMonth()] + " " + date.getFullYear();
  return newDate;
};

export const convertTimeStampToDate = (value) => {
  value = convertServerTimeStamp(value);

  var month = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  var date = new Date(value * 1000);
  let newDate =
    date.getDate() + "/" + month[date.getMonth()] + "/" + date.getFullYear();
  return newDate;
};

export const uploadDateDisplay = (value) => {
  // value = convertServerTimeStamp(value)
  var month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var date = new Date(value);
  let newDate =
    ordinal(date.getDate()) +
    " " +
    month[date.getMonth()] +
    " " +
    date.getFullYear() +
    " " +
    date.getHours() +
    ":" +
    date.getMinutes();
  return newDate;
};

export const convertScheduleDate = (value) => {
  value = convertServerTimeStamp(value);
  var date = new Date(value * 1000);
  // eslint-disable-next-line no-useless-concat
  let newDate =
    `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}` +
    "/" +
    `${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
    }` +
    "/" +
    date.getFullYear();
  return newDate;
};

export const uploadDateDisplayNew = (value) => {
  // value = convertServerTimeStamp(value)
  return dayjs(value).format("Do MMM YYYY HH:mm");
};

export const dateConvertUTCToLocal = (utcTime) => {
  let localTime = moment.utc(utcTime);
  return moment(localTime).local().format("Do MMM YYYY HH:mm")
}

export const timeConvertUTCToLocal = (utcTime) => {
  let localTime = moment.utc(utcTime);
  return moment(localTime).local().format("HH:mm A")
}

export const convertToDDMMYYYY = (value) => {
  // value = convertServerTimeStamp(value)
  return dayjs(value).format("DD/MM/YYYY");
};
export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });

  // fetch(dataurl)
  //     .then(res => res.blob())
  //     .then(blob => {
  //         const file = new File([blob], filename, { type: "image/png" })
  //         return file
  //     })
}

export const capitalizeString = (str) => {
  var splitStr = str ? str.toLowerCase().split(" ") : [];
  for (var i = 0; i < splitStr.length; i++) {
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  return splitStr.join(" ");
};

export const signatureTypeLookUp = [
  {
    label: "Manual Signature",
    value: 1,
  },
  {
    label: "File Upload",
    value: 2,
  },
];
export const downloadOneFileFromUrl = (url) => {
  var link = document.createElement("a");

  link.setAttribute("download", "prescription");
  link.style.display = "none";
  document.body.appendChild(link);

  link.setAttribute("href", url);
  link.setAttribute("target", "_blank");
  link.click();

  document.body.removeChild(link);
};

export const downloadFromUrl = (url, name) => {
  var link = document.createElement("a");

  link.setAttribute("download", name);
  link.style.display = "none";
  document.body.appendChild(link);

  link.setAttribute("href", url);
  link.setAttribute("target", "_blank");
  link.click();

  document.body.removeChild(link);
};

export const createCanvasImage = (json) => {
  var can = document.getElementById('canvas');
  var contextRound = can.getContext('2d');
  var lineX = can.getContext('2d');
  var lineY = can.getContext('2d');

  let arr = json[3].wound_segmentation;
  let arrLength = json[3].wound_max_length_coordinates.map(item => {
    return {
      "startPoint": { "x": item[0][0], "y": item[0][1] },
      "endPoint": { "x": item[1][0], "y": item[1][1] }
    }
  });

  let arrWidth = json[3].wound_max_width_coordinates.map(item => {
    return {
      "startPoint": { "x": item[0][0], "y": item[0][1] },
      "endPoint": { "x": item[1][0], "y": item[1][1] }
    }
  });

  //round start
  contextRound.beginPath();
  contextRound.lineWidth = 3;
  contextRound.strokeStyle = 'blue';
  for (let i = 0; i < arr.length; i++) {
    for (let j = 0; j < arr[i].length; j++) {
      contextRound.lineTo(arr[i][j][0][0], arr[i][j][0][1]);
    }
  }
  contextRound.stroke();
  //round end

  //line x draw start                        
  lineX.beginPath();
  lineX.lineWidth = 3;
  lineX.strokeStyle = 'yellow';
  lineX.lineTo(arrLength[0].startPoint.x, arrLength[0].startPoint.y);
  lineX.lineTo(arrLength[0].endPoint.x, arrLength[0].endPoint.y);
  lineX.stroke();
  //line x draw end

  //line Y draw start                        
  lineY.beginPath();
  lineY.lineWidth = 3;
  lineY.strokeStyle = 'yellow';
  lineY.lineTo(arrWidth[0].startPoint.x, arrWidth[0].startPoint.y);
  lineY.lineTo(arrWidth[0].endPoint.x, arrWidth[0].endPoint.y);
  lineY.stroke();
  //line Y draw end
};


export const downloadPDFForMobile = (url) => {
  var link = document.createElement("a");

  link.setAttribute("download", "prescription");
  link.style.display = "none";
  document.body.appendChild(link);

  link.setAttribute("href", url);
  // link.setAttribute("target", "_blank");
  link.click();

  document.body.removeChild(link);
};