/**
 * Axios config  setup
 * Set interceptor for global api response error handling
 * Set access token in headers
 */
import axios from "axios";
import store from "../store";
import { AUTHENTICATE_FAILED } from "../store/Login/Types";
import { parse } from "query-string";
import { isMobile } from "react-device-detect";
import { getDoctorsDetailsById } from "../store/Doctor/Actions";
import { authenticate } from "../store/Login/Actions";
import { useDispatch } from "react-redux";
// var dispatch = useDispatch();
(function (axios) {
  console.log("query string ", parse(window.location.search));

  var token = "";
  if (
    window.location.pathname.includes("/patients-history/") &&
    isMobile &&
    !localStorage.getItem("authToken") &&
    !localStorage.getItem("user")
  ) {
    console.log("if app js ", localStorage);
    const query = parse(window.location.search);

    localStorage.setItem("authToken", JSON.stringify(query?.authToken));
    store
      .dispatch(getDoctorsDetailsById({ doctorId: query?.doctorId }))
      .then((res) => {
        localStorage.setItem("user", JSON.stringify(res.return_data));
        console.log("if");
        if (localStorage.getItem("authToken")) {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }

        console.log("app js after call api and set token ", localStorage);

        //Check if userdata is available in session storage
        token =
          localStorage.getItem("authToken") &&
          localStorage.getItem("authToken") != null
            ? JSON.parse(localStorage.getItem("authToken"))
            : null;
        const user = JSON.parse(localStorage.getItem("user"));

        // if (localStorage.getItem("user")) {
        //   dispatch(authenticate(JSON.parse(localStorage.getItem("user"))));
        // }
      });
  } else {
    token =
      localStorage.getItem("authToken") &&
      localStorage.getItem("authToken") != null
        ? JSON.parse(localStorage.getItem("authToken"))
        : null;
  }

  if (token) {
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }

  axios.interceptors.response.use(null, (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        if (
          !window.location.pathname.includes("/patients-history/") &&
          !isMobile
        ) {
          store.dispatch({
            type: AUTHENTICATE_FAILED,
          });
        }

        return Promise.reject(error);
      } else return Promise.reject(error);
    } else if (error.request) {
      let err = {
        response: {
          data: {
            message: "Something went wrong,Please try again later!!!",
          },
        },
      };
      return Promise.reject(err);
    }
  });
})(axios);
