import * as ACTION_TYPES from "./Types";

const initialState = {
  message: "",
  error: null,
  patientList: [],
  patient: {},
  archiveStatus: [],
  isJoined: false,
  totalCount: 0,
  mediaList: [],
};

const PatientReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.RESET_PATIENT_STATE:
      return {
        ...initialState,
      };

    case ACTION_TYPES.LISTUSER_SUCCESS:
      return {
        ...state,
        patientList: Object.keys(action.payload.return_data).length ? action.payload.return_data.patientData : [],
        totalCount: Object.keys(action.payload.return_data).length ? action.payload.return_data.totalPatientCount : 0
      };

    case ACTION_TYPES.LISTUSER_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };
    case ACTION_TYPES.PATIENTS_DETAILS_SUCCESS:
      return {
        ...state,
        patient: action.payload.return_data[0],
      };

    case ACTION_TYPES.PATIENTS_DETAILS_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.ARCHIVE_PATIENT_SUCCESS:
      return {
        ...state,
        archiveStatus: action.payload.return_data,
      };

    case ACTION_TYPES.ARCHIVE_PATIENT_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.IS_JOINED_MEETING:
      return {
        ...state,
        isJoined: !state.isJoined,
      };

    case ACTION_TYPES.RESEND_MAIL_SUCCESS:
      return {
        ...state,
        patient: action.payload.return_data,
      };

    case ACTION_TYPES.RESEND_MAIL_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.GLASS_FILES_SUCCESS:
      return {
        ...state,
        mediaList: action.payload.return_data,
      };

    case ACTION_TYPES.GLASS_FILES_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    default:
      return state;
  }
};

export default PatientReducer;
