import Footer from "./Footer";
import { toAbsoluteUrl } from "../../utils";
import style from "../../scss/component/wound.module.scss";

const RestrictMobileView = () => {

    function getAppFromStore(url) {
        window.open(url, '_blank');
    }

    return (
        <>
            <div className={`${style.wounddetail}`}>
                <div className={`${style.woundheader}`}>  
                    <img src={toAbsoluteUrl("/images/wound-logo.svg")} alt="wound-logo" />
                    <p>Wound 'AI'D is a remote monitoring platform to analyse dermis and epidermis layers using advanced AI techniques. </p>
                </div>
                <div className={`${style.woundmain}`}>  
                    <h2 className={`${style.title}`}>Apps</h2>
                    {/*<div className={`${style.woundlist} d-flex w-100`}>*/}
                    {/*    <picture>*/}
                    {/*        <img src={toAbsoluteUrl("/images/wound-icon1.png")} alt="wound-icon1" />*/}
                    {/*    </picture>*/}
                    {/*    <div className={`${style.wounddesc} d-flex flex-wrap`}>*/}
                    {/*        <div className={`d-flex w-100 justify-content-between`}>*/}
                    {/*            <div className={`${style.woundtitle}`}>*/}
                    {/*                <h2>Wound ‘AI‘d</h2>*/}
                    {/*                <span>Medical</span>*/}
                    {/*            </div>*/}
                    {/*            <div className={`${style.getbtn}`}>*/}
                    {/*                <button>GET</button>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <p className={`${style.desc} w-100 `}>You are the recipient of health care services that are performed by healthcare professionals.</p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={`${style.woundlist} d-flex w-100`}>
                        <picture className="iOS" >
                            <img src={toAbsoluteUrl("/images/wound-icon2.png")} alt="wound-icon2" />
                        </picture>
                        <div className={`${style.wounddesc} d-flex flex-wrap`}>
                            <div className={`d-flex w-100 justify-content-between`}>
                                <div className={`${style.woundtitle}`}>
                                    <h2>Wound ‘AI‘d Clinician</h2>
                                    <span>Apple</span>
                                </div>
                                <div className={`${style.getbtn}`}>
                                    <button onClick={()=> getAppFromStore("https://apps.apple.com/in/app/wound-aid-clinician/id1582000219")}>GET</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${style.woundlist} d-flex w-100`}>
                        <picture className="Android" >
                            <img src={toAbsoluteUrl("/images/wound-icon2.png")} alt="wound-icon2" />
                        </picture>
                        <div className={`${style.wounddesc} d-flex flex-wrap`}>
                            <div className={`d-flex w-100 justify-content-between`}>
                                <div className={`${style.woundtitle}`}>
                                    <h2>Wound ‘AI‘d Clinician</h2>
                                    <span>Android</span>
                                </div>
                                <div className={`${style.getbtn}`}>
                                    <button onClick={()=> getAppFromStore("https://play.google.com/store/apps/details?id=com.sotdashboard")}>GET</button>
                                </div>
                            </div>
                            {/*<p className={`${style.desc} w-100 `}>You are the clinician may diagnose, treat, and otherwise care for patients.</p>*/}
                        </div>
                    </div>
                </div>
            </div>
                
            
            <Footer componentView='restricetdView' />
        </>
        
    )
}

export default RestrictMobileView;