import React, { useEffect, lazy, Suspense, useState } from "react";
import { Router, Route, Switch } from "react-router-dom";
import "./index.scss";
import { isDesktop, isMobile, isTablet } from "react-device-detect";
import store from "./store/index";

import ReduxToastr from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";

import { createBrowserHistory } from "history";

//Axios Interceptor
import "./config/AxiosConfig";
//Interceptor

import { toAbsoluteUrl } from "./utils";
import * as ACTIONS from "./store/Login/Actions";
import { AUTHENTICATE_FAILED } from "./store/Login/Types";

import checkAuth from "./Authentication/Auth";
import ThreedImage from "./components/common/Patient/ThreedImage";
import HeaderTest from "./components/common/Patient/HeaderTest";

import { parse } from "query-string";
import { getDoctorsDetailsById, getUnitPreferences } from "./store/Doctor/Actions";
import RestrictMobileView from "./components/common/RestrictMobileView";
// import { authenticate } from "./store/Login/Actions";


if (
  window.location.pathname.includes("/patients-history/") &&
  isMobile &&
  !localStorage.getItem("authToken") &&
  !localStorage.getItem("user")
) {

  const query = parse(window.location.search);

  localStorage.setItem("authToken", JSON.stringify(query?.authToken));
  store
    .dispatch(getDoctorsDetailsById({ doctorId: query?.doctorId }))
    .then((res) => {
      localStorage.setItem("user", JSON.stringify(res.return_data));

      if (localStorage.getItem("authToken")) {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }


      //Check if userdata is available in session storage
      const token =
        localStorage.getItem("authToken") &&
          localStorage.getItem("authToken") != null
          ? JSON.parse(localStorage.getItem("authToken"))
          : null;
      const user = JSON.parse(localStorage.getItem("user"))
      if (token || user) {

        store.dispatch(ACTIONS.authenticate(user));
      } else {

        store.dispatch({ type: AUTHENTICATE_FAILED });
      }
    });
} else {

  //Check if userdata is available in session storage
  const token =
    localStorage.getItem("authToken") &&
      localStorage.getItem("authToken") != null
      ? JSON.parse(localStorage.getItem("authToken"))
      : null;
  const user = JSON.parse(localStorage.getItem("user"));

  if (token || user) {
    store.dispatch(ACTIONS.authenticate(user));
  } else {
    store.dispatch({ type: AUTHENTICATE_FAILED });
  }
}

const history = createBrowserHistory();

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Components
const DefaultLayout = lazy(() => import("./components/layout"));

// Pages
const Home = lazy(() => import("./pages/Home"));
const Login = lazy(() => import("./pages/Login"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const ForceResetPwd = lazy(() => import("./pages/ForceResetPwd.js"));
const MobileAnatomy = lazy(() => import("./pages/MobileAnatomy"));
const ScrollToTop = React.lazy(() => import("./components/common/scroll"));

const GlobalDebug = (function () {
  var savedConsole = console;
  /**
  * @param {boolean} debugOn
  * @param {boolean} suppressAll
  */
  return function (debugOn, suppressAll) {
    var suppress = suppressAll || false;
    if (debugOn === false) {
      // supress the default console functionality
      console = {};
      console.log = function () { };
      // supress all type of consoles
      if (suppress) {
        console.info = function () { };
        console.warn = function () { };
        // console.error = function () { };
      } else {
        console.info = savedConsole.info;
        console.warn = savedConsole.warn;
        console.error = savedConsole.error;
      }
    } else {
      console = savedConsole;
    }
  };
})(); 


const mobileEnablePath = ['/Threed-image','/body-anatomy']
const App = () => {
  // window.screen.orientation.lock('portrait')
  const [width, setWidth] = useState(window.innerWidth);
  const [displayMobileRoute, setdisplayMobileRoute] = useState(false);
  const pathname = window.location.pathname;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  useEffect(()=>{
    for (let g = 0; g < mobileEnablePath.length; g++) {
      const element = mobileEnablePath[g];

      if(window.location.pathname.startsWith(element)){
        setdisplayMobileRoute(true)
        return 
      }else {
        setdisplayMobileRoute(false)
      }
      
    }
  },[])

  useEffect(() => {
    if (isDesktop) {
      document.body.classList.add("hide-orientation");
    } else if (isMobile || isTablet) {
      document.body.classList.add("block-orientation");
    }
  }, []);


  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      store.dispatch(getUnitPreferences(user.userId));
      store.dispatch(ACTIONS.getLoggedinUserImages({ clinician_id: user.userId }));
      // alert('reload');
    }
  }, []);

  // useEffect(() => {
  //   (process.env.REACT_APP != "Development") &&
  //     GlobalDebug(false);
  // }, []);

  // function getLocation() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(showPosition);
  //   } else {
  //     // x.innerHTML = "Geolocation is not supported by this browser.";
  //   }
  // }

  // function showPosition(position) {
  //   console.log(position, "position");
  // }
  // useEffect(() => {
  //   getLocation();
  // }, []);
  //ScreenOrientation   landscape-primary

  return (
    <>
      {displayMobileRoute ? (
        <div className="d-flex flex-column min-h100 ">
          <Router history={history}>
            <ReduxToastr />
            <Suspense fallback={loading()}>
              <ScrollToTop>
                <Switch>
                  <Route path="/health">
                    <h3>Shades Of Things</h3>
                  </Route>
                  <Route
                    exact
                    path="/join-trial"
                    name="Home"
                    render={(props) => <Home {...props} />}
                  />
                  <Route
                    exact
                    path="/"
                    name="Login"
                    render={(props) => <Login {...props} />}
                  />
                  <Route
                    exact
                    path="/login"
                    name="Login"
                    render={(props) => <Login {...props} />}
                  />
                  <Route
                    exact
                    path="/reset-password/:token"
                    name="Home"
                    render={(props) => <ResetPassword {...props} />}
                  />
                  <Route
                    exact
                    path="/force-reset"
                    name="Home"
                    render={(props) => <ForceResetPwd {...props} />}
                  />
                  <Route
                    exact
                    path="/Threed-image"
                    name="ThreedImage"
                    render={(props) => <ThreedImage {...props} />}
                  />
                  <Route
                    exact
                    path="/header-test"
                    name="HeaderTest"
                    render={(props) => <HeaderTest {...props} />}
                  />
                  <Route
                    exact
                    path="/body-anatomy/:gender/:id"
                    name="bodyAnatomy"
                    render={(props) => <MobileAnatomy {...props} />}
                  />
                  <Route
                    path="/"
                    name="Home"
                    component={checkAuth(DefaultLayout)}
                  />
                </Switch>
              </ScrollToTop>
            </Suspense>
          </Router>
        </div>
      ) :
        width > 1024 || pathname.includes('reset-password') ?
          (
            <>
              <div id="inner-root" className="d-flex flex-column min-h100 ">
                <Router history={history}>
                  <ReduxToastr />
                  <Suspense fallback={loading()}>
                    <ScrollToTop>
                      <Switch>
                        <Route path="/health">
                          <h3>Shades Of Things</h3>
                        </Route>
                        <Route
                          exact
                          path="/join-trial"
                          name="Home"
                          render={(props) => <Home {...props} />}
                        />
                        <Route
                          exact
                          path="/"
                          name="Login"
                          render={(props) => <Login {...props} />}
                        />
                        <Route
                          exact
                          path="/login"
                          name="Login"
                          render={(props) => <Login {...props} />}
                        />
                        <Route
                          exact
                          path="/header-test"
                          name="HeaderTest"
                          render={(props) => <HeaderTest {...props} />}
                        />
                        <Route
                          exact
                          path="/reset-password/:token"
                          name="Home"
                          render={(props) => <ResetPassword {...props} />}
                        />
                        <Route
                          exact
                          path="/force-reset"
                          name="Home"
                          render={(props) => <ForceResetPwd {...props} />}
                        />
                        <Route
                          exact
                          path="/Threed-image"
                          name="ThreedImage"
                          render={(props) => <ThreedImage {...props} />}
                        />
                        <Route
                          exact
                          path="/body-anatomy/:gender/:id"
                          name="bodyAnatomy"
                          render={(props) => <MobileAnatomy {...props} />}
                        />
                        <Route
                          path="/"
                          name="Home"
                          component={checkAuth(DefaultLayout)}
                        />
                      </Switch>
                    </ScrollToTop>
                  </Suspense>
                </Router>
              </div>
              <div id="landscape-div" className="landscape-wrap">
                <span>
                  <img
                    src={toAbsoluteUrl("/images/landscape.svg")}
                    alt="icon-sucessfull"
                  />
                </span>
                landscape is not allowed
              </div>
            </>
          ) :
          <RestrictMobileView />
      }

    </>
  );
};
export default App;
