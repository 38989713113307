/** LOGIN ACTIONS */
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_ERROR = "LOGIN_ERROR"

/** AUTHENTICATION ACTIONS */
export const AUTHENTICATE_USER = "AUTHENTICATE_USER"
export const AUTHENTICATE_FAILED = "AUTHENTICATE_FAILED"

/** TWILIO PASSCODE ACTIONS */
export const GET_TWIL_PASS_SUCCESS = "GET_TWIL_PASS_SUCCESS"
export const GET_TWIL_PASS_ERROR = "GET_TWIL_PASS_ERROR"

//logged in user images
export const USER_IMAGES_SUCCESS = "USER_IMAGES_SUCCESS";
export const USER_IMAGES_ERROR = "USER_IMAGES_ERROR";
