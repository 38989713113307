import React from 'react'

const IconUpload = ({
    className
}) => {
    return (
        <svg className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 50 40.7" style={{ enableBackground: "new 0 0 50 40.7" }}>
            <path id="Path_680" className="IconUpload" d="M41.7,13C39.4,3.8,30-1.8,20.8,0.5c-7.2,1.8-12.4,8-13,15.4c-5.1,0.8-8.6,5.7-7.7,10.8
	c0.7,4.5,4.7,7.9,9.3,7.9h7.8c0,0,1.7-0.1,1.7-1.6c0-1.4-1.7-1.5-1.7-1.5H9.4c-3.5,0-6.3-2.8-6.2-6.3c0-3.5,2.8-6.2,6.2-6.2
	c0.9,0,1.6-0.7,1.6-1.6C11,9.6,17.3,3.3,25,3.3c6.7,0,12.5,4.7,13.8,11.3c0.1,0.7,0.7,1.2,1.3,1.3c4.3,0.6,7.2,4.6,6.6,8.8
	c-0.5,3.8-3.8,6.7-7.7,6.7h-6.3c0,0-1.6,0-1.7,1.5c-0.1,1.5,1.7,1.6,1.7,1.6h6.3c6,0,10.9-4.9,10.9-11C50,18.6,46.5,14.2,41.7,13z"
            />
            <path id="Path_681" className="IconUpload" d="M23.9,19.4l-6.3,6.3c0,0-1,1.3,0,2.2c1,1,2.2,0,2.2,0l3.6-3.6v15c0.1,0.8,0.7,1.4,1.6,1.4
	c0.8,0,1.5-0.6,1.6-1.4v-15l3.6,3.6c0,0,1.1,1,2.2,0c1.1-1,0-2.2,0-2.2l-6.3-6.3C25.5,18.8,24.5,18.8,23.9,19.4z"/>
        </svg>
    )
}
export default IconUpload;