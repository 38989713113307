import * as ACTION_TYPES from "./Types";

const initialState = {
  message: "",
  error: null,
  token: "",
  isLoggedIn: false,
  user: {},
  user_role: "",
  twilioUrl: "",
  loggedinUserImgs: []
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.LOGIN_SUCCESS:
      console.log('action.payload: ', action.payload);
      localStorage.setItem("authToken", JSON.stringify(action.payload.token));
      localStorage.setItem("email", action.payload.login_id);
      localStorage.setItem("doctor_image", action.payload.doctor_image);
      localStorage.setItem(
        "user",
        JSON.stringify({
          userName: action.payload.user_name,
          userId: action.payload.user_id,
          user_role: action.payload.user_role,
          image: action.payload.image,
          id:
            action.payload.user_role === 1
              ? action.payload?.doctor_id
              : action.payload?.hospital_id,
          doctor_image: action.payload.doctor_image,
          token: action.payload.token,
          hospital_id: action.payload.hospital_id || action.payload.hospitalId
        })
      );
      return {
        ...state,
        user: {
          userId: action.payload.user_id,
          userName: action.payload.user_name,
          user_role: action.payload.user_role,
          id:
            action.payload.user_role === 1
              ? action.payload?.doctor_id
              : action.payload?.hospital_id,
          image: action.payload.image,
          doctor_image: action.payload.doctor_image,
          hospital_id: action.payload.hospital_id || action.payload.hospitalId
        },
        loggedinUserImgs: {
          logo: action.payload.image,
          doctor_image: action.payload.doctor_image
        },
        message: action?.payload?.data?.message || "",
        isLoggedIn: true,
        
      };

    case ACTION_TYPES.LOGIN_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || "",
        error: action?.payload?.data || "",
      };

    case ACTION_TYPES.GET_TWIL_PASS_SUCCESS:
      return {
        ...state,
        twilioUrl: action.payload?.return_data?.twillio_url,
      };

    case ACTION_TYPES.GET_TWIL_PASS_ERROR:
      return {
        ...state,
        twilioUrl: "",
      };

    case ACTION_TYPES.AUTHENTICATE_USER:
      return {
        ...state,
        isLoggedIn: true,
        // token: action.payload
        user: action.payload,
      };

    case ACTION_TYPES.AUTHENTICATE_FAILED:
      console.log("-------------------call---------------------");
      localStorage.removeItem("authToken");
      // localStorage.removeItem("user_name");
      localStorage.removeItem("user");
      localStorage.removeItem("email");
      return {
        ...state,
        isLoggedIn: false,
        token: "",
      };

    case ACTION_TYPES.USER_IMAGES_SUCCESS:
      return {
        ...state,
        loggedinUserImgs: {
          logo: action.payload.return_data.image,
          doctor_image: action.payload.return_data.doctor_image
        },
      }

    case ACTION_TYPES.USER_IMAGES_ERROR:
      return {
        ...state,
        message: action?.payload?.data?.message || '',
        error: action?.payload?.data || ''
      }

    default:
      return state;
  }
};

export default LoginReducer;
