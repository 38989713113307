import React from 'react'

const IconMinus = ({
    className
}) => {
    return (
        <svg className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 20 20" style={{ enableBackground: "new 0 0 20 20" }}>
            <path className="IconMinus2" d="M14,10c0,0.5-0.5,1-1,1H7c-0.5,0-1-0.5-1-1s0.5-1,1-1h6C13.5,9,14,9.5,14,10z" />
            <path className="IconMinus2" d="M0,10C0,4.5,4.5,0,10,0c2.7,0,5.2,1.1,7.1,2.9C18.9,4.8,20,7.3,20,10c0,5.5-4.5,10-10,10C4.5,20,0,15.5,0,10z
	 M2,10c0,4.4,3.6,8,8,8c2.1,0,4.2-0.8,5.7-2.3c1.5-1.5,2.3-3.5,2.3-5.7c0-4.4-3.6-8-8-8C5.6,2,2,5.6,2,10z"/>
        </svg>
    )
}
export default IconMinus;