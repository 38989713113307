import React, { Component } from "react"
import * as THREE from "three"
import Stats from 'three/examples/jsm/libs/stats.module'
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import IconReset from "../../svg/IconReset"

let controls
export default class ThreeDView extends Component {

    constructor(props) {
        super(props)
        this.reset = this.reset.bind(this)
    }

    componentDidMount() {
        let container;
        let camera, scene, renderer;


        document.getElementById(this.props.isFullView ? 'threeDcanvasFull' : 'threeDcanvas').innerHTML = "";
        container = document.getElementById(this.props.isFullView ? 'threeDcanvasFull' : 'threeDcanvas');

        // console.log("container.clientWidth", container.clientWidth, "container.clientHeight", container.clientHeight)

        renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true, preserveDrawingBuffer: true });
        renderer.setPixelRatio(window.devicePixelRatio);

        (container.clientWidth && container.clientHeight) ? renderer.setSize(container.clientWidth, container.clientHeight) : renderer.setSize(398, 508)

        container.appendChild(renderer.domElement);

        var fov = 2.7
        scene = new THREE.Scene();
        scene.background = new THREE.Color(0x000000);

        camera = new THREE.PerspectiveCamera(fov, 1.5, 1, 500);
        camera.position.set(2, 4, -45);

        var loader = new PLYLoader();
        var that = this
        var plyFile = this.props.ply
        // var imagePNG = plyFile.replace(".ply", ".png")
        var imagePNG = this.props.image_png;

        loader.load(plyFile, function (geometry) {
            geometry.computeVertexNormals();
            geometry.center();

            var texture = new THREE.TextureLoader().load(imagePNG);
            var material = new THREE.MeshBasicMaterial({ map: texture, transparent: true });
            var mesh = new THREE.Mesh(geometry, material);
            mesh.rotateZ(Math.PI);

            controls = new OrbitControls(camera, renderer.domElement)
            controls.minPolarAngle = 0; // radians
            controls.maxPolarAngle = Math.PI; // radians
            controls.minAzimuthAngle = Math.PI * (100 / 180);
            controls.maxAzimuthAngle = -Math.PI * (100 / 180);

            controls.update();

            scene.add(mesh);
            that.props.toggleLoading(false)
            // render()
        }, function (xhr) {
            that.props.loadedPLY(xhr.loaded / xhr.total * 100)
            if (xhr.loaded === xhr.total) {
                setTimeout(() => render(), 3000)
            }
            // console.log((xhr.loaded / xhr.total * 100) + '% loaded');

        });

        var stats = new Stats();
        window.addEventListener('resize', onWindowResize, false);

        function onWindowResize() { camera.aspect = 1; camera.updateProjectionMatrix(); }
        function animate(now) { requestAnimationFrame(animate); render(); stats.update(); }
        function render() { renderer.render(scene, camera) }
        // eslint-disable-next-line
        function resize() {
            // update the size
            renderer.setSize(window.innerWidth, window.innerHeight)
            // update the camera
            const canvas = renderer.domElement
            camera.aspect = canvas.clientWidth / canvas.clientHeight
            camera.updateProjectionMatrix()
        }
        animate(0);
    }
    reset() {
        if (controls) controls.reset()
    }
    render() {
        return (
            <>
                <div id={`${this.props.isFullView ? 'threeDcanvasFull' : 'threeDcanvas'}`} style={{
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    width: '100%',
                    height: '100%'
                }}></div>
                {!this.props.isLoading3D && <a href="/" className="icon-chart patients-reset" onClick={e => { e.preventDefault(); this.reset() }}>
                    <IconReset className="icon-reset" />
                </a>}
            </>
        )
    }
}