import React, { useState, useEffect, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import CloseIcon from "@material-ui/icons/Close";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Button } from "@material-ui/core";
import { reduxForm, Field, reset } from "redux-form";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";

import { toAbsoluteUrl, purposeContact } from "../../utils";
import { renderSelectField, renderTextField } from "./../FormField/index";
import { validate } from "./../validate";
import { contactUs } from "../../store/Home/Actions";
import { country } from "../../utils/countries";

import style from "../../scss/component/footer.module.scss";
import "../../scss/component/modal.scss";

let Footer = ({ handleSubmit, dispatch, pristine, submitting, contactUs, componentView }) => {
  const [shouldUpdate, setShouldUpdate] = useState(true);
  const [isRecaptcha, setIsRecaptcha] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState();
  const [width, setWidth] = useState(window.innerWidth);
  const [formDilog, setFormDilog] = useState(false);
  const [thanksDilog, setThanksDilog] = useState(false);

  const recaptchaRef = useRef();

  const width1024 = 1024;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  });

  //formreset
  const handlereset = () => {
    dispatch(reset("contact-dialog"));
  };

  const handleForm = (values) => {
    contactUs({ ...values, shouldUpdate, recaptchaValue, type: "contact_us" })
      .then((res) => {
        if (res.success) {
          setFormDilog(false);
          setThanksDilog(true);
          handlereset();
        } else {
          // toastr.error('Error', "Please try again.");
          console.log("Please try again.");
        }
      })
      .catch((err) => {
        toastr.error("Error", err.response.data.msg);
      });
  };

  return (
    <>
      <footer className={`${style.section} d-flex flex-column text-center 
      ${componentView ? `${style.woundfooter}` : ''}`}>
        <div
          className={`${style.logo} d-flex flex-wrap justify-content-center`}
        >
          <a
            href={process.env.REACT_APP_WEB_URL}
            target="_blank"
            rel="noreferrer"
          >
            <picture>
              <img
                src={toAbsoluteUrl("/images/brand-logo-footer.png")}
                alt="Shades of Things"
              />
            </picture>
          </a>
        </div>
        <nav className={`${style.nav} `}>
          <div
            className={`${style.container} d-flex flex-wrap justify-content-between align-items-center`}
          >
            <ul className="d-flex flex-wrap justify-content-center">
              <li>
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    setFormDilog(true);
                  }}
                >
                  Contact Us
                </a>
              </li>
              <li>
                                <a
                  href={`${process.env.REACT_APP_WEB_URL}privacy-policy`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </li>
              <li>
                <a
                  href={`${process.env.REACT_APP_WEB_URL}terms-conditions`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms & Conditions
                </a>
              </li>
            </ul>
            {width > width1024 && (
              <p className={style.copyright}>
                Copyright {new Date().getFullYear()} Shades of Things Pte. Ltd.
              </p>
            )}
          </div>
        </nav>
        {width <= width1024 && (
          <p className={style.copyright}>
            Copyright {new Date().getFullYear()} Shades of Things Pte. Ltd.
          </p>
        )}
      </footer>

      {/* Dialog Contact */}
      <Dialog
        onClose={() => setFormDilog(false)}
        open={formDilog}
        className="dialog-contact-wrap"
      >
        <form onSubmit={handleSubmit(handleForm)}>
          <div className="dialog-contact">
            <IconButton
              aria-label="close"
              onClick={() => {
                setFormDilog(false);
                handlereset();
              }}
              className="dialog-close"
            >
              <CloseIcon />
            </IconButton>
            <div className="dialog-header">
              <h2>CONTACT US</h2>
            </div>
            <div className="dialog-body">
              <Grid container spacing={2}>
                {/* <Field
                                    name="title"
                                    fieldName="Title"
                                    displayEmpty={true}
                                    component={renderSelectField}
                                    variant="outlined"
                                    fullWidth={true}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    xs={12}
                                    md={4}
                                    options={title}
                                /> */}
                <Field
                  name="firstname"
                  component={renderTextField}
                  placeholder="First Name"
                  variant="outlined"
                  fullWidth={true}
                  xs={12}
                  md={6}
                />
                <Field
                  name="lastname"
                  component={renderTextField}
                  placeholder="Last Name"
                  variant="outlined"
                  fullWidth={true}
                  xs={12}
                  md={6}
                />
                <Field
                  name="email"
                  component={renderTextField}
                  placeholder="Email Address"
                  variant="outlined"
                  fullWidth={true}
                  xs={12}
                />
                <Field
                  name="country"
                  fieldName="Country"
                  displayEmpty={true}
                  component={renderSelectField}
                  variant="outlined"
                  fullWidth={true}
                  inputProps={{ "aria-label": "Without label" }}
                  xs={12}
                  md={6}
                  options={country}
                />
                <Field
                  name="purposeOfContact"
                  fieldName="Purpose Of Contact"
                  displayEmpty={true}
                  component={renderSelectField}
                  variant="outlined"
                  fullWidth={true}
                  inputProps={{ "aria-label": "Without label" }}
                  xs={12}
                  md={6}
                  options={purposeContact}
                />
                <Field
                  name="message"
                  component={renderTextField}
                  placeholder="Message"
                  variant="outlined"
                  fullWidth={true}
                  multiline={true}
                  rows={4}
                  xs={12}
                />
                <Grid item xs={12} className="grid-col" md={12}>
                  <input
                    id="0"
                    className="form-check"
                    type="checkbox"
                    defaultChecked={shouldUpdate}
                  />
                  <label
                    htmlFor="0"
                    className={`form-label d-flex flex-wrap`}
                    onClick={() => setShouldUpdate(!shouldUpdate)}
                  >
                    <span className={`checkbox`}></span>
                    <span className="text flex-1" style={{ color: "black" }}>
                      Sign me up for the latest updates.
                    </span>
                  </label>
                </Grid>
                <Grid item xs={12} className="grid-col" md={12}>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    className="form-captcha"
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    onChange={(v) => {
                      setRecaptchaValue(v);
                      setIsRecaptcha(true);
                    }}
                  />
                </Grid>
              </Grid>
              <div className="dialog-footer">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="btn-primary"
                  size="large"
                  disabled={pristine || submitting || !isRecaptcha}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Dialog>

      {/* Dialog Thank you */}
      <Dialog
        onClose={() => {
          setThanksDilog(false);
        }}
        open={thanksDilog}
        className="dialog-contact-wrap"
      >
        <div className="dialog-contact dialog-thank-you">
          <IconButton
            aria-label="close"
            onClick={() => {
              setThanksDilog(false);
            }}
            className="dialog-close"
          >
            <CloseIcon />
          </IconButton>
          <div className="dialog-header">
            <h2>CONTACT US</h2>
          </div>
          <div className="dialog-body">
            <p>
              Thank you for expressing your interest. We will get back to you
              shortly.
            </p>
          </div>
        </div>
      </Dialog>
    </>
  );
};

Footer = reduxForm({
  form: "contact-dialog",
  validate,
})(Footer);

const mapDispatchToProps = (dispatch) => ({
  contactUs: (data) => dispatch(contactUs(data)),
});

Footer = connect(null, mapDispatchToProps)(Footer);

export default Footer;
