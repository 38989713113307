import React from 'react'

const IconPlus = ({
    className
}) => {
    return (
        <>
            <svg className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 75 75" style={{ enableBackground: "new 0 0 75 75" }}>
                <path className="IconPlus1" d="M0,37.5C0,16.8,16.8,0,37.5,0C47.4,0,57,4,64,11s11,16.6,11,26.5C75,58.2,58.2,75,37.5,75S0,58.2,0,37.5z
	 M7.5,37.5c0,16.6,13.4,30,30,30c8,0,15.6-3.2,21.2-8.8s8.8-13.3,8.8-21.2c0-16.6-13.4-30-30-30S7.5,20.9,7.5,37.5z"/>
                <path className="IconPlus2" d="M48.8,33.8h-7.5v-7.5c0-2.1-1.7-3.8-3.8-3.8s-3.8,1.7-3.8,3.8v7.5h-7.5c-2.1,0-3.8,1.7-3.8,3.8s1.7,3.8,3.8,3.8
	h7.5v7.5c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8v-7.5h7.5c2.1,0,3.8-1.7,3.8-3.8S50.8,33.8,48.8,33.8z"/>
            </svg>
        </>
    )
}
export default IconPlus;