import axios from 'axios'
import * as ACTION_TYPES from './Types'
import { API_BASE } from '../../config/AppConstant';

const validatedRespose = (res) => res?.data || { return_data: [] };

//To Join Us
export const joinUs = data => dispatch => {
  return axios
    .post(`${API_BASE}SendEmail`, data)
    .then(res => {
      dispatch({ type: ACTION_TYPES.JOIN_US_SUCCESS, payload: res.data })
      return res.data
    })
    .catch((err) => {
      dispatch({ type: ACTION_TYPES.JOIN_US_ERROR, payload: err.response })
      throw err
    })
}

//To Contact Us
export const contactUs = data => dispatch => {
  return axios
    .post(`${API_BASE}SendEmail`, data)
    .then(res => {
      dispatch({ type: ACTION_TYPES.CONTACT_US_SUCCESS, payload: res.data })
      return res.data
    })
    .catch((err) => {
      dispatch({ type: ACTION_TYPES.CONTACT_US_ERROR, payload: err.response })
      throw err
    })
}

export const resetPatientPassword = (data) => () => {
  return axios
    .post(`${API_BASE}patient/auth/resetPatientPassword`, data)
    .then((res) => {
      return validatedRespose(res);
    })
    .catch((err) => {
      throw err;
    });
};

export const forceResetPassword = (data) => () => {
  return axios
    .post(`${API_BASE}api/change${data.doctorId?`Doc`:`Hosp`}Password`, data)
    .then((res) => {
      return validatedRespose(res);
    })
    .catch((err) => {
      throw err;
    });
};

export const forgotPassword = (data) => () => {
  return axios
    .post(`${API_BASE}patient/auth/forgotPassword`, data)
    .then((res) => {
      return validatedRespose(res);
    })
    .catch((err) => {
      throw err;
    });
};