import { valuesIn } from "lodash";

export const validate = (values) => {
  // console.log('values: ', values);
  let error = {};

  if (!values.title) {
    error.title = "Please select title.";
  }
  if (!values.close_reason) {
    error.close_reason = "Please select reason.";
  }
  if (!values.purposeOfContact) {
    error.purposeOfContact = "Please select purpose of contact.";
  }
  if (!values.user_id) {
    error.user_id = "Please enter a valid username";
  } else {
    values.user_id = values.user_id.replace(/ /g, "");
  }
  if (!values.name || !values.name.trim()) {
    error.name = "Please enter medicine name";
  }
  if (!values.password) {
    error.password = "Please enter a valid password";
  } else {
    values.password = values.password.replace(/ /g, "");
  }
  if (!values.reset_password || / /g.test(values.reset_password)) {
    error.reset_password = "Please enter a valid password";
  } else if (values.reset_password.length < 5) {
    error.reset_password = "Password has at least 5 characters.";
  }

  if (!values.confirm_password || / /g.test(values.confirm_password)) {
    error.confirm_password = "Please enter a valid password";
  }

  if (values.reset_password !== values.confirm_password) {
    error.confirm_password = "This field needs to match the one above it";
  }

  if (!values.firstname || !values.firstname.trim()) {
    error.firstname = "Please enter first name";
  } else if (!/^[a-zA-Z ]{2,30}$/i.test(values.firstname)) {
    error.firstname = "Please enter valid first name";
  }
  if (!values.lastname || !values.lastname.trim()) {
    error.lastname = "Please enter last name";
  } else if (!/^[a-zA-Z ]{2,30}$/i.test(values.lastname)) {
    error.lastname = "Please enter valid last name";
  }
  if (!values.organizationName || !values.organizationName.trim()) {
    error.organizationName = "Please enter organization name";
  }
  if (!values.jobTitle || !values.jobTitle.trim()) {
    error.jobTitle = "Please enter job title";
  }
  if (!values.email || !values.email.trim()) {
    error.email = "Please enter the username.";
  } else {
    values.email = values.email.replace(/ /g, "");
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email) &&
    !/^\d+(?:-\d+)*$/.test(values.email) ) {
      error.email = "Invalid username.";
    }
  }

  if (!values.country) {
    error.country = "Please select country";
  }
  if (!values.comment || !values.comment.trim()) {
    error.comment = "Please enter detail";
  }
  if (!values.message || !values.message.trim()) {
    error.message = "Please enter message";
  }
  if (!values.designation || !values.designation.trim()) {
    error.designation = "Please enter designation";
  }
  if (!values.numberOfParticipants) {
    error.numberOfParticipants = "Please select number of participants";
  }
  if (!values.date) {
    error.date = "Please select date";
  }

  if (!/[^0-9.]/g.test(values.wound_height)) {
    error.Patient_Name = "Please enter valid number.";
  }

  if (!values.Patient_Name || !values.Patient_Name.trim()) {
    error.Patient_Name = "Please enter your full name";
  }

  // if (!values.p_email || !values.p_email.trim()) {
  //   error.p_email = "Please enter email.";
  // } else
  if (values.p_email) {
    values.p_email = values.p_email.replace(/ /g, "");
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.p_email)) {
      error.p_email = "Invalid email address.";
    }
  }

  // if (!values.address) {
  //   error.address = "Please enter your address";
  // }

  // if (!values.phonenumber) {
  //   error.phonenumber = "Please enter your phone number";
  // }

  if (!values.doctor_name) {
    error.doctor_name = "Please enter your name";
  }

  if (!values.dr_login_id) {
    error.dr_login_id = "Please enter email";
  } else {
    values.dr_login_id = values.dr_login_id.replace(/ /g, "");
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.dr_login_id)) {
      error.dr_login_id = "Invalid email address";
    }
  }

  // if(!values.height){
  //     error.height = " Please select height."
  // }

  // if(!values.weight){
  //     error.weight = "Please select weight."
  // }

  // if(!values.heart_rate){
  //     error.heart_rate = "Please select heart rate."
  // }

  // if(!values.body_temp){
  //     error.body_temp = "Please select body temperature."
  // }

  // if(!values.blood_group){
  //     error.blood_group = "Please select blood group."
  // }

  // if(!values.SugarLevel){
  //     error.SugarLevel = "Please select sugar level."
  // }

  // if(!values.systol){
  //     error.systol = "Please select blood pressure (systolic)."
  // }

  // if(!values.diastole){
  //     error.diastole = "Please select blood pressure (diastolic)."
  // }

  if (!values.wound_type) {
    error.wound_type = "Please select wound type";
  }

  if (!values.wound_type2) {
    error.wound_type2 = "Please select sub-wound type";
  }

  if (!values.wound_side) {
    error.wound_side = "Please select wound side";
  }

  if (!values.wound_location) {
    error.wound_location = "Please select wound location";
  }

  if (!values.wound_reason) {
    // error.wound_reason = "Please enter wound cause.";
  }

  if (!values.appointment_title) {
    error.appointment_title = "Please enter the title";
  }

  if (!values.room_name) {
    error.room_name = "Please enter the room name";
  }

  if (!values.first_name) {
    error.first_name = "Please enter the first name.";
  }

  if (!values.last_name) {
    error.last_name = "Please enter the last name.";
  }

  if (!values.specialization) {
    error.specialization = "Please enter the specialization.";
  }

  if (!values.country) {
    error.country = "Please select the country.";
  }

  if (!values.dressingName || !values.dressingName.trim()) {
    error.dressingName = "Please enter dressing name";
  }
  if (!values.brand || !values.brand.trim()) {
    error.brand = "Please enter brand name";
  }
  if (!values.regNumber || !values.regNumber.trim()) {
    error.regNumber = "Please enter registeration number";
  }
  if (!values.treatmentId) {
    error.treatmentId = "Please select treatment";
  }

  if (!values.strength) {
    error.strength = "Please select strength.";
  }

  if (!values.unit) {
    error.unit = "Please enter unit";
  }

  if (!values.form) {
    error.form = "Please select form";
  }

  if (!values.route) {
    error.route = "Please select route";
  }

  if (values.route && values.route.value == "Other" && !values.other_route) {
    error.other_route = "Please enter other route";
  }

  if (!values.drug_allergy || !values.drug_allergy.trim()) {
    error.drug_allergy = "Please enter drug allergy";
  }

  if (!values.food_allergy || !values.food_allergy.trim()) {
    error.food_allergy = "Please enter food allergy";
  }

  // if (!values.tunnel_hour) {
  //   error.tunnel_hour = "Location required.";
  // }

  // if(!values.tunnel_minute){
  //   error.tunnel_minute = "Location required.";
  // }

  // if(!values.wound_depth_tunnel){
  //   error.wound_depth_tunnel = "Depth required.";
  // }

  if (!values.under_hour) {
    error.under_hour = "Location required.";
  }

  if (!values.under_minute) {
    error.under_minute = "Location required.";
  }

  if (!values.language_name) {
    error.language_name = "Language name required.";
  }

  if (!values.translated_name) {
    error.translated_name = "Translated language name required.";
  }

  if (!values.version_number) {
    error.version_number = "Version number required.";
  }

  // if(!values.json_file){
  //   error.json_file = "Select Json File.";
  // }

  // if(!values.flag_image){
  //   error.flag_image = "Select Flag Image.";
  // }

  if (!values.admin_name) {
    error.admin_name = "Admin Name is required."
  }

  if (!values.admin_number) {
    error.admin_number = "Admin Contact is required."
  }

  if (!values.admin_email) {
    error.admin_email = "Admin Email is required."
  }

  return error;
};
