import axios from "axios";
import * as ACTION_TYPES from "./Types";
import { API_BASE } from "../../config/AppConstant";

//To reAuthenticate if the cookie is not expired
export const authenticate = (user) => (dispatch) => {
  if (!user.user_role) {
    return dispatch({ type: ACTION_TYPES.AUTHENTICATE_FAILED });
  }
  if (user) {
    return dispatch({ type: ACTION_TYPES.AUTHENTICATE_USER, payload: user });
  } else {
    return dispatch({ type: ACTION_TYPES.AUTHENTICATE_FAILED });
  }
};

//To Login
export const login = (user) => (dispatch) => {
  return axios
    .post(`${API_BASE}Login_Email_Id`, { ...user,isFaceLogin:false })
    .then((res) => {
      // set access token for rest of the api
      axios.defaults.headers.common["Authorization"] = res.data.token;

      dispatch({ type: ACTION_TYPES.LOGIN_SUCCESS, payload: res.data });
      return res.data;
    })
    .catch((err) => {
      dispatch({ type: ACTION_TYPES.LOGIN_ERROR, payload: err.response });
      throw err;
    });
};

// To Twilio passcode
export const getTwilioPasscode = (user) => (dispatch) => {
  return axios
    .get(`${API_BASE}api/getSettings`)
    .then((res) => {
      dispatch({ type: ACTION_TYPES.GET_TWIL_PASS_SUCCESS, payload: res.data });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.GET_TWIL_PASS_ERROR,
        payload: err.response,
      });
      throw err;
    });
};

//logo and profile img
export const getLoggedinUserImages = (data) => (dispatch) => {
  return axios
    .post(`${API_BASE}api/getDocAndHosImages`,data)
    .then((res) => {
      dispatch({
        type: ACTION_TYPES.USER_IMAGES_SUCCESS,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      dispatch({
        type: ACTION_TYPES.USER_IMAGES_ERROR,
        payload: err.response,
      });
      throw err;
    });
}
