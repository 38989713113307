import React, { useEffect, useState } from "react";
import { FormGroup, Input, InputGroup, Label } from "reactstrap";
import {
  TextField,
  Grid,
  MenuItem,
  FormControl,
  Select as MaterialSelect,
} from "@material-ui/core";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import IconCalender from "../svg/IconCalender";
import ReactDatePicker from "react-datepicker";
import IconMinus from "../svg/IconMinus";
import IconPlus from "../svg/IconPlus";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { countryCodes } from "../../utils";
import dayjs from "dayjs";
import ReactSignatureCanvas from "react-signature-canvas";
import { NumericFormat } from 'react-number-format'; //PatternFormat
var customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat);

export const renderInput = ({
  input,
  label,
  inputlabel,
  type,
  isDisabled,
  step,
  defaultValue,
  meta: { touched, error, warning },
}) => (
  <FormGroup>
    {inputlabel && <Label>{inputlabel}</Label>}
    <InputGroup>
      { }
      <Input
        {...input}
        type={type}
        step={step}
        placeholder={label}
        disabled={isDisabled}
        value={defaultValue ? defaultValue : input.value}
      />
    </InputGroup>
    <div style={{ color: "red", marginTop: "2px" }}>
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}{" "}
    </div>
  </FormGroup>
);


export const renderNumber = ({
  input,
  inputlabel,
  type,
  icon,
  name,
  defaultValue,
  meta: {
    touched,
    error,
    warning
  }
}) => {
  return (
    <FormGroup>
      {inputlabel && <Label>{inputlabel}</Label>}
      <InputGroup>
        <NumericFormat customInput={Input} {...input} decimalScale={2} value={defaultValue ? defaultValue : input.value} />
        {/* <Input {...input} type='number' defaultValue={input.value} placeholder={label} /> */}
      </InputGroup>
      <div className="text-error text-danger mt-5">
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </FormGroup>
  )
}

export const tissueNumber = ({
  input,
  name,
  defaultValue,
  className,
  disabled,
  meta: {
    touched,
    error,
    warning
  }
}) => {
  return (
    <>
      <InputGroup>
        {/* <PatternFormat */}
        <NumericFormat
          disabled={disabled}
          customInput={Input}
          {...input}
          format="###"
          value={defaultValue ? defaultValue : input.value}
          className={className}
          decimalScale={0}
        />
      </InputGroup>
      <div className="text-danger">
        {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
      </div>
    </>
  )
}


export const renderTextField = ({
  input,
  fullWidth,
  variant,
  placeholder,
  multiline,
  rows,
  xs,
  md,
  meta: { touched, error, warning },
}) => (
  <Grid item xs={xs} className="grid-col" md={md}>
    <TextField
      {...input}
      placeholder={placeholder}
      variant={variant}
      fullWidth={fullWidth}
      multiline={multiline}
      rows={rows}
    />
    <div style={{ color: "red", marginTop: "2px" }}>
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}{" "}
    </div>
  </Grid>
);

export const renderSelectField = ({
  input,
  fieldName,
  fullWidth,
  variant,
  displayEmpty,
  xs,
  md,
  inputProps,
  options,
  meta: { touched, error, warning },
}) => (
  <Grid item xs={xs} md={md} className="grid-col">
    <FormControl variant={variant} fullWidth={fullWidth}>
      <MaterialSelect
        {...input}
        displayEmpty={displayEmpty}
        inputProps={inputProps}
      >
        <MenuItem value="" disabled>
          {fieldName}
        </MenuItem>
        {options.map((o) => (
          <MenuItem value={o.value} disabled={o.disabled}>{o.label}</MenuItem>
        ))}
      </MaterialSelect>
    </FormControl>
    <div style={{ color: "red", marginTop: "2px" }}>
      {" "}
      {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))}{" "}
    </div>
  </Grid>
);


export const SelectField4 = ({
  formLabel,
  input,
  formClass,
  options,
  placeholder,
  menuPlacement,
  defaultValue,
  onChangeProps,
  isRedux,
  isDisabled,
  zindex,
  position,
  style,
  meta: { touched, error },
  dropdownMessage,
  isMultiple,
}) => {
  return (
    <li className={`form-group${formClass} ${touched && error && "error"}`}>
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div
        className="form-block"
        style={{ zIndex: zindex, position: position }}
      >
        {defaultValue ? (
          <Select
            {...input}
            options={options}
            value={defaultValue}
            onChange={(value) => {
              input.onChange(value);
              if (onChangeProps) {
                onChangeProps(value);
              }
            }}
            isDisabled={isDisabled}
            onBlur={() => input.onBlur()}
            onFocus={() => input.onFocus()}
            placeholder={placeholder}
            isMulti={isMultiple ? true : false}
            menuPlacement={menuPlacement}
            noOptionsMessage={() => dropdownMessage && dropdownMessage}
            isOptionDisabled={(option) => option.disabled}
          />
        ) : (
          <Select
            {...input}
            options={options}
            onChange={(value) => {
              input.onChange(value);
              if (onChangeProps) {
                onChangeProps(value);
              }
            }}
            onBlur={() => input.onBlur()}
            onFocus={() => input.onFocus()}
            placeholder={placeholder}
            isMulti={isMultiple ? true : false}
            menuPlacement={menuPlacement}
            noOptionsMessage={() => dropdownMessage && dropdownMessage}
            isOptionDisabled={(option) => option.disabled}
          />
        )}
      </div>
      <div className="text-error text-danger mt-5" style={style}>
        {touched && error && error}
      </div>
    </li>
  );
};

export const InputField = ({
  formLabel,
  input,
  formClass,
  type,
  placeholder,
  disabled,
  readOnly,
  maxLength,
  err,
  defaultValue,
  icons,
  meta: { touched, error },
}) => {
  return (
    <li
      className={`form-group${formClass} ${err && "error"}  ${touched && error && "error"
        } `}
    >
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div className={`form-block ${icons}`}>
        <input
          {...input}
          className="form-control"
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          value={defaultValue ? defaultValue : input.value}
          readOnly={readOnly}
          maxLength={maxLength && maxLength}
          min={0}
        />
      </div>
      <div className="text-error text-danger mt-2">{err && err}</div>
      <div className="text-error text-danger mt-2">
        {touched && error && error}
      </div>
    </li>
  );
};

export const InputField2 = ({
  formLabel,
  formClass,
  formValue,
  err,
  meta: { touched, error },
}) => {
  return (
    <li
      className={`form-group${formClass}  ${touched && error && "error"
        } `} style={{
          display: "flex"
        }}
    >
      <div>
        {formLabel && <label className="form-title" style={{ marginTop: "7px" }}><i>{formLabel}</i></label>}
        {formValue && <label className="form-title" style={{ marginLeft: "15px", marginTop: "7px", color: "#36B4B0" }}>{formValue}</label>}
      </div>
      <div className="text-error text-danger ">{err && err}</div>
      <div className="text-error text-danger ">
        {touched && error && error}
      </div>
    </li>
  );
};



export const ViewField = ({
  formLabel,
  formClass,
  formValue,
  defaultValue,
  divclass,
  line
}) => {
  return (
    <>
      {
        line ? (<li
          className={`form-group `} style={{
            display: "flex"
          }} >
          <div className={divclass} >
            {formLabel && <label className="form-title" style={{ marginTop: "4px" }}><i>{formLabel}</i></label>}
          </div>
          <div>
            <label className="form-title" style={{ marginTop: "4px", marginLeft: "10px", color: "#4d6065" }}>{formValue}</label>
          </div>
        </li >) :
          (<li
            className={`form-group`} >
            <div>
              {formLabel && <label className="form-title" style={{ marginTop: "7px" }}><i>{formLabel}</i></label>}
            </div>
            <div>
              {formValue && <label className="form-title" style={{ marginTop: "7px", color: "#4d6065" }}>{formValue}</label>}
            </div>
          </li>)
      }
    </>
  );
};

export const FileInputField = ({
  formLabel,
  input,
  formClass,
  type,
  selectedFile,
  placeholder,
  disabled,
  readOnly,
  maxLength,
  err,
  defaultValue,
  ref,
  meta: { touched, error },
}) => {
  return (
    <li
      className={`form-group${formClass} ${err && "error"}  ${touched && error && "error"
        } `}
    >
      {formLabel && <label className="form-title">{formLabel}</label>}
      {/* {selectedFile.src &&
                <figure>
                    <img src={selectedFile.src} alt={selectedFile.alt} height="80px" />
                </figure>
            } */}
      <div className="form-block">
        <input
          {...input}
          className="form-control"
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          // value={defaultValue ? defaultValue : input.value}
          value={undefined}
          readOnly={readOnly}
          accept="image/jpeg,image/png,image/jpg"
          maxLength={maxLength && maxLength}
          ref={ref}
        />
      </div>
      <div className="text-error text-danger">{err && err}</div>
      <div className="text-error text-danger">{touched && error && error}</div>
    </li>
  );
};

export const PasswordField = ({
  input,
  formLabel,
  formClass,
  type,
  placeholder,
  disabled,
  readOnly,
  err,
  meta: { touched, error },
}) => {
  // const [error, setError] = useState({});
  const [values, setValues] = useState({
    user_id: "",
    password: "",
    showPassword: false,
  });

  // const handleChange = (prop) => (event) => {
  //     setValues({ ...values, [prop]: event.target.value });
  //     event.target.value && setError({ ...error, [prop]: "" })
  // };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    // ${(touched && error) && 'error'}
    <li
      className={`form-group${formClass} ${err && "error"} ${touched && error && "error"
        }`}
    >
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div className="form-block">
        <input
          {...input}
          className="form-control"
          type={values.showPassword ? "text" : "password"}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
        // value={values.password}
        // onChange={handleChange('password')}
        />
        <button
          className="btn-password"
          type="button"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
        >
          {values.showPassword ? <VisibilityOff /> : <Visibility />}
        </button>
      </div>
      <div className="text-error text-danger mt-2">{err && err}</div>
      <div className="text-error text-danger mt-2">
        {touched && error && error}
      </div>
    </li>
  );
};

export const TextareaField = ({
  formLabel,
  input,
  formClass,
  rows,
  maxLength,
  placeholder,
  onChangeProps,
  defaultValue,
  meta: { touched, error },
}) => {
  return (
    <li className={`form-group${formClass} ${touched && error && "error"}`}>
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div className="form-block">
        {defaultValue ? (
          <textarea
            {...input}
            rows={rows}
            onChange={(value) => {
              input.onChange(value);
              if (onChangeProps) {
                onChangeProps(value);
              }
            }}
            maxLength={maxLength}
            value={defaultValue}
            className="form-control"
            placeholder={placeholder}
          ></textarea>
        ) : (
          <textarea
            {...input}
            rows={rows}
            onChange={(value) => {
              input.onChange(value);
              if (onChangeProps) {
                onChangeProps(value);
              }
            }}
            maxLength={maxLength}
            className="form-control"
            placeholder={placeholder}
          ></textarea>
        )}
      </div>
      <div className="text-error text-danger">{touched && error && error}</div>
    </li>
  );
};

export const SignatureField = ({
  formLabel,
  input,
  formClass,
  rows,
  placeholder,
  defaultValue,
  meta: { touched, error },
}) => {
  return (
    <li className={`form-group${formClass} ${touched && error && "error"}`}>
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div className="form-block">
        {defaultValue ? (
          <ReactSignatureCanvas
            penColor="black"
            {...input}
            canvasProps={{ className: "signature-box" }}
          />
        ) : (
          <ReactSignatureCanvas
            penColor="black"
            {...input}
            onChange={input.onChange}
            onEnd={input.onEnd}
            canvasProps={{ className: "signature-box" }}
          />
        )}
      </div>
      <div className="text-error text-danger">{touched && error && error}</div>
    </li>
  );
};

export const SelectField = ({
  formLabel,
  input,
  formClass,
  options,
  placeholder,
  menuPlacement,
  defaultValue,
  onChangeProps,
  isRedux,
  isDisabled,
  zindex,
  position,
  style,
  meta: { touched, error },
  dropdownMessage,
  isMultiple,
}) => {
  return (
    <li className={`form-group${formClass} ${touched && error && "error"}`}>

      {formLabel && <label className="form-title">{formLabel}</label>}
      <div
        className="form-block"
        style={{ zIndex: zindex, position: position }}
      >
        {defaultValue ? (
          <Select
            {...input}
            options={options}
            value={defaultValue}
            onChange={(value) => {
              input.onChange(value);
              if (onChangeProps) {
                onChangeProps(value);
              }
            }}
            isDisabled={isDisabled}
            onBlur={() => input.onBlur()}
            onFocus={() => input.onFocus()}
            placeholder={placeholder}
            isMulti={isMultiple ? true : false}
            menuPlacement={menuPlacement}
            noOptionsMessage={() => dropdownMessage && dropdownMessage}
          />
        ) : (
          <Select
            {...input}
            options={options}
            onChange={(value) => {
              input.onChange(value);
              if (onChangeProps) {
                onChangeProps(value);
              }
            }}
            onBlur={() => input.onBlur()}
            onFocus={() => input.onFocus()}
            placeholder={placeholder}
            isMulti={isMultiple ? true : false}
            menuPlacement={menuPlacement}
            noOptionsMessage={() => dropdownMessage && dropdownMessage}
          />
        )}
      </div>
      <div className="text-error text-danger mt-5" style={style}>
        {touched && error && error}
      </div>
    </li>
  );
};

export const SelectAllField = ({
  formLabel,
  input,
  formClass,
  options,
  placeholder,
  menuPlacement,
  defaultValue,
  onChangeProps,
  isRedux,
  isDisabled,
  zindex,
  position,
  style,
  meta: { touched, error },
  dropdownMessage,
  isMultiple,
}) => {
  return (
    <li className={`form-group${formClass} ${touched && error && "error"}`}>

      {formLabel && <label className="form-title">{formLabel}</label>}
      <div
        className="form-block"
        style={{ zIndex: zindex, position: position }}
      >
        {defaultValue ? (
          <Select
            {...input}
            options={options}
            value={defaultValue}
            // onChange={(value) => {
            //   input.onChange(value);
            // if (onChangeProps) {
            //   onChangeProps(value);
            // }
            // }}
            onChange={selected => {
              input.onChange(selected);
              selected.length &&
                selected.find(option => option.value === "all")
                ? onChangeProps(options.slice(1))
                : onChangeProps(selected);
            }}
            isDisabled={isDisabled}
            onBlur={() => input.onBlur()}
            onFocus={() => input.onFocus()}
            placeholder={placeholder}
            isMulti={isMultiple ? true : false}
            menuPlacement={menuPlacement}
            noOptionsMessage={() => dropdownMessage && dropdownMessage}
          />
        ) : (
          <Select
            {...input}
            options={options}
            onChange={(value) => {
              input.onChange(value);
              if (onChangeProps) {
                onChangeProps(value);
              }
            }}
            onBlur={() => input.onBlur()}
            onFocus={() => input.onFocus()}
            placeholder={placeholder}
            isMulti={isMultiple ? true : false}
            menuPlacement={menuPlacement}
            noOptionsMessage={() => dropdownMessage && dropdownMessage}
          />
        )}
      </div>
      <div className="text-error text-danger mt-5" style={style}>
        {touched && error && error}
      </div>
    </li>
  );
};

export const SelectField2 = ({
  formLabel,
  input,
  formClass,
  options,
  placeholder,
  menuPlacement,
  defaultValue,
  onChangeProps,
  maxMenuHeight,
  className,
  isRedux,
  zindex,
  position,
  style,
  meta: { touched, error },
  dropdownMessage,
  isMultiple,
}) => {
  return (
    <li className={`form-group${formClass} ${touched && error && "error"}`}>
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div
        className="form-block"
        style={{ zIndex: zindex, position: position }}
      >
        {defaultValue ? (
          <Select
            {...input}
            options={options}
            maxMenuHeight={maxMenuHeight}
            value={defaultValue}
            onChange={(value) => {
              input.onChange(value);
              if (onChangeProps) {
                onChangeProps(value);
              }
            }}
            menuColor="green"
            className={className}
            onBlur={() => input.onBlur()}
            onFocus={() => input.onFocus()}
            placeholder={placeholder}
            isMulti={isMultiple ? true : false}
            menuPlacement={menuPlacement}
            isSearchable={false}
            noOptionsMessage={() => dropdownMessage && dropdownMessage}
          />
        ) : (
          <Select
            {...input}
            options={options}
            className={className}
            maxMenuHeight={maxMenuHeight}
            onChange={(value) => {
              input.onChange(value);
              if (onChangeProps) {
                onChangeProps(value);
              }
            }}
            onBlur={() => input.onBlur()}
            onFocus={() => input.onFocus()}
            placeholder={placeholder}
            isMulti={isMultiple ? true : false}
            menuPlacement={menuPlacement}
            isSearchable={false}
            noOptionsMessage={() => dropdownMessage && dropdownMessage}
          />
        )}
      </div>
      <div className="text-error text-danger mt-5" style={style}>
        {touched && error && error}
      </div>
    </li>
  );
};

const formatOptionLabel = ({ label, colorCode }) => (
  <div style={{ color: colorCode }}>{label}</div>
);

export const SelectField3 = ({
  formLabel,
  input,
  formClass,
  options,
  className,
  placeholder,
  menuPlacement,
  defaultValue,
  onChangeProps,
  maxMenuHeight,
  isRedux,
  zindex,
  position,
  style,
  meta: { touched, error },
  dropdownMessage,
  isMultiple,
}) => {
  return (
    <li className={`form-group${formClass} ${touched && error && "error"}`}>
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div
        className="form-block"
        style={{ zIndex: zindex, position: position }}
      >
        {defaultValue ? (
          <Select
            {...input}
            className={className}
            options={options}
            maxMenuHeight={maxMenuHeight}
            value={defaultValue}
            onChange={(value) => {
              input.onChange(value);
              if (onChangeProps) {
                onChangeProps(value);
              }
            }}
            formatOptionLabel={formatOptionLabel}
            onBlur={() => input.onBlur()}
            onFocus={() => input.onFocus()}
            placeholder={placeholder}
            isMulti={isMultiple ? true : false}
            menuPlacement={menuPlacement}
            isSearchable={false}
            noOptionsMessage={() => dropdownMessage && dropdownMessage}
          />
        ) : (
          <Select
            {...input}
            options={options}
            className={className}
            maxMenuHeight={maxMenuHeight}
            onChange={(value) => {
              input.onChange(value);
              if (onChangeProps) {
                onChangeProps(value);
              }
            }}
            onBlur={() => input.onBlur()}
            onFocus={() => input.onFocus()}
            placeholder={placeholder}
            isMulti={isMultiple ? true : false}
            menuPlacement={menuPlacement}
            isSearchable={false}
            noOptionsMessage={() => dropdownMessage && dropdownMessage}
          />
        )}
      </div>
      <div className="text-error text-danger mt-5" style={style}>
        {touched && error && error}
      </div>
    </li>
  );
};

export const CalenderField = ({
  formLabel,
  input,
  formClass,
  type,
  placeholder,
  disabled,
  readOnly,
  startDateProp,
  defaultValue,
  onChangeProps,
  ShowprevDate,
  startDateSelected,
  // meta: { touched, error }
}) => {
  const [startDate, setStartDate] = useState();
  const [dateInline, setDateInline] = useState(false);
  const handleDateChange = (date, e) => {
    setDateInline(!dateInline);
    let d = new Date(date);
    setStartDate(d.getTime());
    onChangeProps(Math.trunc(d.getTime() / 1000));
  };

  if (!startDate) {
    let d = new Date();
    setStartDate(d.getTime());
    onChangeProps(Math.trunc(d.getTime() / 1000));
  }

  const setDefaultDate = (v) => {
    let d = new Date(v * 1000);
    let new_date =
      ("0" + d.getDate()).slice(-2) +
      "/" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      d.getFullYear();
    return new_date;
  };

  return (
    // ${(touched && error) && 'error'}
    <li className={`form-group${formClass} `}>
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div className="form-block">
        <input
          {...input}
          className="form-control"
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          defaultValue={defaultValue}
        />
        <IconCalender
          onClick={() => {
            setDateInline(!dateInline);
          }}
          className="icon-calendar"
        />
        <ReactDatePicker
          {...input}
          minDate={ShowprevDate ? new Date(startDateProp * 1000) : new Date()}
          popperPlacement="auto"
          value={
            ShowprevDate
              ? startDateSelected
                ? setDefaultDate(startDateProp)
                : setDefaultDate(defaultValue)
              : setDefaultDate(defaultValue)
          }
          selected={
            ShowprevDate
              ? startDateSelected
                ? startDateProp * 1000
                : defaultValue * 1000
              : defaultValue * 1000
          }
          onChange={(date, e) => handleDateChange(date, e)}
          // includeDates={woundDates.map(w => new Date(w))}
          dateFormat="dd/MM/yyyy"
          open={dateInline}
          onChangeRaw={(e) => e.preventDefault()}
          onInputClick={() => setDateInline(true)}
          onClickOutside={() => setDateInline(false)}
        />
      </div>
      <div className="text-error text-danger">
        {/* {(touched && error) && error} */}
      </div>
    </li>
  );
};

export const CalenderFieldForMedication = ({
  formLabel,
  input,
  formClass,
  type,
  name,
  placeholder,
  disabled,
  readOnly,
  startDateProp,
  defaultValue,
  onChangeProps,
  ShowprevDate,
  startDateSelected,
  // meta: { touched, error }
}) => {
  const [startDate, setStartDate] = useState();
  const [dateInline, setDateInline] = useState(false);

  const handleDateChange = (date, e) => {
    setDateInline(!dateInline);

    if (input.name === "new_end_date") {
      let tmp = dayjs(date).set("hour", 23).set("minute", 59).set("second", 59);
      let d = new Date(tmp);
      setStartDate(d.getTime());
      onChangeProps(Math.trunc(d.getTime() / 1000));
    } else {
      let tmp = dayjs(date).set("hour", 0).set("minute", 0).set("second", 0);
      let d = new Date(tmp);
      setStartDate(d.getTime());
      onChangeProps(Math.trunc(d.getTime() / 1000));
    }
  };

  useEffect(() => {
    if (!startDate) {
      let tmp = dayjs().set("hour", 0).set("minute", 0).set("second", 0);
      let d = new Date(tmp);
      setStartDate(d.getTime());
      onChangeProps(Math.trunc(d.getTime() / 1000));
    }
  }, [startDate])

  const setDefaultDate = (v) => {
    let d = new Date(v * 1000);
    let new_date =
      ("0" + d.getDate()).slice(-2) +
      "/" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      d.getFullYear();
    return new_date;
  };

  return (
    // ${(touched && error) && 'error'}
    <li className={`form-group${formClass} `}>
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div className="form-block">
        <input
          {...input}
          className="form-control"
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          defaultValue={defaultValue}
        />
        <IconCalender
          onClick={() => {
            setDateInline(!dateInline);
          }}
          className="icon-calendar"
        />
        <ReactDatePicker
          {...input}
          minDate={ShowprevDate ? new Date(startDateProp * 1000) : new Date()}
          popperPlacement="auto"
          value={
            ShowprevDate
              ? startDateSelected
                ? setDefaultDate(startDateProp)
                : setDefaultDate(defaultValue)
              : setDefaultDate(defaultValue)
          }
          selected={
            ShowprevDate
              ? startDateSelected
                ? startDateProp * 1000
                : defaultValue * 1000
              : defaultValue * 1000
          }
          onChange={(date, e) => handleDateChange(date, e)}
          // includeDates={woundDates.map(w => new Date(w))}
          dateFormat="dd/MM/yyyy"
          open={dateInline}
          onChangeRaw={(e) => e.preventDefault()}
          onInputClick={() => setDateInline(true)}
          onClickOutside={() => setDateInline(false)}
        />
      </div>
      <div className="text-error text-danger">
        {/* {(touched && error) && error} */}
      </div>
    </li>
  );
};

export const CalenderFieldNew = ({
  formLabel,
  input,
  formClass,
  type,
  placeholder,
  disabled,
  readOnly,
  defaultValue,
  handleChange,
  meta: { touched, error },
}) => {
  const [dateInline, setDateInline] = useState(false);

  return (
    <li className={`form-group${formClass} ${touched && error && "error"}`}>
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div className="form-block">
        <input
          className="form-control"
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          defaultValue={defaultValue}
        />
        <IconCalender
          onClick={() => {
            setDateInline(!dateInline);
          }}
          className="icon-calendar"
        />
        <ReactDatePicker
          style={{ zIndex: "111111" }}
          minDate={new Date()}
          popperPlacement="auto"
          value={defaultValue}
          onChange={(date) => {
            setDateInline(!dateInline);
            handleChange(date);
          }}
          dateFormat="dd/MM/yyyy"
          open={dateInline}
          onChangeRaw={(e) => e.preventDefault()}
          onInputClick={() => setDateInline(true)}
          onClickOutside={() => setDateInline(false)}
        />
      </div>
      <div className="text-error text-danger">{touched && error && error}</div>
    </li>
  );
};

export const CalenderFieldRedux = ({
  formLabel,
  input,
  formClass,
  type,
  placeholder,
  disabled,
  readOnly,
  defaultValue,
  // handleChange,
  minDate,
}) => {
  const [dateInline, setDateInline] = useState(false);

  return (
    <li className={`form-group${formClass} `}>
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div className="form-block">
        <input
          className="form-control"
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          defaultValue={defaultValue}
        />
        <IconCalender
          onClick={() => {
            setDateInline(!dateInline);
          }}
          className="icon-calendar"
        />
        <ReactDatePicker
          {...input}
          minDate={minDate}
          popperPlacement="auto"
          value={defaultValue}
          // onChange={(date) => { setDateInline(!dateInline); handleChange(date) }}
          dateFormat="dd/MM/yyyy"
          open={dateInline}
          onChangeRaw={(e) => e.preventDefault()}
          onInputClick={() => setDateInline(true)}
          onClickOutside={() => setDateInline(false)}
        />
      </div>
      <div className="text-error text-danger"></div>
    </li>
  );
};

export const NumberField = ({
  formLabel,
  formClass,
  label,
  maxDays,
  value,
  onAdd,
  onSubstract,
  // meta: { touched, error }
}) => {
  return (
    // ${(touched && error) && 'error'}
    <li className={`form-group${formClass}`}>
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div className="d-flex flex-wrap align-items-center">
        <div className="time-main d-flex flex-wrap align-items-center">
          <a
            className={`icon-minus ${value <= 1 && "disabled"}`}
            href="/"
            onClick={(e) => {
              e.preventDefault();
              onSubstract();
            }}
          >
            <IconMinus />
          </a>
          <span className="time-block d-flex flex-wrap align-items-center">
            <input
              disabled
              className="time-input"
              type="text"
              value={value}
              defaultValue="1"
              min="1"
              max="999"
            />
          </span>
          <a
            className={`icon-plus 
                       ${formLabel === "Number of Days" &&
              value >= 365 &&
              "disabled"
              }
                       ${label === "Times" && value >= 4 && "disabled"}
                       `}
            href="/"
            onClick={(e) => {
              e.preventDefault();
              onAdd();
            }}
          >
            <IconPlus />
          </a>
        </div>
        <span>{label}</span>&nbsp;{maxDays && <small>({maxDays})</small>}
      </div>
      <div className="text-error text-danger">
        {/* {(touched && error) && error} */}
      </div>
    </li>
  );
};
export const PhoneField = ({
  formLabel,
  formClass,
  country,
  input,
  value,
  onChangeProps,
  defaultValue,
  meta: { touched, error },
}) => {
  let splitNo = defaultValue && defaultValue.split(" ");

  const defaultCountryValue = () => {
    if (defaultValue) {
      let contryCodeData = countryCodes.filter((v) => {
        //return v.dial_code === splitNo[0] && v.code && v.code.toLowerCase();
        return (
          v.dial_code === splitNo[0] ||
          (v.code.toLowerCase() === country && v.code.toLowerCase())
        );
      });

      if (contryCodeData.length) {
        return contryCodeData[0].code.toLowerCase();
      }
    }
    return country.toLowerCase();
  };
  const changeVal = (value, country) => {
    onChangeProps(value, country.countryCode);
  };

  return (
    // ${(touched && error) && 'error'}
    <li className={`form-group${formClass}  ${touched && error && "error"} `}>
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div className="d-flex flex-wrap align-items-center">
        <PhoneInput
          inputProps={
            defaultValue && {
              value: defaultValue,
            }
          }
          {...input}
          // country={defaultValue ? countryCodes.filter(v => { return  v.dial_code === splitNo[0] && v.code && v.code.toLowerCase()})[0].code.toLowerCase() : country}
          country={defaultCountryValue()}
          value={value}
          onChange={(value, country, e, formattedValue) => {
            changeVal(formattedValue, country);
          }}
          countryCodeEditable={false}
        />
      </div>
      <div className="text-error text-danger">{touched && error && error}</div>
    </li>
  );
};
export const BODField = ({
  formLabel,
  input,
  formClass,
  type,
  placeholder,
  disabled,
  readOnly,
  startDateProp,
  defaultValue,
  onChangeProps,
  ShowprevDate,
  startDateSelected,
  renderCustomHeader,
  // meta: { touched, error }
}) => {
  const [startDate, setStartDate] = useState();
  const [dateInline, setDateInline] = useState(false);
  const handleDateChange = (date, e) => {
    setDateInline(!dateInline);
    let d = new Date(date);
    setStartDate(d.getTime());
    onChangeProps(Math.trunc(d.getTime() / 1000));
  };

  useEffect(() => {
    if (!startDate) {
      let d = new Date();
      d.setHours(0, 0, 0);
      setStartDate(d.getTime());
      onChangeProps(Math.trunc(d.getTime() / 1000));
    }
  }, [startDate]);

  const setDefaultDate = (v) => {
    let d = new Date(v * 1000);
    let new_date =
      ("0" + d.getDate()).slice(-2) +
      "/" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "/" +
      d.getFullYear();
    return new_date;
  };

  return (
    // ${(touched && error) && 'error'}
    <li className={`form-group${formClass} `}>
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div className="form-block">
        <input
          {...input}
          className="form-control"
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          defaultValue={defaultValue}
        />
        <IconCalender
          onClick={() => {
            setDateInline(!dateInline);
          }}
          className="icon-calendar"
        />
        <ReactDatePicker
          maxDate={new Date()}
          popperPlacement="auto"
          onChange={(date, e) => {
            handleDateChange(date, e);
            setStartDate(date);
          }}
          dateFormat="dd/MM/yyyy"
          open={dateInline}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          renderCustomHeader={renderCustomHeader}
          value={
            ShowprevDate
              ? startDateSelected
                ? setDefaultDate(startDateProp)
                : setDefaultDate(defaultValue)
              : setDefaultDate(defaultValue)
          }
          selected={
            ShowprevDate
              ? startDateSelected
                ? startDateProp * 1000
                : defaultValue * 1000
              : defaultValue * 1000
          }
          onInputClick={() => setDateInline(true)}
          onClickOutside={() => setDateInline(false)}
        />
      </div>
      <div className="text-error text-danger">
        {/* {(touched && error) && error} */}
      </div>
    </li>
  );
};

export const JSONFileInputField = ({
  formLabel,
  input,
  formClass,
  type,
  placeholder,
  disabled,
  readOnly,
  maxLength,
  err,
  defaultValue,
  meta: { touched, error },
}) => {
  return (
    <li
      className={`form-group${formClass} ${err && "error"}  ${touched && error && "error"
        } `}
    >
      {formLabel && <label className="form-title">{formLabel}</label>}
      <div className="form-block">
        <input
          {...input}
          className="form-control"
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          // value={defaultValue ? defaultValue : input.value}
          value={undefined}
          readOnly={readOnly}
          accept="application/json"
          maxLength={maxLength && maxLength}
        />
      </div>
      <div className="text-error text-danger">{err && err}</div>
      <div className="text-error text-danger">{touched && error && error}</div>
    </li>
  );
};