import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    joinUsResponse: {},
    contactUsResponse: {}
}

const HomeReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.JOIN_US_SUCCESS:
            return {
                ...state,
                joinUsResponse: action.payload
            }

        case ACTION_TYPES.JOIN_US_ERROR:
            return {
                ...state,
                message: action?.payload?.data?.message || '',
                error: action?.payload?.data || ''
            }

        case ACTION_TYPES.CONTACT_US_SUCCESS:
            return {
                ...state,
                contactUsResponse: action.payload
            }

        case ACTION_TYPES.CONTACT_US_ERROR:
            return {
                ...state,
                message: action?.payload?.data?.message || '',
                error: action?.payload?.data || ''
            }

        default:
            return state
    }
}

export default HomeReducer