export const RESET_PATIENT_STATE = "RESET_PATIENT_STATE";

//LISTUSER Actions
export const LISTUSER_SUCCESS = "LISTUSER_SUCCESS";
export const LISTUSER_ERROR = "LISTUSER_ERROR";
export const PATIENTS_DETAILS_SUCCESS = "PATIENTS_DETAILS_SUCCESS";
export const PATIENTS_DETAILS_ERROR = "PATIENTS_DETAILS_ERROR";

//ARCHIVE_USER Actions
export const ARCHIVE_PATIENT_SUCCESS = "ARCHIVE_PATIENT_SUCCESS";
export const ARCHIVE_PATIENT_ERROR = "ARCHIVE_PATIENT_ERROR";

//ADD_NEW_PATIENT Actions
export const ADD_NEW_PATIENT_SUCCESS = "ADD_NEW_PATIENT_SUCCESS";
export const ADD_NEW_PATIENT_ERROR = "ADD_NEW_PATIENT_ERROR";

//ADD_NEW_PATIENT Actions
export const EDIT_PATIENT_SUCCESS = "EDIT_PATIENT_SUCCESS";
export const EDIT_PATIENT_ERROR = "EDIT_PATIENT_ERROR";

// IS_JOINED_MEETING Actions
export const IS_JOINED_MEETING = "IS_JOINED_MEETING";

//resend_PATIENT Actions
export const RESEND_MAIL_SUCCESS = "RESEND_MAIL_SUCCESS";
export const RESEND_MAIL_ERROR = "RESEND_MAIL_ERROR";

//files list
export const GLASS_FILES_SUCCESS = "GLASS_FILES_SUCCESS";
export const GLASS_FILES_ERROR = "GLASS_FILES_ERROR";