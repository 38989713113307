import React from 'react'

const IconReset = ({
    className
}) => {
    return (
        <svg className={className} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 30 30" style={{ enableBackground: "new 0 0 30 30" }} >
            <path d="M30,4.1v8.2c0,0.8-0.6,1.4-1.4,1.4h-8.2c-0.8,0-1.4-0.6-1.4-1.4c0-0.8,0.6-1.4,1.4-1.4h5.4C25,8,21.4,2.7,15,2.7
	C8.2,2.7,2.7,8.2,2.7,15c0,6.8,5.5,12.3,12.3,12.3c0.8,0,1.4,0.6,1.4,1.4c0,0.8-0.6,1.4-1.4,1.4C6.7,30,0,23.3,0,15
	C0,6.7,6.7,0,15,0c6.1,0,10.2,3.8,12.3,7.4V4.1c0-0.8,0.6-1.4,1.4-1.4C29.4,2.7,30,3.3,30,4.1z"/>
        </svg>
    )
}
export default IconReset;