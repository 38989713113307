import React from 'react'

const IconCalender = ({
    className,
    onClick
}) => {
    return (
        <svg className={className} onClick={onClick} version="1.1" id="calendar" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 22 22" style={{ enableBackground: "new 0 0 22 22" }} >
            <path className="IconCalender" d="M19.4,1.7h-1V0h-1.7v1.7H5.3V0H3.6v1.7h-1C1.2,1.7,0,2.9,0,4.3v15.1C0,20.8,1.2,22,2.6,22h16.8
       c1.4,0,2.6-1.2,2.6-2.6V4.3C22,2.9,20.8,1.7,19.4,1.7z M20.3,19.4c0,0.5-0.4,0.9-0.9,0.9H2.6c-0.5,0-0.9-0.4-0.9-0.9V8.1h18.6V19.4z
        M20.3,6.4H1.7V4.3c0-0.5,0.4-0.9,0.9-0.9h1v1.7h1.7V3.4h11.3v1.7h1.7V3.4h1c0.5,0,0.9,0.4,0.9,0.9V6.4z M3.8,9.4h2v2h-2V9.4z
        M6.9,9.4h2v2h-2V9.4z M10.1,9.4h2v2h-2V9.4z M13.5,9.4h2v2h-2V9.4z M16.7,9.4h2v2h-2V9.4z M3.8,12.8h2v2h-2V12.8z M6.9,12.8h2v2h-2
       V12.8z M10.1,12.8h2v2h-2V12.8z M13.5,12.8h2v2h-2V12.8z M16.7,12.8h2v2h-2V12.8z M3.8,16.3h2v2h-2V16.3z M6.9,16.3h2v2h-2V16.3z
        M10.1,16.3h2v2h-2V16.3z M13.5,16.3h2v2h-2V16.3z"/>
        </svg>
    )
}
export default IconCalender;