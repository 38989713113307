import { combineReducers } from 'redux'
import { reducer as toastrReducer } from 'react-redux-toastr'
import { reducer as formReducer } from 'redux-form'
import home from './Home/Reducer'
import login from './Login/Reducer'
import patient from './Patient/Reducer'
import doctor from './Doctor/Reducer'
import wounds from './Wounds/Reducer'

const rootReducer = combineReducers({
    toastr: toastrReducer,
    form: formReducer,
    home,
    login,
    doctor,
    patient,
    wounds
})

export default rootReducer