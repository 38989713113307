import * as ACTION_TYPES from './Types'

const initialState = {
    message: "",
    error: null,
    doctorList: [],
    doctorListWithPagination: [],
    totalDoctorCount: 0,
    doctor: {},
    archiveStatus: [],
    MedicineList: [],
    DressingList: [],
    treatmentList: [],
    tandcDetails: [],
    settingsList: [],
    isJoined: false,
    unitPreferences: '',
    hospitalAdminList: [],
}

const doctorReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_TYPES.RESET_DOCTOR_STATE:
            return {
                ...initialState,
            }

        case ACTION_TYPES.LISTDOCTOR_SUCCESS:
            return {
                ...state,
                doctorList: action.payload.return_data
            }

        case ACTION_TYPES.TC_SUCCESS:
            return {
                ...state,
                tandcDetails: action.payload.return_data
            }

        case ACTION_TYPES.LISTDOCTOR_ERROR:
            return {
                ...state,
                message: action?.payload?.data?.message || '',
                error: action?.payload?.data || ''
            }

        case ACTION_TYPES.TC_ERROR:
            return {
                ...state,
                message: action?.payload?.data?.message || '',
                error: action?.payload?.data || ''
            }
        case ACTION_TYPES.DOCTORS_DETAILS_SUCCESS:
            return {
                ...state,
                doctor: action.payload.return_data
            }

        case ACTION_TYPES.DOCTORS_DETAILS_ERROR:
            return {
                ...state,
                doctor: {},
                message: action?.payload?.data?.message || '',
                error: action?.payload?.data || ''
            }

        case ACTION_TYPES.MEDICINE_LIST_SUCCESS:
            return {
                ...state,
                MedicineList: action.payload.return_data
            }

        case ACTION_TYPES.DRESSING_MATERIAL_LIST_SUCCESS:
            return {
                ...state,
                DressingList: action.payload.return_data
            }
        case ACTION_TYPES.ALL_TREATMENT_LIST_SUCCESS:
            return {
                ...state,
                treatmentList: action.payload.return_data
            }

        case ACTION_TYPES.ALL_TREATMENT_LIST_ERROR:
            return {
                ...state,
                treatmentList: []
            }


        case ACTION_TYPES.UNIT_PREFERENCES_SUCCESS:
            return {
                ...state,
                unitPreferences: JSON.parse(action.payload.return_data[0]?.unit_preferences)
            }

        case ACTION_TYPES.UNIT_PREFERENCES_ERROR:
            return {
                ...state,
                message: action?.payload?.data?.message || '',
                error: action?.payload?.data || ''
            }

        case ACTION_TYPES.LISTDOCTOR_WITH_PAGINATION_SUCCESS:
            return {
                ...state,
                doctorListWithPagination: Object.keys(action.payload.return_data).length ? action.payload.return_data.clinicianList : [],
                totalDoctorCount: Object.keys(action.payload.return_data).length ? action.payload.return_data.totalClinicianCount : 0,
            }

        case ACTION_TYPES.LISTDOCTOR_WITH_PAGINATION_ERROR:
            return {
                ...state,
                message: action?.payload?.data?.message || '',
                error: action?.payload?.data || ''
            }

        case ACTION_TYPES.SETTING_LIST_SUCCESS:
            return {
                ...state,
                settingsList: action.payload.return_data[0]?.settings ? action.payload.return_data[0].settings : {}
            }

        case ACTION_TYPES.SETTING_LIST_ERROR:
            return {
                ...state,
                settingsList: []
            }

            case ACTION_TYPES.HOSPITAL_ADMIN_LIST_SUCCESS:
                return {
                    ...state,
                    hospitalAdminList: action.payload.return_data
                }
    
            case ACTION_TYPES.HOSPITAL_ADMIN_LIST_ERROR:
                return {
                    ...state,
                    hospitalAdminList: []
                }

        default:
            return state
    }
}

export default doctorReducer