import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

export default function checkAuth(ComposedComponent) {
  const Authentication = (props) => {
    useEffect(() => {
      // console.log(window.location);
      if (
        !props.isloggedin &&
        !window.location.pathname.includes("/patients-history/")
      ) {
        props.history.push("/login");
      }
      // eslint-disable-next-line
    }, [props.isloggedin]);

    return <ComposedComponent {...props} />;
  };
  const mapStateToProps = (state) => ({
    isloggedin: state.login.isLoggedIn,
  });

  return withRouter(connect(mapStateToProps)(Authentication));
}
