import React from 'react'

const Icon360 = ({
    className
}) => {
    return (
        <svg className={className} version="1.1" id="_360-degrees_1_" xmlns="http://www.w3.org/2000/svg" x="0px"
            y="0px" viewBox="0 0 86 63.8" style={{ enableBackground: "new 0 0 86 63.8" }}>
            <path className="Icon3601" d="M54.1,56.4c-1.4,0-2.5-1.1-2.5-2.5c0-1.3,0.9-2.4,2.2-2.5c8-1,15.1-3.2,20.1-6.1c4.6-2.7,7.1-5.8,7.1-8.8
	c0-3.3-2.9-6-5.4-7.7c-1.2-0.8-1.5-2.3-0.7-3.5c0.8-1.2,2.3-1.5,3.5-0.7c0,0,0.1,0,0.1,0.1c5,3.4,7.6,7.5,7.6,11.9
	c0,5-3.3,9.5-9.6,13.1c-5.6,3.3-13.2,5.6-22,6.7C54.3,56.4,54.2,56.4,54.1,56.4z M42.4,52.8L35.7,46c-1-1-2.6-1-3.6-0.1
	c-1,1-1,2.6-0.1,3.6c0,0,0,0,0.1,0.1l2,2c-6.8-0.6-13.4-2.3-19.7-5.1C8.5,43.6,5,40,5,36.5c0-3,2.4-6,6.9-8.7
	c1.2-0.7,1.6-2.2,0.9-3.4c-0.7-1.2-2.2-1.6-3.4-0.9c0,0-0.1,0-0.1,0C1.6,28.1,0,33.1,0,36.5C0,42.1,4.3,47.3,12.2,51
	c6.1,2.9,14,4.9,22.7,5.7l-2.8,2.8c-1,1-0.9,2.6,0.1,3.6c1,0.9,2.5,0.9,3.5,0l6.7-6.7C43.4,55.4,43.4,53.8,42.4,52.8
	C42.4,52.8,42.4,52.8,42.4,52.8z M26.4,31.4v-0.6c0-2.1-1.3-2.6-3.1-2.6c-1.1,0-1.4-1-1.4-1.9c0-1,0.4-1.9,1.4-1.9
	c1.2,0,2.5-0.2,2.5-2.7c0-1.9-1.1-2.3-2.4-2.3c-1.6,0-2.4,0.4-2.4,1.6c0,1.1-0.5,1.8-2.3,1.8c-2.3,0-2.6-0.5-2.6-2
	c0-2.5,1.8-5.7,7.3-5.7c4.1,0,7.1,1.5,7.1,5.8c0,2.3-0.9,4.5-2.5,5.2c2,0.6,3.4,2.6,3.3,4.7v0.6c0,5.2-3.6,7.2-8.1,7.2
	c-5.5,0-7.6-3.4-7.6-6c0-1.4,0.6-1.8,2.4-1.8c2,0,2.6,0.4,2.6,1.7c0,1.5,1.4,1.9,2.8,1.9C25.6,34.3,26.4,33.5,26.4,31.4z M50.7,30.8
	v0.3c0,5.5-3.4,7.5-7.8,7.5s-7.9-2-7.9-7.5v-8.5c0-5.5,3.5-7.5,8.1-7.5c5.4,0,7.5,3.4,7.5,6c0,1.5-0.7,2-2.3,2
	c-1.4,0-2.6-0.4-2.6-1.8c0-1.2-1.3-1.9-2.8-1.9c-1.9,0-3,1-3,3.2v2.9c1.1-1,2.5-1.5,4-1.4C47.7,24.1,50.7,25.7,50.7,30.8z M40,31.5
	c0,2.2,1.1,3.2,2.9,3.2s2.8-1,2.8-3.2v-0.3c0-2.3-1.1-3.2-2.9-3.2c-1.7,0-2.8,0.8-2.8,2.9V31.5z M54.6,31.1v-8.5
	c0-5.5,3.4-7.5,7.8-7.5s7.9,2,7.9,7.5v8.5c0,5.5-3.4,7.5-7.9,7.5S54.6,36.6,54.6,31.1z M65.3,22.7c0-2.2-1.1-3.2-2.9-3.2
	c-1.8,0-2.8,1-2.8,3.2v8.5c0,2.2,1.1,3.2,2.8,3.2s2.9-1,2.9-3.2V22.7z M76.3,15.1c-4.2,0-7.6-3.4-7.6-7.6c0-4.2,3.4-7.6,7.6-7.6
	c4.2,0,7.6,3.4,7.6,7.6C83.9,11.7,80.5,15.1,76.3,15.1z M76.3,5c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5
	c0,0,0,0,0,0C78.8,6.2,77.7,5,76.3,5z"/>
        </svg>
    )
}
export default Icon360;
